<template>
  <b-form-group
    :invalid-feedback="firstErrorMessage"
    :state="isValid"
    :append="append"
    :prepend="prepend"
  >
    <template #label>
      <div class="d-flex">
        <slot name="label">{{ label }}</slot>
        <span
          v-if="required"
          v-b-tooltip.hover
          class="text-danger"
          :title="$t('words.required')"
          >*</span
        >
        <div v-if="collapse.value || $slots.collapse" class="ml-1">
          <b-icon-question-circle
            v-b-toggle="'tooltip-name-' + name"
            class="go-cursor-pointer"
            variant="primary"
            @click.stop=""
          />
        </div>
      </div>
      <b-collapse :id="'tooltip-name-' + name" :visible="collapse.visible">
        <slot name="collapse">{{ collapse.value }}</slot>
      </b-collapse>
    </template>

    <b-input-group>
      <b-input-group-prepend v-if="prepend" size="MD" is-text>
        <b>{{ prepend }}</b>
      </b-input-group-prepend>
      <slot name="prepend"></slot>

      <slot
        :attrs="{ state: isValid }"
        :listeners="{ input: () => preferredValidator.$touch() }"
      />

      <b-input-group-append v-if="append" size="MD" is-text>
        <b>{{ append }}</b>
      </b-input-group-append>
      <slot name="append"></slot>
    </b-input-group>
  </b-form-group>
</template>
<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor'

export default {
  name: 'FormGroup',
  extends: singleErrorExtractorMixin,
  inject: {
    scope: { default: () => '' }
  },
  props: {
    // WIKI: Collapse can be an object {value,visible} or a template/slot (in this case visible is false by default)
    collapse: {
      type: Object,
      default: () => Object({ value: '', visible: false })
    },
    required: { type: Boolean, default: false },
    prepend: { type: String, default: null },
    append: { type: String, default: null }
  },
  computed: {
    resolvedAttribute() {
      let field = this.attribute || this.name
      return this.$_VEE_hasI18n ? this.$t(this.attr_code + field) : field
    },

    attr_code() {
      let code = ''
      if (this.scope.length > 0) code = this.scope + '.'

      if (this.$_VEE_hasI18n)
        code =
          code + this.$vuelidateErrorExtractor.i18nAttributes.__default + '.'
      return code
    },

    msg_code() {
      let code = ''
      if (this.scope.length > 0) code = this.scope + '.'

      if (this.$_VEE_hasI18n)
        code = code + this.$vuelidateErrorExtractor.i18n + '.'
      return code
    }
  },
  methods: {
    getI18nMessage(key, params) {
      return this.$t(this.msg_code + key, params)
    }
  }
}
</script>


<style></style>