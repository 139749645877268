<template>
  <div>
    <SearchBar></SearchBar>
    <transition name="fade-custom" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import SearchBar from './components/SearchBar.vue'
// import map from 'lodash/map'
// import sortBy from 'lodash/sortBy'

export default {
  name: 'App',

  metaInfo: {
    title: 'CRM',
    titleTemplate: 'CRM | %s'
  },

  components: { SearchBar },
  props: {},
  data() {
    return {
      user: null
    }
  },

  created() {
    // FETCH DOMAIN USERS AND MAP IT
    //
    // Rails.ajax({
    //   url: '/api/list_domain_users',
    //   type: 'GET',
    //   dataType: 'json',
    //   success: (data) => {
    //     // maps to name and email, first email [0] is the primary email
    //     this.$store.state.listUsers = map(data.users, (o) => ({
    //       name: o.name.fullName,
    //       emails: map(sortBy(o.emails, 'primary'), (o) => o.address)
    //     }))
    //   },
    //   error: () => {
    //     console.log('App created without list users.')
    //   }
    // }),
    Rails.ajax({
      url: '/api/users/list_local_user_emails',
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        this.$store.state.usersEmailsList = data
      },
      error: () => {
        console.log('App created without user lists.')
      }
    })
  },

  mounted() {
    Rails.ajax({
      url: '/api/users',
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        console.log(data)
        this.$store.state.user = data
      },
      error: () => {
        console.log('App mounted without user logged.')
      }
    })
  },
  methods: {}
}
</script>

<style lang="scss">
.fade-custom-enter-active,
.fade-custom-leave-active {
  transition: opacity 0.1s;
}

.fade-custom-enter,
.fade-custom-leave-to {
  opacity: 0;
}

#footer.fade-custom-leave-active,
#footer.fade-custom-enter-active {
  display: none;
}
</style>
