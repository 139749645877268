import consumer from "./consumer"

consumer.subscriptions.create("BoardChannel", {
  connected() {
    console.log('connnected');
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log(data)
    if (data.commit) {
      return window.store.commit(data.commit, JSON.parse(data.payload));
    }
  }
});
