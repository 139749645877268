var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"bg-light px-4",attrs:{"fluid":""}},[_c('b-row',{staticClass:"small",attrs:{"align-content":"end","align-h":"between","align-v":"center"}},[_c('b-button',{attrs:{"variant":"link"},on:{"click":function($event){return _vm.$router.back()}}},[_c('b-icon-arrow-left'),_vm._v("Voltar")],1),_vm._v(" "),(_vm.saved)?_c('div',[_vm._v("\n      Alterações salvas com sucesso!"),_c('b-icon-check',{staticClass:"ml-2",attrs:{"scale":"2","variant":"success"}})],1):_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-4"},[_vm._v("Salvando...")]),_vm._v(" "),_c('Loading',{attrs:{"loading":true,"small":true}})],1)],1),_vm._v(" "),_c('form-wrapper',{attrs:{"validator":_vm.$v.form,"scope":"deal"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius"},[_c('form-group',{staticClass:"form-group font-weight-bold",attrs:{"name":"organization"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"align-items-center d-flex"},[_c('b-icon-building',{staticClass:"mr-1"}),_vm._v("\n                Organização\n              ")],1)]},proxy:true},{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('v-select',_vm._g(_vm._b({staticClass:"w-100 h-auto",attrs:{"label":"name","filterable":false,"clearable":false,"options":_vm.orgOptions,"placeholder":'Procure aqui...',"taggable":false,"append-to-body":true,"calculate-position":_vm.dropPos},on:{"search":_vm.getOrganizations,"input":_vm.setOrganization},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('div',[_c('b-icon-building',{staticClass:"mr-2",attrs:{"font-scale":"1.5"}})],1),_vm._v(" "),_c('div',{staticClass:"flex-column"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(option.name))]),_vm._v(" "),_c('div',[_vm._v("\n                      "+_vm._s(option.email)+"\n                      "+_vm._s(_vm._f("facade")(option.mobile,[
                          '####-####',
                          '#####-####',
                          '(##) ####-####',
                          '(##) #####-####'
                        ]))+"\n                      "+_vm._s(_vm._f("facade")(option.telephone,[
                          '####-####',
                          '#####-####',
                          '(##) ####-####',
                          '(##) #####-####'
                        ]))+"\n                    ")])])])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v("\n                  "+_vm._s(option.name)+"\n                ")])]}}]),model:{value:(_vm.form.organization),callback:function ($$v) {_vm.$set(_vm.form, "organization", $$v)},expression:"form.organization"}},'v-select',attrs,false),listeners),[_c('template',{slot:"no-options"},[_vm._v(" Digite para procurar... ")])],2)}}])}),_vm._v(" "),(_vm.form.organization)?_c('div',{staticClass:"small"},[_c('hr'),_vm._v(" "),_c('b-icon-building',{staticClass:"w-100",attrs:{"font-scale":"2"}}),_vm._v(" "),_c('FormToOrganizations',{attrs:{"organization":_vm.form.organization},on:{"update:localChange":function($event){_vm.localChange = $event}}})],1):_vm._e()],1)]),_vm._v(" "),(_vm.form.organization)?_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-row',[_c('div',{staticClass:"w-100 p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius"},[_c('div',{staticClass:"align-items-center d-flex font-weight-bold"},[_c('b-icon-person-fill',{staticClass:"mr-1"}),_vm._v("\n              Pessoas ("+_vm._s(_vm.form.organization.people
                ? _vm.form.organization.people.length
                : 0)+")\n              "),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-add-person",modifiers:{"modal-add-person":true}}],staticClass:"ml-auto py-0",attrs:{"size":"sm"}},[_c('b-icon-plus',{attrs:{"scale":"1.2"}})],1)],1),_vm._v(" "),_c('ul',_vm._l((_vm.seePeople),function(person){return _c('li',{key:person.id,staticClass:"triggerRemove"},[_c('b-link',{attrs:{"to":{ name: 'Person View', params: { id: person.id } }}},[_vm._v(_vm._s(person.name))]),_vm._v(" "),_c('b-link',{staticClass:"small text-danger hiddenRemove float-right",on:{"click":function($event){return _vm.removePerson(person)}}},[_vm._v("Remover")])],1)}),0),_vm._v(" "),(!_vm.seeMorePeople)?_c('b-link',{on:{"click":function($event){_vm.seeMorePeople = true}}},[_vm._v("Ver mais...")]):_c('b-link',{on:{"click":function($event){_vm.seeMorePeople = false}}},[_vm._v("Reduzir...")])],1)]),_vm._v(" "),_c('b-modal',{attrs:{"id":"modal-add-person","centered":"","hide-footer":"","hide-header":""}},[_c('b-form-group',{staticClass:"form-group font-weight-bold",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"align-items-center d-flex"},[_c('b-icon-person-fill'),_vm._v("\n                Contato\n              ")],1)]},proxy:true},{key:"default",fn:function(ref){
                var attrs = ref.attrs;
                var listeners = ref.listeners;
return _c('v-select',_vm._g(_vm._b({staticClass:"w-100 h-auto",staticStyle:{"z-index":"1000"},attrs:{"label":"name","filterable":false,"options":_vm.peopleOptions,"placeholder":'Procure aqui...',"taggable":false,"append-to-body":true,"calculate-position":_vm.dropPos},on:{"search":_vm.getPeople,"search:blur":function($event){return _vm.$refs.addPersonButton.focus()}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('div',[_c('b-icon-person-circle',{staticClass:"mr-2",attrs:{"font-scale":"1.5"}})],1),_vm._v(" "),_c('div',{staticClass:"flex-column"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(option.name))]),_vm._v(" "),_c('div',[_vm._v("\n                      "+_vm._s(option.email)+"\n                      "+_vm._s(_vm._f("facade")(option.mobile,[
                          '####-####',
                          '#####-####',
                          '(##) ####-####',
                          '(##) #####-####'
                        ]))+"\n                      "+_vm._s(_vm._f("facade")(option.telephone,[
                          '####-####',
                          '#####-####',
                          '(##) ####-####',
                          '(##) #####-####'
                        ]))+"\n                    ")])])])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v("\n                  "+_vm._s(option.name)+"\n                ")])]}}],null,false,2425564785),model:{value:(_vm.newPerson),callback:function ($$v) {_vm.newPerson=$$v},expression:"newPerson"}},'v-select',attrs,false),listeners),[_c('template',{slot:"no-options"},[_vm._v(" Digite para procurar... ")])],2)}}],null,false,1944488392)}),_vm._v(" "),_c('b-button',{ref:"addPersonButton",attrs:{"block":"","variant":"success","disabled":!_vm.saved},on:{"click":function($event){return _vm.addToOrg()}}},[_vm._v("Adicionar à organização\n            "),(!_vm.saved)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1),_vm._v(" "),_c('b-row',[_c('div',{staticClass:"w-100 p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius"},[_c('div',{staticClass:"align-items-center d-flex font-weight-bold"},[_c('b-icon-files',{staticClass:"mr-1"}),_vm._v("\n              Negócios ("+_vm._s(_vm.form.organization.deals ? _vm.form.organization.deals.length : 0)+")\n            ")],1),_vm._v(" "),_c('ul',_vm._l((_vm.seeDeals),function(deal){return _c('li',{key:deal.id},[_c('b-link',{attrs:{"to":{
                    name: 'Deal View',
                    params: {
                      id: deal.id
                    }
                  }}},[_vm._v(_vm._s(deal.title))])],1)}),0),_vm._v(" "),(!_vm.seeMoreDeals)?_c('b-link',{on:{"click":function($event){_vm.seeMoreDeals = true}}},[_vm._v("Ver mais...")]):_c('b-link',{on:{"click":function($event){_vm.seeMoreDeals = false}}},[_vm._v("Reduzir...")])],1)])],1):_vm._e(),_vm._v(" "),_c('b-col',{attrs:{"cols":"12","md":"4"}},[(_vm.timeline)?_c('b-col',_vm._l((_vm.timeline),function(note,index){return _c('b-row',{key:index},[(note.spec == 'history')?_c('div',{staticClass:"w-100 my-2"},[_c('div',{staticClass:"text-black-50 text-center",staticStyle:{"background-color":"transparent","border-radius":"10px","border":"dashed 1px lightgray"}},[_c('span',{staticClass:"small text-left d-block font-italic"},[_vm._v("\n                  "+_vm._s(_vm.niceMoment(note.created_at))+" por "+_vm._s(note.actor)+"\n                ")]),_vm._v("\n                Atualizou\n                "),_c('span',{staticClass:"font-italic font-weight-bold"},[_vm._v(_vm._s(note.attribute))]),_vm._v("\n                de ("+_vm._s(note.name[0])+") para ("+_vm._s(note.name[1])+")\n              ")])]):_c('div',[_vm._v("\n              "+_vm._s(JSON.stringify(note))+"\n            ")])])}),1):_c('b-col',[_c('hr'),_vm._v(" "),_c('div',{staticClass:"w-100 text-center text-black-50"},[_vm._v("\n            linha do tempo vazia\n          ")]),_vm._v(" "),_c('hr')])],1)],1)],1),_vm._v(" "),_c('go-top',{attrs:{"size":35,"right":15,"bottom":25,"fg-color":"#000000","bg-color":"#eaeaea","weight":"bold"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }