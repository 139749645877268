export default {
    methods: {
        toast(title, msg, variant) {
            this.$bvToast.toast(msg, {
                title: title,
                variant: variant,
                noFade: true,
                solid: true,
                appendToast: true,
                bodyClass: 'p-1'
            })
        }
    }
};