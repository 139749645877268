var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"text-center my-2",attrs:{"align-h":"between","no-gutters":""}},[_c('b-col',{attrs:{"cols":"6","md":"2"}},[_c('b-button',{class:[
          'text-secondary',
          _vm.activeTable == 'deals' ? 'bg-graylight font-weight-bold' : ''
        ],attrs:{"variant":"link"},on:{"click":_vm.fetchDeals}},[_c('b-icon-files',{attrs:{"variant":"primary"}}),_vm._v(" Negócios")],1)],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"6","md":"2"}},[_c('b-button',{class:[
          'text-secondary',
          _vm.activeTable == 'organizations'
            ? 'bg-graylight font-weight-bold'
            : ''
        ],attrs:{"variant":"link"},on:{"click":_vm.fetchOrganizations}},[_c('b-icon-building',{attrs:{"variant":"primary"}}),_vm._v(" Organizações")],1)],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"6","md":"2"}},[_c('b-button',{class:[
          'text-secondary',
          _vm.activeTable == 'people' ? 'bg-graylight font-weight-bold' : ''
        ],attrs:{"variant":"link"},on:{"click":_vm.fetchPeople}},[_c('b-icon-person',{attrs:{"variant":"primary"}}),_vm._v(" Pessoas")],1)],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"6","md":"2"}},[_c('b-button',{class:[
          'text-secondary',
          _vm.activeTable == 'activities' ? 'bg-graylight font-weight-bold' : ''
        ],attrs:{"variant":"link"},on:{"click":_vm.fetchActivities}},[_c('b-icon-list-task',{attrs:{"variant":"primary"}}),_vm._v(" Atividades")],1)],1),_vm._v(" "),_c('b-col',{staticClass:"px-1 my-2",attrs:{"cols":"3","md":"1"}},[_c('b-button',{attrs:{"block":"","size":"sm"},on:{"click":_vm.refreshTable}},[_c('b-icon-arrow-clockwise',{attrs:{"rotate":30,"scale":"1.25"}}),_vm._v(" Reset")],1)],1),_vm._v(" "),_c('b-col',{staticClass:"px-1 my-2",attrs:{"cols":"9","md":"1"}},[_c('b-dropdown',{attrs:{"block":"","text":"Colunas","menu-class":"min-width-250 shadow-8","boundary":"viewport","size":"sm","no-flip":"","lazy":""}},_vm._l((_vm.menu),function(col,i){return _c('b-dropdown-form',{key:i,staticClass:"btn-outline-secondary",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();col.action($event)
          _vm.refreshMenu()}}},[_c('b-row',{attrs:{"align-v":"center"}},[('visible' in col)?_c('b-checkbox',{attrs:{"value":col.visible,"checked":true}}):_c('span'),_vm._v("\n            "+_vm._s(col.label)+"\n          ")],1)],1)}),1)],1)],1),_vm._v(" "),_c('b-row',{staticClass:"my-2 text-center",attrs:{"align-h":"between","align-v":"center"}},[_c('form-wrapper',{staticClass:"d-flex flex-column flex-md-row col-12",attrs:{"validator":_vm.$v.form,"scope":"table_attributes"}},[_c('form-group',{staticClass:"form-group small col mb-0",attrs:{"name":"filter"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var attrs = ref.attrs;
          var listeners = ref.listeners;
return _c('v-select',_vm._g(_vm._b({staticClass:"w-100",attrs:{"name":"filter","placeholder":"Filtrar coluna...","options":_vm.activeColumns,"get-option-label":function (c) { return c.getDefinition().title; },"get-option-key":function (c) { return c.getDefinition().title; },"clearable":false},model:{value:(_vm.form.filter),callback:function ($$v) {_vm.$set(_vm.form, "filter", $$v)},expression:"form.filter"}},'v-select',attrs,false),listeners))}}])}),_vm._v(" "),_c('form-group',{staticClass:"small col mb-0",attrs:{"name":"condition"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var attrs = ref.attrs;
          var listeners = ref.listeners;
return _c('v-select',_vm._g(_vm._b({staticClass:"w-100",attrs:{"name":"condition","placeholder":"Condição","options":_vm.filterConditions,"clearable":false,"selectable":function (e) { return !e.disabled; }},model:{value:(_vm.form.condition),callback:function ($$v) {_vm.$set(_vm.form, "condition", $$v)},expression:"form.condition"}},'v-select',attrs,false),listeners))}}])}),_vm._v(" "),_c('form-group',{staticClass:"small col mb-0",attrs:{"name":"value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var attrs = ref.attrs;
          var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({staticClass:"w-100 p-1 h-100 no-grip",staticStyle:{"font-size":"1.1em"},attrs:{"name":"value","placeholder":"Valor...","disabled":_vm.disableValue},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addFilter($event)}},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}},'b-input',attrs,false),listeners))}}])}),_vm._v(" "),_c('b-button',{staticClass:"col col-md-2 my-2",attrs:{"block":"","size":"sm"},on:{"click":_vm.addFilter}},[_vm._v("\n        Filtrar")]),_vm._v(" "),_c('b-radio-group',{staticClass:"col col-md-1",attrs:{"options":['E', 'OU'],"checked":"E"},model:{value:(_vm.andOr),callback:function ($$v) {_vm.andOr=$$v},expression:"andOr"}})],1)],1),_vm._v(" "),_vm._l((_vm.filtersFlatted),function(f,i){return _c('div',{key:i,class:[
      'row justify-content-around align-items-center',
      f.depth == 0 ? 'bg-graylight' : ''
    ]},[_c('div',{staticClass:"font-weight-bold col-1"},[_vm._v(_vm._s(f.depth == 0 ? 'E' : 'OU'))]),_vm._v(" "),_c('div',{staticClass:"col-2 text-center"},[_vm._v(_vm._s(_vm.tableAttr[f.field]))]),_vm._v(" "),_c('div',{staticClass:"col-2 text-center"},[_vm._v(_vm._s(_vm.tableAttr[f.type]))]),_vm._v(" "),_c('div',{staticClass:"col-2 text-center"},[_vm._v(_vm._s(f.value || _vm.tableAttr[f.value]))]),_vm._v(" "),_c('b-button',{staticClass:"col-5",attrs:{"variant":"link"},on:{"click":function($event){return _vm.removeFilter(f)}}},[_c('b-icon-x-circle',{staticClass:"float-right",attrs:{"variant":"danger"}})],1)],1)}),_vm._v(" "),_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',[_c('Tabulator',{ref:"table",staticClass:"table-bordered table-striped table-sm border-0 nice-scroll",attrs:{"options":_vm.activeTableOptions,"table-data":_vm.activeTableData},on:{"data-loaded":_vm.dataLoaded,"row-click":_vm.rowClick,"column-moved":_vm.columnMoved,"ajax-response":_vm.ajaxResponse}}),_vm._v(" "),_c('div',{staticClass:"small text-black-50 text-right"},[_vm._v("\n        "+_vm._s(_vm.maxNo)+" resultados"),_c('br'),_vm._v("\n        "+_vm._s(_vm.maxPages)+" páginas\n      ")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }