var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"bg-light px-4",attrs:{"fluid":""}},[_c('b-row',{staticClass:"small",attrs:{"align-content":"end","align-h":"between","align-v":"center"}},[_c('b-button',{attrs:{"variant":"link"},on:{"click":function($event){return _vm.$router.back()}}},[_c('b-icon-arrow-left'),_vm._v("Voltar")],1),_vm._v(" "),(_vm.saved)?_c('div',[_vm._v("\n      Alterações salvas com sucesso!"),_c('b-icon-check',{staticClass:"ml-2",attrs:{"scale":"2","variant":"success"}})],1):_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-4"},[_vm._v("Salvando...")]),_vm._v(" "),_c('Loading',{attrs:{"loading":true,"small":true}})],1)],1),_vm._v(" "),_c('form-wrapper',{attrs:{"validator":_vm.$v.form,"scope":"deal"}},[_c('div',{staticClass:"bg-white px-3 big-radius border-bottom shadow-1"},[_c('b-form-row',[_c('b-col',{staticClass:"p-0"},[_c('form-group',{staticClass:"form-group",attrs:{"name":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-form-input',_vm._g(_vm._b({ref:"title",class:{
                'form-control edit-title': true,
                'no-valid-vuelidate': function () { return _vm.avoidValidClass(); }
              },attrs:{"placeholder":"Título do novo negócio","value":_vm.form.title,"name":"title","type":"text","autocomplete":"off"},on:{"change":function($event){_vm.form.title = $event}}},'b-form-input',attrs,false),listeners))}}])})],1)],1),_vm._v(" "),_c('b-form-row',{staticClass:"mb-2"},[_c('div',{staticClass:"w-100 nice-scroll"},[_c('form-group',{staticClass:"form-group align-items-center",attrs:{"name":"pipe"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var attrs = ref.attrs;
              var listeners = ref.listeners;
return _c('v-select',_vm._g(_vm._b({staticClass:"w-100",attrs:{"options":_vm.pipes,"label":"name"},model:{value:(_vm.form.pipe),callback:function ($$v) {_vm.$set(_vm.form, "pipe", $$v)},expression:"form.pipe"}},'v-select',attrs,false),listeners))}}])})],1)])],1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('b-row',{attrs:{"align-v":"center"}},[_c('b-button',{staticClass:"col-auto p-0 m-0 px-3",attrs:{"size":"sm","variant":"link"},on:{"click":function($event){return _vm.returnNextNumberServer()}}},[_vm._v("Retornar próximos números (servidor): ")]),_c('Loading',{staticStyle:{"width":"25px","align-self":"center","color":"dodgerblue"},attrs:{"small":true,"loading":_vm.nextNumberServerLoading}}),_vm._v(" "),_c('b-link',{staticClass:"col font-weight-bold small mx-3",on:{"click":function($event){_vm.form.title = _vm.nextNumberServer[0] + ' - ' + _vm.form.title}}},[_vm._v(_vm._s(_vm.nextNumberServer[0]))]),_vm._v(" "),_c('b-link',{staticClass:"col font-weight-bold small mx-3",on:{"click":function($event){_vm.form.title = _vm.nextNumberServer[1] + ' - ' + _vm.form.title}}},[_vm._v(_vm._s(_vm.nextNumberServer[1]))])],1),_vm._v(" "),_c('b-row',{attrs:{"align-v":"center"}},[_c('form-group',{staticClass:"form-group small mb-0 px-3 form-group-inline",attrs:{"name":"create_folder","label":"Criar pasta automático ?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var attrs = ref.attrs;
              var listeners = ref.listeners;
return _c('b-form-checkbox',_vm._g(_vm._b({staticClass:"w-100 h-100 input-pointer",attrs:{"inline":"","value":"Sim","unchecked-value":"Não","name":"create_folder","size":"sm"},model:{value:(_vm.form.create_folder),callback:function ($$v) {_vm.$set(_vm.form, "create_folder", $$v)},expression:"form.create_folder"}},'b-form-checkbox',attrs,false),listeners),[_c('span',{style:({
              cursor: 'pointer',
              color: _vm.form.create_folder == 'Não' ? '#dc3545' : undefined
            })},[_vm._v(_vm._s(_vm.form.create_folder))])])}}])})],1),_vm._v(" "),_c('b-row',{attrs:{"align-content":"center","align-h":"center","align-v":"center"}},[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius"},[_c('span',{staticClass:"font-weight-bold"},[_c('b-icon-pen',{staticClass:"mr-1"}),_vm._v(" Detalhes:")],1),_vm._v(" "),_c('hr',{staticClass:"my-1"}),_vm._v(" "),_c('form-group',{staticClass:"form-group align-items-center",attrs:{"name":"value","label":"Valor","prepend":'R$'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var attrs = ref.attrs;
            var listeners = ref.listeners;
return _c('CurrencyInput',_vm._g(_vm._b({staticClass:"form-control",attrs:{"value":_vm.form.value,"currency":null,"distraction-free":false,"auto-decimal-mode":true,"locale":"pt-BR"},on:{"change":function($event){_vm.form.value = $event}}},'CurrencyInput',attrs,false),listeners))}}])}),_vm._v(" "),_c('form-group',{staticClass:"form-group align-items-center",attrs:{"name":"status","label":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var attrs = ref.attrs;
            var listeners = ref.listeners;
return _c('v-select',_vm._g(_vm._b({staticClass:"w-100",attrs:{"options":_vm.statuses,"label":"name"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},'v-select',attrs,false),listeners))}}])}),_vm._v(" "),_c('form-group',{staticClass:"form-group align-items-center",attrs:{"name":"owner","label":"Proprietário"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var attrs = ref.attrs;
            var listeners = ref.listeners;
return _c('v-select',_vm._g(_vm._b({staticClass:"w-100",attrs:{"options":_vm.owners,"label":"email"},model:{value:(_vm.form.owner),callback:function ($$v) {_vm.$set(_vm.form, "owner", $$v)},expression:"form.owner"}},'v-select',attrs,false),listeners))}}])})],1),_vm._v(" "),_c('div',{staticClass:"p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius"},[_c('form-group',{staticClass:"form-group font-weight-bold",attrs:{"name":"organization"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"align-items-center d-flex"},[_c('b-icon-building',{staticClass:"mr-1"}),_vm._v("\n                Organização\n              ")],1)]},proxy:true},{key:"default",fn:function(ref){
            var attrs = ref.attrs;
            var listeners = ref.listeners;
return _c('v-select',_vm._g(_vm._b({staticClass:"w-100 h-auto",attrs:{"label":"name","filterable":false,"options":_vm.orgOptions,"placeholder":'Procure aqui...',"taggable":false,"append-to-body":true,"calculate-position":_vm.dropPos},on:{"search":_vm.getOrganizations,"input":_vm.setOrganization},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('div',[_c('b-icon-building',{staticClass:"mr-2",attrs:{"font-scale":"1.5"}})],1),_vm._v(" "),_c('div',{staticClass:"flex-column"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(option.name))]),_vm._v(" "),_c('div',[_vm._v("\n                      "+_vm._s(option.email)+"\n                      "+_vm._s(_vm._f("facade")(option.mobile,[
                            '####-####',
                            '#####-####',
                            '(##) ####-####',
                            '(##) #####-####'
                          ]))+"\n                      "+_vm._s(_vm._f("facade")(option.telephone,[
                            '####-####',
                            '#####-####',
                            '(##) ####-####',
                            '(##) #####-####'
                          ]))+"\n                    ")])])])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v("\n                  "+_vm._s(option.name)+"\n                ")])]}}]),model:{value:(_vm.form.organization),callback:function ($$v) {_vm.$set(_vm.form, "organization", $$v)},expression:"form.organization"}},'v-select',attrs,false),listeners),[_c('template',{slot:"no-options"},[_vm._v(" Digite para procurar... ")])],2)}}])}),_vm._v(" "),(_vm.form.organization)?_c('div',{staticClass:"small"},[_c('hr'),_vm._v(" "),_c('b-link',{attrs:{"to":{
                name: 'Org View',
                params: { id: _vm.form.organization.id }
              }}},[_c('b-icon-building',{staticClass:"w-100",attrs:{"font-scale":"2","variant":"primary"}})],1),_vm._v(" "),_c('FormToOrganizations',{attrs:{"organization":_vm.form.organization}})],1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius"},[_c('form-group',{staticClass:"form-group font-weight-bold",attrs:{"name":"contact"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"align-items-center d-flex"},[_c('b-icon-person-fill'),_vm._v("\n                Contato\n              ")],1)]},proxy:true},{key:"default",fn:function(ref){
              var attrs = ref.attrs;
              var listeners = ref.listeners;
return _c('v-select',_vm._g(_vm._b({staticClass:"w-100 h-auto",attrs:{"label":"name","filterable":false,"options":_vm.peopleOptions,"placeholder":'Procure aqui...',"taggable":false,"append-to-body":true,"calculate-position":_vm.dropPos},on:{"search":_vm.getPeople},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('div',[_c('b-icon-person-circle',{staticClass:"mr-2",attrs:{"font-scale":"1.5"}})],1),_vm._v(" "),_c('div',{staticClass:"flex-column"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(option.name))]),_vm._v(" "),_c('div',[_vm._v("\n                      "+_vm._s(option.email)+"\n                      "+_vm._s(_vm._f("facade")(option.mobile,[
                            '####-####',
                            '#####-####',
                            '(##) ####-####',
                            '(##) #####-####'
                          ]))+"\n                      "+_vm._s(_vm._f("facade")(option.telephone,[
                            '####-####',
                            '#####-####',
                            '(##) ####-####',
                            '(##) #####-####'
                          ]))+"\n                    ")])])])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v("\n                  "+_vm._s(option.name)+"\n                ")])]}}]),model:{value:(_vm.form.contact),callback:function ($$v) {_vm.$set(_vm.form, "contact", $$v)},expression:"form.contact"}},'v-select',attrs,false),listeners),[_c('template',{slot:"no-options"},[_vm._v(" Digite para procurar... ")])],2)}}])}),_vm._v(" "),(_vm.form.contact)?_c('div',{staticClass:"small"},[_c('hr'),_vm._v(" "),_c('b-link',{attrs:{"to":{
                name: 'Person View',
                params: { id: _vm.form.contact.id }
              }}},[_c('b-icon-person-fill',{staticClass:"w-100",attrs:{"font-scale":"2","variant":"primary"}})],1),_vm._v(" "),_c('FormToPeople',{attrs:{"person":_vm.form.contact}})],1):(_vm.form.organization)?_c('div',[(_vm.seePeople)?_c('div',[_c('ul',_vm._l((_vm.seePeople),function(p){return _c('li',{key:p.id,staticClass:"triggerSelect"},[_c('b-link',{on:{"click":function($event){return _vm.selectPerson(p)}}},[_vm._v(_vm._s(p.name))])],1)}),0),_vm._v(" "),(_vm.seePeople.length >= 9)?_c('div',[(!_vm.seeMorePeople)?_c('b-link',{on:{"click":function($event){_vm.seeMorePeople = true}}},[_vm._v("Ver mais...")]):_c('b-link',{on:{"click":function($event){_vm.seeMorePeople = false}}},[_vm._v("Reduzir...")])],1):_vm._e()]):_vm._e()]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius"},[_c('form-group',{staticClass:"form-group font-weight-bold",attrs:{"name":"customer"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"align-items-center d-flex"},[_c('b-icon-person'),_vm._v("\n                Cliente\n              ")],1)]},proxy:true},{key:"default",fn:function(ref){
              var attrs = ref.attrs;
              var listeners = ref.listeners;
return _c('v-select',_vm._g(_vm._b({staticClass:"w-100 h-auto",attrs:{"label":"name","filterable":false,"options":_vm.peopleOptions,"placeholder":'Procure aqui...',"taggable":false,"append-to-body":true,"calculate-position":_vm.dropPos},on:{"search":_vm.getPeople},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('div',[_c('b-icon-person-circle',{staticClass:"mr-2",attrs:{"font-scale":"1.5"}})],1),_vm._v(" "),_c('div',{staticClass:"flex-column"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(option.name))]),_vm._v(" "),_c('div',[_vm._v("\n                      "+_vm._s(option.email)+"\n                      "+_vm._s(_vm._f("facade")(option.mobile,[
                            '####-####',
                            '#####-####',
                            '(##) ####-####',
                            '(##) #####-####'
                          ]))+"\n                      "+_vm._s(_vm._f("facade")(option.telephone,[
                            '####-####',
                            '#####-####',
                            '(##) ####-####',
                            '(##) #####-####'
                          ]))+"\n                    ")])])])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v("\n                  "+_vm._s(option.name)+"\n                ")])]}}]),model:{value:(_vm.form.customer),callback:function ($$v) {_vm.$set(_vm.form, "customer", $$v)},expression:"form.customer"}},'v-select',attrs,false),listeners),[_c('template',{slot:"no-options"},[_vm._v(" Digite para procurar... ")])],2)}}])}),_vm._v(" "),(_vm.form.customer)?_c('div',{staticClass:"small"},[_c('hr'),_vm._v(" "),_c('b-link',{attrs:{"to":{
                name: 'Person View',
                params: { id: _vm.form.customer.id }
              }}},[_c('b-icon-person-fill',{staticClass:"w-100",attrs:{"font-scale":"2","variant":"primary"}})],1),_vm._v(" "),_c('FormToPeople',{attrs:{"person":_vm.form.customer}})],1):_vm._e()],1),_vm._v(" "),_c('b-row',{attrs:{"align-h":"end"}},[_c('b-button',{staticClass:"m-5",attrs:{"block":"","variant":"success"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Salvar")])],1)],1)],1),_vm._v(" "),_c('b-row',{staticClass:"small"},[_vm._v("\n      Criado em: "+_vm._s(_vm.createdAt)+" "),_c('br'),_vm._v("\n      Criado por: "+_vm._s(_vm.creatorId)+" "),_c('br'),_c('br'),_vm._v("\n      Atualizado em: "+_vm._s(_vm.updatedAt)+" "),_c('br'),_vm._v("\n      Atualizado por: "+_vm._s(_vm.lastUpdateById)+"\n    ")])],1),_vm._v(" "),_c('go-top',{attrs:{"size":35,"right":15,"bottom":25,"fg-color":"#000000","bg-color":"#eaeaea","weight":"bold"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }