import { helpers } from 'vuelidate/lib/validators'
/*eslint no-unused-vars: */

let CPF_BLACKLIST = [
  "00000000000",
  "11111111111",
  "22222222222",
  "33333333333",
  "44444444444",
  "55555555555",
  "66666666666",
  "77777777777",
  "88888888888",
  "99999999999",
  "12345678909",
  "01234567890"
];

let CNPJ_BLACKLIST = [
  "00000000000000",
  "11111111111111",
  "22222222222222",
  "33333333333333",
  "44444444444444",
  "55555555555555",
  "66666666666666",
  "77777777777777",
  "88888888888888",
  "99999999999999"
];

const isBetweenLength = (min, max) => {
  return helpers.withParams(
    { max: max, min: min },
    (value) => {
      return !helpers.req(value) || value != null && value.length >= min && value.length <= max
    }
  )
}



const sameAsIf = (attr, condition) => {
  return helpers.withParams({ attr }, function (value, parentVm) {
    let eq = value === helpers.ref(attr, this, parentVm)
    return helpers.ref(condition, this, parentVm) ? eq : true
  })
}

const isValidPrice = (value) => {
  let isPriceUsingDots = /^\d+(,\d{3})*(\.\d{1,2})?$/.test(value)
  let isPriceUsingComma = /^\d+(\.\d{3})*(,\d{1,2})?$/.test(value)
  return isPriceUsingDots || isPriceUsingComma
}




const isValidQuantity = (str) => {
  var n = Math.floor(Number(str))
  return n !== Infinity && n > 0
}

const isValidPostcode = (postcode) => {
  var regex = /^[0-9]{2}.[0-9]{3}-[0-9]{3}$/;
  return regex.test(postcode)
}

const isValidDocument = (cpfcnpj) => {

  // HELPER FROM VUELIDATE TO NOT REQUIRED(OPTIONAL) FIELD
  if (!helpers.req(cpfcnpj)) {
    return false
  }

  // must have 11 or 14 chars
  let cpfcnpjStrip = cpfcnpj.replace(/[^\d]/g, '')
  if (cpfcnpjStrip.length !== 11 && cpfcnpjStrip.length !== 14) {
    return false
  }

  if (CPF_BLACKLIST.includes(cpfcnpjStrip) || CNPJ_BLACKLIST.includes(cpfcnpjStrip)) {
    return false
  }

  var regexCPF = /^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$/
  var regexCNPJ = /^[0-9]{2}.[0-9]{3}.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/

  // if CPF runs verifier
  if (regexCPF.test(cpfcnpj)) {
    // CPF verifier
    const verifCPF = num => {
      numbers = num.split('').map(number => {
        return parseInt(number, 10)
      })

      let modulus = numbers.length + 1
      let multiplied = numbers.map(
        (number, index) => number * (modulus - index)
      )
      let mod = multiplied.reduce((buffer, number) => buffer + number) % 11
      return mod < 2 ? 0 : 11 - mod
    }

    let numbers = cpfcnpjStrip.substr(0, 9)
    numbers += verifCPF(numbers)
    numbers += verifCPF(numbers)

    return numbers.substr(-2) === cpfcnpjStrip.substr(-2)
  }

  // if CNPJ runs verifier
  else if (regexCNPJ.test(cpfcnpj)) {
    // CNPJ verifier
    const verifCNPJ = num => {
      let index = 2
      let reverse = num.split('').reduce((buffer, number) => {
        return [parseInt(number, 10)].concat(buffer)
      }, [])

      let sum = reverse.reduce((buffer, number) => {
        buffer += number * index
        index = index === 9 ? 2 : index + 1
        return buffer
      }, 0)

      const mod = sum % 11
      return mod < 2 ? 0 : 11 - mod
    }

    let numbers = cpfcnpjStrip.substr(0, 12)
    numbers += verifCNPJ(numbers)
    numbers += verifCNPJ(numbers)

    return numbers.substr(-2) === cpfcnpjStrip.substr(-2)
  }
  return false
}

// TODO: fix bug with \s working with tab
const isValidPhone = (phone) => {
  var regex = /^\((\d){2}\)\s(\d){4,5}-(\d){4}$/
  return regex.test(phone)
}

const isValidMultipleEmails = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  }
  return /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/.test(value)
}

const anyOfRequired = (arr) => {
  return helpers.withParams(
    { arr },
    (value, parentVm) => {
      return arr.some((val, index) => {
        return helpers.req(parentVm[val])
      })
    }
  )
}

export { isValidPrice, isValidQuantity, isValidPostcode, isValidDocument, isBetweenLength, isValidPhone, sameAsIf, isValidMultipleEmails, anyOfRequired }

