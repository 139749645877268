<template>
  <b-container fluid>
    <b-row class="text-center my-2" align-h="between" no-gutters>
      <b-col cols="6" md="2">
        <b-button
          variant="link"
          :class="[
            'text-secondary',
            activeTable == 'deals' ? 'bg-graylight font-weight-bold' : ''
          ]"
          @click="fetchDeals"
          ><b-icon-files variant="primary" /> Negócios</b-button
        >
      </b-col>
      <b-col cols="6" md="2">
        <b-button
          variant="link"
          :class="[
            'text-secondary',
            activeTable == 'organizations'
              ? 'bg-graylight font-weight-bold'
              : ''
          ]"
          @click="fetchOrganizations"
          ><b-icon-building variant="primary" /> Organizações</b-button
        >
      </b-col>
      <b-col cols="6" md="2">
        <b-button
          variant="link"
          :class="[
            'text-secondary',
            activeTable == 'people' ? 'bg-graylight font-weight-bold' : ''
          ]"
          @click="fetchPeople"
          ><b-icon-person variant="primary" /> Pessoas</b-button
        >
      </b-col>
      <b-col cols="6" md="2">
        <b-button
          variant="link"
          :class="[
            'text-secondary',
            activeTable == 'activities' ? 'bg-graylight font-weight-bold' : ''
          ]"
          @click="fetchActivities"
          ><b-icon-list-task variant="primary" /> Atividades</b-button
        >
      </b-col>
      <b-col cols="3" md="1" class="px-1 my-2">
        <b-button block size="sm" @click="refreshTable"
          ><b-icon-arrow-clockwise :rotate="30" scale="1.25" /> Reset</b-button
        >
      </b-col>
      <b-col cols="9" md="1" class="px-1 my-2">
        <b-dropdown
          block
          text="Colunas"
          menu-class="min-width-250 shadow-8"
          boundary="viewport"
          size="sm"
          no-flip
          lazy
        >
          <b-dropdown-form
            v-for="(col, i) in menu"
            :key="i"
            class="btn-outline-secondary"
            style="cursor: pointer"
            @click.stop="
              col.action($event)
            refreshMenu()
              "
          >
            <b-row align-v="center">
              <b-checkbox
                v-if="'visible' in col"
                :value="col.visible"
                :checked="true"
              />
              <span v-else />
              {{ col.label }}
            </b-row>
          </b-dropdown-form>
        </b-dropdown>
      </b-col>
    </b-row>
    <b-row align-h="between" align-v="center" class="my-2 text-center">
      <form-wrapper
        :validator="$v.form"
        scope="table_attributes"
        class="d-flex flex-column flex-md-row col-12"
      >
        <form-group name="filter" class="form-group small col mb-0">
          <v-select
            v-model="form.filter"
            slot-scope="{ attrs, listeners }"
            class="w-100"
            v-bind="attrs"
            name="filter"
            placeholder="Filtrar coluna..."
            :options="activeColumns"
            :get-option-label="(c) => c.getDefinition().title"
            :get-option-key="(c) => c.getDefinition().title"
            :clearable="false"
            v-on="listeners"
          />
        </form-group>
        <form-group name="condition" class="small col mb-0">
          <v-select
            v-model="form.condition"
            slot-scope="{ attrs, listeners }"
            class="w-100"
            name="condition"
            v-bind="attrs"
            placeholder="Condição"
            :options="filterConditions"
            :clearable="false"
            :selectable="(e) => !e.disabled"
            v-on="listeners"
          />
        </form-group>
        <form-group name="value" class="small col mb-0">
          <b-input
            v-model="form.value"
            slot-scope="{ attrs, listeners }"
            name="value"
            v-bind="attrs"
            placeholder="Valor..."
            class="w-100 p-1 h-100 no-grip"
            style="font-size: 1.1em"
            :disabled="disableValue"
            v-on="listeners"
            @keydown.enter="addFilter"
          />
        </form-group>
        <b-button class="col col-md-2 my-2" block size="sm" @click="addFilter">
          Filtrar</b-button
        >
        <b-radio-group
          v-model="andOr"
          :options="['E', 'OU']"
          checked="E"
          class="col col-md-1"
        />
      </form-wrapper>
    </b-row>
    <div
      v-for="(f, i) in filtersFlatted"
      :key="i"
      :class="[
        'row justify-content-around align-items-center',
        f.depth == 0 ? 'bg-graylight' : ''
      ]"
    >
      <div class="font-weight-bold col-1">{{ f.depth == 0 ? 'E' : 'OU' }}</div>
      <div class="col-2 text-center">{{ tableAttr[f.field] }}</div>
      <div class="col-2 text-center">{{ tableAttr[f.type] }}</div>
      <div class="col-2 text-center">{{ f.value || tableAttr[f.value] }}</div>
      <b-button variant="link" class="col-5" @click="removeFilter(f)"
        ><b-icon-x-circle variant="danger" class="float-right"
      /></b-button>
    </div>

    <b-row no-gutters>
      <b-col>
        <Tabulator
          ref="table"
          class="table-bordered table-striped table-sm border-0 nice-scroll"
          :options="activeTableOptions"
          :table-data="activeTableData"
          @data-loaded="dataLoaded"
          @row-click="rowClick"
          @column-moved="columnMoved"
          @ajax-response="ajaxResponse"
        ></Tabulator>
        <div class="small text-black-50 text-right">
          {{ maxNo }} resultados<br />
          {{ maxPages }} páginas
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import Tabulator from './Tabulator.vue'
import TableAttr from '../packs/locales/en/table_attributes.json'

import ActivityForm from './ActivityForm.vue'

import find from 'lodash/find'

export default {
  metaInfo: {
    title: 'Tabela'
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ActivityForm,
    Tabulator
  },
  data() {
    return {
      tableAttr: TableAttr,
      activeTable: null,
      activeTableOptions: {},
      activeTableData: [],
      columns: [],
      menu: null,
      filterConditions: [
        { id: 1, label: 'é vazio', value: '=', disableValue: true },
        { id: 2, label: 'não é vazio', value: '!=', disableValue: true },
        { id: 3, label: '──────────', disabled: true, disableValue: false },
        { id: 4, label: 'contém', value: 'like', disableValue: false },
        { id: 5, label: 'não contém', value: '!=', disableValue: false },
        { id: 6, label: '──────────', disabled: true, disableValue: false },
        { id: 7, label: 'igual a', value: '=', disableValue: false },
        { id: 8, label: 'não é igual a', value: '!=', disableValue: false },
        { id: 9, label: '──────────', disabled: true, disableValue: false },
        { id: 10, label: 'começa com', value: 'starts', disableValue: false },
        { id: 11, label: 'termina com', value: 'ends', disableValue: false },
        { id: 12, label: '──────────', disabled: true, disableValue: false },
        { id: 13, label: 'maior que (>)', value: '>', disableValue: false },
        {
          id: 14,
          label: 'maior ou igual que (>=)',
          value: '>=',
          disableValue: false
        },
        { id: 15, label: 'menor que (<)', value: '<', disableValue: false },
        {
          id: 16,
          label: 'menor ou igual que (<=)',
          value: '<=',
          disableValue: false
        }
      ],
      filters: null,
      maxNo: null,
      maxPages: null,
      form: {
        filter: null,
        condition: null,
        value: null
      },
      andOr: 'E',
      selectedView: null,
      showFilters: false
    }
  },

  validations: {
    form: {
      filter: { required },
      condition: { required },
      value: {}
    }
  },

  computed: {
    activeInstance() {
      return this.$refs.table.tabulatorInstance
    },
    activeColumns() {
      return this.columns.filter((c) => {
        return c.isVisible()
      })
    },
    disableValue() {
      return this.form.condition?.disableValue
    },
    filtersFlatted() {
      if (!this.filters) return
      let sliced = this.filters.slice(0)
      this.addDepth(sliced)
      return sliced.flat()
    }
  },

  watch: {
    activeTable: {
      handler(newValue) {
        switch (newValue) {
          case 'deals':
            this.activeTableOptions = this.tableDealsOptions()
            localStorage.setItem('activeTable', 'deals')
            break
          case 'organizations':
            this.activeTableOptions = this.tableOrganizationsOptions()
            localStorage.setItem('activeTable', 'organizations')
            break
          case 'people':
            this.activeTableOptions = this.tablePeopleOptions()
            localStorage.setItem('activeTable', 'people')
            break
          case 'activities':
            this.activeTableOptions = this.tableActivitiesOptions()
            localStorage.setItem('activeTable', 'activities')
            break
          default:
            this.activeTableOptions = this.tableDealsOptions()
            localStorage.setItem('activeTable', 'deals')
            break
        }
      }
    }
  },

  created() {
    if (localStorage.getItem('activeTable')) {
      this.activeTable = localStorage.getItem('activeTable')
    } else {
      this.activeTable = 'deals'
    }
  },

  methods: {
    returnStage(id) {
      return find(this.$store.getters.stages, { id: id })
    },

    returnStatusName(id) {
      return find(this.$store.getters.statuses, { id: id }).name
    },

    dataLoaded() {
      this.startColumns()
      this.filters = this.activeInstance?.getFilters()
    },

    rowClick() { },

    columnMoved() {
      this.startColumns()
    },

    refreshPage(page) {
      this.activeInstance.setPage(page)
    },

    addFilter() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      let value = this.form.value

      if (this.form.condition.disableValue) value = null

      let statement = {
        field: this.form.filter.getField(),
        type: this.form.condition.value,
        value: value
      }

      if (this.filtersFlatted.length == 0) {
        if (this.andOr == 'E') {
          this.activeInstance.addFilter(
            statement.field,
            statement.type,
            statement.value
          )
        } else {
          this.activeInstance.setFilter([[statement]])
        }
      } else {
        let arr1 = []
        let arr2 = []

        for (let i = 0; i < this.filtersFlatted.length; i++) {
          const element = this.filtersFlatted[i]
          if (element.depth == 0) {
            arr1.push(element)
          } else {
            arr2.push(element)
          }
        }

        if (this.andOr == 'E') {
          arr1.push(statement)
        } else {
          arr2.push(statement)
        }

        arr1.push(arr2)

        this.activeInstance.setFilter(arr1)
      }
    },

    removeFilter(f) {
      console.log(f)

      let arr1 = []
      let arr2 = []

      for (let i = 0; i < this.filtersFlatted.length; i++) {
        const element = this.filtersFlatted[i]
        if (element.index == f.index && element.depth == f.depth) continue
        if (element.depth == 0) {
          arr1.push(element)
        } else {
          arr2.push(element)
        }
      }

      arr1.push(arr2)

      this.activeInstance.setFilter(arr1)
    },

    addDepth(arr, depth = 0) {
      console.log(arr)
      arr.forEach((obj, index) => {
        obj.index = index
        obj.depth = depth
        if (Array.isArray(obj)) {
          this.addDepth(obj, depth + 1)
        }
      })
    },

    refreshTable() {
      // remove all local storage
      var arr = []
      for (var i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i).substring(0, 9) == 'tabulator') {
          arr.push(localStorage.key(i))
        }
      }
      for (var j = 0; j < arr.length; j++) {
        localStorage.setItem(arr[j], '[]')
      }
      localStorage.setItem('activeTable', '')

      // set columns to default
      switch (this.activeTable) {
        case 'deals':
          this.activeInstance.setColumns(this.tableDealsOptions().columns)
          break
        case 'organizations':
          this.activeInstance.setColumns(
            this.tableOrganizationsOptions().columns
          )
          break
        case 'people':
          this.activeInstance.setColumns(this.tablePeopleOptions().columns)
          break
        case 'activities':
          this.activeInstance.setColumns(this.tableActivitiesOptions().columns)
          break
        default:
          this.activeInstance.setColumns(this.tableDealsOptions().columns)
          break
      }

      // reset sort and filter - unfortunately tabulator fetch twice in this case
      this.activeInstance.clearFilter()
      this.activeInstance.clearSort()

      this.startColumns()

      // set filters local to zero
      this.filters = this.activeInstance.getFilters()

      // only way to auto resize all columns - doubleclick all
      let double = document.createEvent('MouseEvents')
      double.initEvent('dblclick', true, true)
      document
        .querySelectorAll(
          '.tabulator-header .tabulator-col-resize-handle:not(.prev)'
        )
        .forEach((e) => e.dispatchEvent(double))

      // reset form
      this.form.filter = null
      this.form.condition = null
      this.form.value = null
      this.$v.$reset()
    },

    ajaxResponse(url, params, response) {
      // current_page, current_per_page, last_page, total_count
      this.maxNo = response.total_count
      this.maxPages = response.last_page
    },

    fetchDeals() {
      this.activeTable = 'deals'
    },
    fetchOrganizations() {
      this.activeTable = 'organizations'
    },
    fetchPeople() {
      this.activeTable = 'people'
    },
    fetchActivities() {
      this.activeTable = 'activities'
    },
    startColumns() {
      if (!this.activeInstance) return
      this.columns = this.activeInstance.getColumns()
      this.refreshMenu()
    },
    refreshMenu() {
      this.menu = []
      let cols = this.columns
      for (let column of cols) {
        //create menu item
        this.menu.push({
          label: column.getDefinition().title,
          visible: column.isVisible(),
          action: function (e) {
            e.stopPropagation()
            column.toggle()
          }
        })
      }
      this.menu.unshift({
        label: 'Esconder todas',
        action: function (e) {
          e.stopPropagation()
          cols.forEach((e) => e.hide())
        }
      })
      this.menu.unshift({
        label: 'Mostrar todas',
        action: function (e) {
          e.stopPropagation()
          cols.forEach((e) => e.show())
        }
      })
    },

    getContrastYIQ(hexcolor) {
      if (!hexcolor) return 'black'
      hexcolor = hexcolor.replace('#', '')
      var r = parseInt(hexcolor.substr(0, 2), 16)
      var g = parseInt(hexcolor.substr(2, 2), 16)
      var b = parseInt(hexcolor.substr(4, 2), 16)
      var yiq = (r * 299 + g * 587 + b * 114) / 1000
      return yiq >= 128 ? 'black' : 'white'
    },

    editData(table, model, field, value, id) {
      var fd = new FormData()

      fd.append(`${model}[${field}]`, value)

      Rails.ajax({
        url: `/api/${table}/${id}`,
        type: 'PATCH',
        data: fd,
        dataType: 'json',
        success: () => {
          this.$root.toast('Sucesso', '', 'success')
        },
        error: (data) => {
          this.$root.toast('Erro - ' + data.status, data.status, 'danger')
        },
        complete: () => {
          this.refreshPage(this.activeInstance.getPage())
          this.saved = true
        }
      })
    },

    tableDealsOptions() {
      let vm = this
      return {
        pagination: 'remote',
        ajaxURL: '/api/table/deals',
        ajaxFiltering: true,
        ajaxSorting: true,
        height: 645,
        layout: 'fitData',
        movableColumns: true,
        persistenceID: 'deals',
        persistence: true,
        layoutColumnsOnNewData: true,
        columnMinWidth: 150,
        paginationSize: 15,
        paginationSizeSelector: [5, 15, 25, 50, 100, true],
        columns: [
          { field: 'id', title: this.tableAttr['id'], visible: false },
          {
            field: 'title',
            title: this.tableAttr['title'],
            sorter: 'alphanum',
            visible: true,
            formatter: function (cell) {
              var value = cell.getValue()
              let data = cell.getData()
              let status_name = find(vm.$store.getters.statuses, {
                id: data.deal_status_id
              }).name
              if (data.active == false) {
                return (
                  "<span class='text-muted btn-link' style='text-decoration: line-through;'>" +
                  value +
                  '</span>'
                )
              } else if (status_name == 'Em aberto') {
                return (
                  "<span class='text-primary font-weight-bold btn-link'>" +
                  value +
                  '</span>'
                )
              } else if (status_name == 'Perdido') {
                return (
                  "<span class='text-danger btn-link' style='text-decoration: line-through;'>" +
                  value +
                  '</span>'
                )
              } else if (status_name == 'Ganho') {
                return (
                  "<span class='text-success font-weight-bold btn-link'>" +
                  value +
                  '</span>'
                )
              }
              return (
                "<span class='text-secondary font-weight-bold btn-link'>" +
                value +
                '</span>'
              )
            },
            cellClick: function (e, cell) {
              vm.$router.push({
                name: 'Deal View',
                params: { id: cell.getData().id }
              })
            }
          },
          {
            field: 'value',
            title: this.tableAttr['value'],
            sorter: 'number',
            formatter: 'money',
            formatterParams: {
              decimal: ',',
              thousand: '.',
              symbol: 'R$ '
            },
            visible: true
          },
          {
            field: 'organization.name',
            title: this.tableAttr['organization'],
            sorter: 'alphanum',
            visible: true
          },
          {
            field: 'contact_person.name',
            title: this.tableAttr['contact_person'],
            sorter: 'alphanum',
            visible: true
          },
          {
            field: 'customer_person.name',
            title: this.tableAttr['customer_person'],
            sorter: 'alphanum',
            visible: true
          },
          {
            field: 'deal_stage_id',
            title: this.tableAttr['deal_stage_id'],
            sorter: 'alphanum',
            formatter: function (cell) {
              let value = cell.getValue()
              if (!value) return
              let stage = vm.returnStage(value)
              let element = cell.getElement()

              element.style.backgroundColor = stage.hex_color
              element.style.color = vm.getContrastYIQ(stage.hex_color)
              element.style.fontWeight = 'bold'

              return stage.name
            },
            editor: 'select',
            cellEdited: function (cell) {
              vm.editData(
                'deals',
                'deal',
                'deal_stage_id',
                cell.getValue(),
                cell.getData().id
              )
            },
            editorParams: {
              values: vm.$store.state.pipes.reduce((add, e) => {
                let obj = {}
                obj.label = e.name
                obj.options = []
                obj.options.push(
                  ...e.deal_stages.map((a) => {
                    return { label: a.name, value: a.id }
                  })
                )
                add.push(obj)
                return add
              }, [])
            },
            visible: true
          },
          {
            field: 'deal_status_id',
            title: this.tableAttr['deal_status_id'],
            sorter: 'alphanum',
            formatter: function (cell) {
              let value = vm.returnStatusName(cell.getValue())
              if (!value) return
              let data = cell.getData()
              let status_name = find(vm.$store.getters.statuses, {
                id: data.deal_status_id
              }).name
              if (data.active == false) {
                return (
                  "<span class='text-muted btn-link' style='text-decoration: line-through;'>" +
                  value +
                  '</span>'
                )
              } else if (status_name == 'Em aberto') {
                return (
                  "<span class='text-primary font-weight-bold btn-link'>" +
                  value +
                  '</span>'
                )
              } else if (status_name == 'Perdido') {
                return (
                  "<span class='text-danger btn-link' style='text-decoration: line-through;'>" +
                  value +
                  '</span>'
                )
              } else if (status_name == 'Ganho') {
                return (
                  "<span class='text-success font-weight-bold btn-link'>" +
                  value +
                  '</span>'
                )
              }
              return (
                "<span class='text-secondary font-weight-bold btn-link'>" +
                value +
                '</span>'
              )
            },
            visible: true,
            editor: 'select',
            cellEdited: function (cell) {
              vm.editData(
                'deals',
                'deal',
                'deal_status_id',
                cell.getValue(),
                cell.getData().id
              )
            },
            editorParams: {
              values: vm.$store.getters.statuses.flatMap((e) => {
                return { label: e.name, value: e.id }
              })
            }
          },
          {
            field: 'updated_at',
            title: this.tableAttr['updated_at'],
            sorter: 'datetime',
            sorterParams: {
              format: 'YYYY-MM-DD HH:mm:ss',
              alignEmptyValues: 'top'
            },
            formatter: function (cell) {
              return vm.moment(cell.getValue()).format('DD/MM/YYYY HH:mm:ss')
            },
            visible: true
          },
          {
            field: 'created_at',
            title: this.tableAttr['created_at'],
            sorter: 'datetime',
            sorterParams: {
              format: 'YYYY-MM-DD HH:mm:ss',
              alignEmptyValues: 'top'
            },
            formatter: function (cell) {
              return vm.moment(cell.getValue()).format('DD/MM/YYYY HH:mm:ss')
            },
            visible: false
          },
          {
            field: 'close_date',
            title: this.tableAttr['close_date'],
            sorter: 'datetime',
            sorterParams: {
              format: 'YYYY-MM-DD HH:mm:ss',
              alignEmptyValues: 'top'
            },
            formatter: function (cell) {
              return vm.moment(cell.getValue()).format('DD/MM/YYYY HH:mm:ss')
            },
            visible: false
          },
          {
            field: 'last_stage_update_when',
            title: this.tableAttr['last_stage_update_when'],
            sorter: 'datetime',
            sorterParams: {
              format: 'YYYY-MM-DD HH:mm:ss',
              alignEmptyValues: 'top'
            },
            formatter: function (cell) {
              return vm.moment(cell.getValue()).format('DD/MM/YYYY HH:mm:ss')
            },
            visible: false
          },
          {
            field: 'last_update_by_id',
            title: this.tableAttr['last_update_by_id'],
            visible: false
          },
          {
            field: 'owner_id',
            title: this.tableAttr['owner_id'],
            visible: false
          },
          {
            field: 'creator_id',
            title: this.tableAttr['creator_id'],
            visible: false
          },
          {
            field: 'active',
            sorter: 'boolean',
            title: this.tableAttr['active'],
            visible: false,
            formatter: function (cell) {
              let data = cell.getData()
              if (data.active == true) {
                return 'Sim'
              } else {
                return 'Não'
              }
            }
          },
          {
            field: 'archived',
            sorter: 'boolean',
            title: this.tableAttr['archived'],
            visible: false,
            formatter: function (cell) {
              let data = cell.getData()
              if (data.archived == true) {
                return 'Sim'
              } else {
                return 'Não'
              }
            }
          }

          // não criado colunas para position e observations
        ]
      }
    },
    tableOrganizationsOptions() {
      let vm = this
      return {
        pagination: 'remote',
        ajaxURL: '/api/table/organizations',
        ajaxFiltering: true,
        ajaxSorting: true,
        height: 645,
        layout: 'fitData',
        movableColumns: true,
        persistenceID: 'organizations',
        persistence: true,
        columnMinWidth: 150,
        paginationSize: 15,
        paginationSizeSelector: [5, 15, 25, 50, 100, true],
        columns: [
          { field: 'id', title: this.tableAttr['id'], visible: false },
          {
            field: 'name',
            title: this.tableAttr['name'],
            sorter: 'alphanum',
            visible: true,
            formatter: function (cell) {
              var value = cell.getValue()
              return (
                "<span class='text-primary font-weight-bold btn-link'>" +
                value +
                '</span>'
              )
            },
            cellClick: function (e, cell) {
              vm.$router.push({
                name: 'Org View',
                params: { id: cell.getData().id }
              })
            }
          },
          {
            field: 'mobile',
            title: this.tableAttr['mobile'],
            sorter: 'number',
            visible: true
          },
          {
            field: 'telephone',
            title: this.tableAttr['telephone'],
            sorter: 'number',
            visible: true
          },
          {
            field: 'email',
            title: this.tableAttr['email'],
            sorter: 'alphanum',
            visible: true
          },
          {
            field: 'address',
            title: this.tableAttr['address'],
            width: 250,
            visible: true
          },
          {
            field: 'updated_at',
            title: this.tableAttr['updated_at'],
            sorter: 'datetime',
            sorterParams: {
              format: 'YYYY-MM-DD HH:mm:ss',
              alignEmptyValues: 'top'
            },
            formatter: function (cell) {
              return vm.moment(cell.getValue()).format('DD/MM/YYYY HH:mm:ss')
            },
            visible: true
          },
          {
            field: 'created_at',
            title: this.tableAttr['created_at'],
            sorter: 'datetime',
            sorterParams: {
              format: 'YYYY-MM-DD HH:mm:ss',
              alignEmptyValues: 'top'
            },
            formatter: function (cell) {
              return vm.moment(cell.getValue()).format('DD/MM/YYYY HH:mm:ss')
            },
            visible: false
          },
          {
            field: 'cpf_cnpj',
            title: this.tableAttr['cpf_cnpj'],
            visible: false
          },
          {
            field: 'bank_details',
            title: this.tableAttr['bank_details'],
            visible: false
          },
          {
            field: 'owner_id',
            title: this.tableAttr['owner_id'],
            visible: false
          },
          {
            field: 'creator_id',
            title: this.tableAttr['creator_id'],
            visible: false
          },
          {
            field: 'industry_id',
            title: this.tableAttr['industry_id'],
            visible: false
          },
          {
            field: 'website',
            title: this.tableAttr['website'],
            visible: false
          },
          {
            field: 'active',
            sorter: 'boolean',
            title: this.tableAttr['active'],
            visible: false,
            formatter: function (cell) {
              let data = cell.getData()
              if (data.active == true) {
                return 'Sim'
              } else {
                return 'Não'
              }
            }
          }
          // não criado colunas para observations
        ]
      }
    },
    tablePeopleOptions() {
      let vm = this
      return {
        pagination: 'remote',
        ajaxURL: '/api/table/people',
        ajaxFiltering: true,
        ajaxSorting: true,
        height: 645,
        layout: 'fitData',
        movableColumns: true,
        persistenceID: 'people',
        persistence: true,
        columnMinWidth: 150,
        paginationSize: 15,
        paginationSizeSelector: [5, 15, 25, 50, 100, true],
        selectable: 2,
        selectableRollingSelection: false, // disable rolling selection
        columns: [
          {
            formatter: 'rowSelection',
            titleFormatter: 'rowSelection',
            align: 'center',
            headerSort: false,
            visible: false,
            minWidth: 'auto'
          },
          { field: 'id', title: this.tableAttr['id'], visible: false },
          {
            field: 'name',
            title: this.tableAttr['name'],
            sorter: 'alphanum',
            visible: true,
            formatter: function (cell) {
              var value = cell.getValue()
              return (
                "<span class='text-primary font-weight-bold btn-link'>" +
                value +
                '</span>'
              )
            },
            cellClick: function (e, cell) {
              vm.$router.push({
                name: 'Person View',
                params: { id: cell.getData().id }
              })
            }
          },
          {
            field: 'mobile',
            title: this.tableAttr['mobile'],
            sorter: 'number',
            visible: true
          },
          {
            field: 'telephone',
            title: this.tableAttr['telephone'],
            sorter: 'number',
            visible: true
          },
          {
            field: 'email',
            title: this.tableAttr['email'],
            sorter: 'alphanum',
            visible: true
          },
          {
            field: 'organization.name',
            title: this.tableAttr['organization'],
            sorter: 'alphanum',
            visible: true
          },
          {
            field: 'address',
            title: this.tableAttr['address'],
            width: 250,
            visible: true
          },
          {
            field: 'updated_at',
            title: this.tableAttr['updated_at'],
            sorter: 'datetime',
            sorterParams: {
              format: 'YYYY-MM-DD HH:mm:ss',
              alignEmptyValues: 'top'
            },
            formatter: function (cell) {
              return vm.moment(cell.getValue()).format('DD/MM/YYYY HH:mm:ss')
            },
            visible: true
          },
          {
            field: 'created_at',
            title: this.tableAttr['created_at'],
            sorter: 'datetime',
            sorterParams: {
              format: 'YYYY-MM-DD HH:mm:ss',
              alignEmptyValues: 'top'
            },
            formatter: function (cell) {
              return vm.moment(cell.getValue()).format('DD/MM/YYYY HH:mm:ss')
            },
            visible: false
          },
          {
            field: 'cpf_cnpj',
            title: this.tableAttr['cpf_cnpj'],
            visible: false
          },
          {
            field: 'bank_details',
            title: this.tableAttr['bank_details'],
            visible: false
          },
          {
            field: 'owner_id',
            title: this.tableAttr['owner_id'],
            visible: false
          },
          {
            field: 'creator_id',
            title: this.tableAttr['creator_id'],
            visible: false
          },
          {
            field: 'rg',
            title: this.tableAttr['rg'],
            visible: false
          },
          {
            field: 'active',
            sorter: 'boolean',
            title: this.tableAttr['active'],
            visible: false,
            formatter: function (cell) {
              let data = cell.getData()
              if (data.active == true) {
                return 'Sim'
              } else {
                return 'Não'
              }
            }
          }
          // não criado colunas para observations
        ]
      }
    },
    tableActivitiesOptions() {
      let vm = this
      return {
        pagination: 'remote',
        ajaxURL: '/api/table/activities',
        ajaxFiltering: true,
        ajaxSorting: true,
        height: 645,
        layout: 'fitData',
        movableColumns: true,
        persistenceID: 'activities',
        persistence: true,
        paginationSize: 15,
        paginationSizeSelector: [5, 15, 25, 50, 100, true],
        columns: [
          { field: 'id', title: this.tableAttr['id'], visible: false },
          {
            field: 'due_date',
            title: this.tableAttr['due_date'],
            sorter: 'datetime',
            sorterParams: {
              format: 'YYYY-MM-DD HH:mm:ss',
              alignEmptyValues: 'top'
            },
            formatter: function (cell) {
              return vm.moment(cell.getValue()).format('DD/MM/YYYY HH:mm:ss')
            },
            visible: true
          },
          {
            field: 'activity_type.name',
            title: this.tableAttr['activity_type'],
            sorter: 'alphanum',
            visible: true,
            formatter: function (cell) {
              var value = cell.getValue()
              let data = cell.getData()
              if (
                data.status == 'Em aberto' &&
                // eslint-disable-next-line no-undef
                moment(data.due_date).isBefore()
              ) {
                return (
                  "<span class='text-danger font-weight-bold btn-link'>" +
                  value +
                  '</span>'
                )
              } else if (data.status == 'Concluído') {
                return (
                  "<span class='text-success btn-link' style='text-decoration: line-through;'>" +
                  value +
                  '</span>'
                )
              }
              return (
                "<span class='text-primary font-weight-bold btn-link'>" +
                value +
                '</span>'
              )
            },
            cellClick: function (e, cell) {
              console.log(cell.getData())
              let h = vm.$createElement
              const vnode = h('ActivityForm', {
                props: { value: cell.getData() }
              })
              vm.$bvModal.msgBoxOk([vnode], {
                id: 'activity_modal',
                footerClass: 'd-none',
                title: 'Atividades',
                centered: true,
                size: 'lg'
              })
            }
          },
          {
            field: 'status',
            title: this.tableAttr['status'],
            visible: true
          },
          {
            field: 'deal.title',
            title: this.tableAttr['deal'],
            visible: true
          },
          {
            field: 'closed_date',
            title: this.tableAttr['closed_date'],
            sorter: 'datetime',
            sorterParams: {
              format: 'YYYY-MM-DD HH:mm:ss',
              alignEmptyValues: 'top'
            },
            formatter: function (cell) {
              return vm.moment(cell.getValue()).format('DD/MM/YYYY HH:mm:ss')
            },
            visible: true
          },
          {
            field: 'updated_at',
            title: this.tableAttr['updated_at'],
            sorter: 'datetime',
            sorterParams: {
              format: 'YYYY-MM-DD HH:mm:ss',
              alignEmptyValues: 'top'
            },
            formatter: function (cell) {
              return vm.moment(cell.getValue()).format('DD/MM/YYYY HH:mm:ss')
            },
            visible: true
          },
          {
            field: 'enrollments',
            title: this.tableAttr['enrollments'],
            headerSort: false,
            visible: true
          },
          {
            field: 'event_location',
            title: this.tableAttr['address'],
            width: 250,
            visible: true
          },
          {
            field: 'notes',
            title: this.tableAttr['notes'],
            formatter: function (cell) {
              let value = cell.getValue() || ''
              let newVal = value.replace(new RegExp('<[^>]*>', 'g'), ' ')
              return newVal
            },
            width: 250,
            maxWidth: 500,
            visible: true
          },
          {
            field: 'created_at',
            title: this.tableAttr['created_at'],
            sorter: 'datetime',
            sorterParams: {
              format: 'YYYY-MM-DD HH:mm:ss',
              alignEmptyValues: 'top'
            },
            formatter: function (cell) {
              return vm.moment(cell.getValue()).format('DD/MM/YYYY HH:mm:ss')
            },
            visible: false
          },
          {
            field: 'creator.name',
            title: this.tableAttr['creator_id'],
            visible: false
          },
          {
            field: 'last_update_by_id',
            title: this.tableAttr['last_update_by_id'],
            visible: false
          },
          {
            field: 'calendar_sync',
            title: 'Sync do GCalendar',
            visible: false
          },
          {
            field: 'event_alert',
            title: 'Alerta do Evento',
            visible: false
          },
          {
            field: 'event_duration',
            title: 'Duração',
            visible: false
          },
          {
            field: 'event_link',
            title: 'Link do evento',
            visible: false
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .min-width-250 {
  min-width: 250px;
}

.tabulator::v-deep {
  & .tabulator-col {
    background-color: #6c757d !important;
    color: white;
  }

  & .tabulator-col.tabulator-sortable[aria-sort='asc'] .tabulator-arrow {
    border-bottom: 6px solid rgb(0, 0, 0) !important;
  }

  & .tabulator-col.tabulator-sortable[aria-sort='desc'] .tabulator-arrow {
    border-top: 6px solid rgb(0, 0, 0) !important;
  }

  .tabulator-tableHolder::-webkit-scrollbar {
    height: 20px;
  }

  .tabulator-tableHolder::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .tabulator-tableHolder::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
    background-color: #d6dee1;
  }

  .tabulator-tableHolder::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
}

.v-select::v-deep .vs__dropdown-menu {
  max-height: 500px;
}
</style>