<template>
  <div>
    <form-wrapper :validator="$v.form" scope="deal" class="d-flex flex-column">
      <b-row v-if="value" align-h="center">
        <div v-if="value.status == 'Em aberto'">
          <b-badge
            v-if="moment(value.due_date).isBefore()"
            class="float-right"
            pill
            variant="danger"
            >Atrasado</b-badge
          >
          <b-badge v-else pill class="float-right" variant="success"
            >Planejado</b-badge
          >
        </div>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          :md="form.type &&
            (form.type.name === 'Medição' || form.type.name === 'Cronograma')
            ? '3'
            : '6'
            "
        >
          <form-group name="type" class="form-group" label="Tipo">
            <v-select
              v-model="form.type"
              slot-scope="{ attrs, listeners }"
              :options="activity_types"
              v-bind="attrs"
              class="w-100 h-100"
              name="type"
              label="name"
              :clearable="false"
              v-on="listeners"
              @input="changeDateTimeDefault"
            ></v-select>
          </form-group>
        </b-col>
        <b-col
          v-if="form.type &&
            (form.type.name === 'Medição' || form.type.name === 'Cronograma')
            "
          cols="12"
          md="3"
        >
          <form-group
            name="job_due_time"
            class="form-group"
            label="Prazo de entrega"
          >
            <flat-pickr
              v-model="form.job_due_time"
              slot-scope="{ attrs, listeners }"
              :config="flatpickrOptsJob"
              class="form-control bg-white text-center"
              name="due_date"
              v-bind="attrs"
              v-on="listeners"
            >
            </flat-pickr>
          </form-group>
          <div class="small text-muted mt-2">
            {{ daysDiff }}
          </div>
          <div :id="'popover-color-target'" class="p-1">
            <span>Cor</span>
            <div
              :style="{
                backgroundColor: form.hex_color || '#007bff',
                width: '100%',
                height: '10px'
              }"
            ></div>
          </div>
          <b-popover
            :target="'popover-color-target'"
            triggers="hover"
            placement="auto"
            boundary="window"
          >
            <form-group name="hex_color" class="form-group" label="Color">
              <color-picker
                :value="form.hex_color || '#007bff'"
                @input="form.hex_color = $event.hex"
              />
            </form-group>
          </b-popover>
        </b-col>
        <b-col cols="12" md="6">
          <form-group name="status" class="form-group" label="Status">
            <b-form-checkbox
              v-model="form.status"
              slot-scope="{ attrs, listeners }"
              value="Concluído"
              unchecked-value="Em aberto"
              v-bind="attrs"
              class="w-100 h-100 input-pointer"
              name="status"
              size="lg"
              v-on="listeners"
            >
              <span
                :class="{ 'text-primary': form.status == 'Em aberto' }"
                :style="{
                  textDecoration:
                    form.status == 'Concluído' ? 'line-through' : undefined,
                  cursor: 'pointer',
                  color: form.status == 'Concluído' ? '#28a745' : undefined
                }"
                >{{ form.status }}</span
              >
            </b-form-checkbox>
          </form-group>
        </b-col>
      </b-row>
      <form-group
        name="due_date"
        :class="[
          'form-group',
          computeIsAfter ? 'input-text-info' : 'input-text-danger'
        ]"
        label="Data de vencimento"
        required
      >
        <flat-pickr
          v-model="form.due_date"
          slot-scope="{ attrs, listeners }"
          :config="flatpickrOpts"
          class="form-control bg-white text-center"
          name="due_date"
          v-bind="attrs"
          v-on="listeners"
        >
        </flat-pickr>
      </form-group>

      <b-row>
        <b-col cols="12" md="9">
          <form-group
            name="enrollments"
            class="form-group"
            label="Atribuir a usuários"
            required
          >
            <v-select
              v-model="form.enrollments"
              slot-scope="{ attrs, listeners }"
              class="w-100 h-100 bg-white"
              placeholder="Selecione..."
              :options="owners"
              :multiple="true"
              name="enrollments"
              label="email"
              v-bind="attrs"
              v-on="listeners"
            >
            </v-select>
          </form-group>
        </b-col>
        <b-col cols="12" md="3">
          <form-group
            name="calendar_sync"
            class="form-group small mb-0"
            label="Google Calendar ?"
          >
            <b-form-checkbox
              v-model="form.calendar_sync"
              slot-scope="{ attrs, listeners }"
              value="Sim"
              unchecked-value="Não"
              v-bind="attrs"
              class="w-100 h-100 input-pointer"
              name="calendar_sync"
              size="sm"
              v-on="listeners"
            >
              <span
                :style="{
                  cursor: 'pointer',
                  color: form.calendar_sync == 'Não' ? '#dc3545' : undefined
                }"
                >{{ form.calendar_sync }}</span
              >
            </b-form-checkbox>
          </form-group>
        </b-col>
      </b-row>
      <b-row v-if="form.calendar_sync == 'Sim'">
        <b-col cols="12" md="6">
          <div style="position: absolute; right: 15px">
            <b-button
              v-for="(val, index) in addresses"
              :key="index"
              class="m-0 p-0 small"
              variant="link"
              @click="form.event_location = val.value"
              >{{ index }}</b-button
            >
          </div>
          <form-group
            name="event_location"
            class="form-group small"
            label="Local"
          >
            <b-input
              v-model="form.event_location"
              slot-scope="{ attrs, listeners }"
              v-bind="attrs"
              class="w-100 h-100"
              name="event_location"
              size="sm"
              v-on="listeners"
            >
            </b-input>
          </form-group>
        </b-col>
        <b-col cols="12" md="3">
          <form-group
            name="event_duration"
            class="form-group small"
            label="Período"
          >
            <v-select
              v-model="form.event_duration"
              slot-scope="{ attrs, listeners }"
              :options="['1 hora', 'Dia inteiro']"
              v-bind="attrs"
              class="w-100 h-100"
              name="event_duration"
              :clearable="false"
              v-on="listeners"
            >
            </v-select>
          </form-group>
        </b-col>
        <b-col cols="12" md="3">
          <form-group
            name="event_alert"
            class="form-group small"
            label="Alertar ?"
          >
            <b-form-checkbox
              v-model="form.event_alert"
              slot-scope="{ attrs, listeners }"
              value="Sim"
              unchecked-value="Não"
              v-bind="attrs"
              class="w-100 h-100 input-pointer"
              name="event_alert"
              size="sm"
              v-on="listeners"
            >
              <span
                :style="{
                  cursor: 'pointer',
                  color: form.event_alert == 'Não' ? '#dc3545' : undefined
                }"
                >{{ form.event_alert }}</span
              >
            </b-form-checkbox>
          </form-group>
        </b-col>
      </b-row>

      <tip-tap
        v-model="form.activity_note"
        class="tiptap-main-yellow w-100 nice-scroll"
      />
      <b-button
        v-if="!value"
        variant="primary"
        block
        class="ml-auto btn-sm mx-3 p-1 mt-2"
        @click="addActivity"
        >Adicionar atividade</b-button
      >
      <b-button
        v-else
        variant="primary"
        class="ml-auto mx-3 mt-2"
        @click="updateActivity"
        >Editar</b-button
      >
    </form-wrapper>
    <b-button
      v-if="value"
      variant="danger"
      size="sm"
      style="position: absolute; right: 15px; bottom: 15px"
      @click="deleteActivity"
      >Deletar</b-button
    >
    <div v-if="value" class="small text-muted">
      Criado em: {{ niceMoment(value.created_at) }}<br />
      Criado por: {{ value.creator.name }}<br />
      Atualizado em: {{ niceMoment(value.updated_at) }}<br />
      Atualizado por: {{ value.last_update_by_id }}<br />
      Concluído em: {{ niceMoment(value.closed_date) }}<br />
      <div v-if="value.event_id">
        Evento:
        <b-link
          v-if="value.event_link"
          target="_blank"
          :href="value.event_link"
        >
          Link para o evento do Google Calendar
        </b-link>
        <div v-else>nenhum evento registrado</div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/themes/material_blue.css'

import find from 'lodash/find'

import { Portuguese } from 'flatpickr/dist/l10n/pt.js'

import TipTap from './TipTap.vue'

import { Chrome } from 'vue-color'

let defaultColors = {
  hex: '#007bff'
}

export default {
  name: 'ActivityForm',

  components: {
    flatPickr,
    TipTap,
    'color-picker': Chrome
  },

  props: {
    value: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      activity_types: [],
      sync_calendar: [
        { text: 'Não', value: 'Não' },
        { text: 'Sim', value: 'Sim' }
      ],
      colors: defaultColors,
      form: {
        type: null,
        // round to next half hour
        due_date: new Date(
          Math.ceil((new Date().getTime() + 120000) / 1800000) * 1800000
        ).toISOString(),
        status: 'Em aberto',
        enrollments: [],
        activity_note: '',
        calendar_sync: 'Não',
        event_duration: '1 hora',
        event_location: null,
        event_alert: 'Não',
        job_due_time: null,
        hex_color: '#007bff'
      },
      flatpickrOpts: {
        wrap: true,
        position: 'auto center',
        dateFormat: 'Z',
        altFormat: 'd \\de F \\de Y (d/m/y) - l à\\s H:i',
        altInput: true,
        enableTime: true,
        time_24hr: true,
        locale: Portuguese
      },
      flatpickrOptsJob: {
        wrap: true,
        position: 'auto center',
        dateFormat: 'Z',
        altFormat: 'Y-m-d H:i',
        altInput: true,
        allowInput: true,
        enableTime: true,
        time_24hr: true,
        locale: Portuguese
      }
    }
  },

  computed: {
    computeIsAfter: function () {
      return this.moment(this.form.due_date).isAfter()
    },
    owners: function () {
      return this.$store.state.usersEmailsList
    },
    addresses: function () {
      return document.querySelectorAll('input[name=address]')
    },
    daysDiff() {
      const dueDate = this.moment(this.form.due_date).startOf('day')
      const jobDueTime = this.moment(this.form.job_due_time).startOf('day').add(1, 'days');
      const diff = jobDueTime.diff(dueDate, 'days')
      const workingDays = this.calculateWorkingDays(dueDate, jobDueTime)
      return `${workingDays} dias úteis (${diff} corridos)`
    }
  },

  created() {
    this.activity_types = this.$store.getters.activity_types
    this.form.type = this.activity_types[0]

    if (this.$store.state.user) {
      this.form.enrollments.push(
        find(this.owners, {
          email: this.$store.state.user.email
        })
      )
    } else {
      Rails.ajax({
        url: '/api/users',
        type: 'GET',
        dataType: 'json',
        success: (data) => {
          console.log(data)
          this.$store.state.user = data
          this.form.enrollments.push(
            find(this.owners, {
              email: this.$store.state.user.email
            })
          )
        },
        error: () => {
          this.$root.toast('Erro', 'App mounted without user logged.', 'danger')
        }
      })
    }

    if (this.value) {
      this.fillFields(this.value)
    }
  },

  validations: {
    form: {
      type: { required },
      due_date: { required },
      status: { required },
      enrollments: { required },
      activity_note: {},
      calendar_sync: {},
      event_duration: {},
      event_location: {},
      event_alert: {},
      job_due_time: {},
      hex_color: {}
    }
  },
  methods: {
    calculateWorkingDays(startDate, endDate) {
      let count = 0
      let currentDate = startDate.clone()
      while (currentDate.isSameOrBefore(endDate)) {
        if (currentDate.day() !== 0 && currentDate.day() !== 6) {
          count++
        }
        currentDate.add(1, 'days')
      }
      return count
    },
    addActivity() {
      this.$v.form.$touch()
      if (!this.$v.form.$anyDirty) return
      if (this.$v.form.$invalid) return

      console.log('add activity: ', this.form)

      var fd = new FormData()
      fd.append('activity[notes]', this.form.activity_note)
      fd.append('activity[deal_id]', this.$store.state.seeingDeal.id)
      fd.append('activity[activity_type_id]', this.form.type.id)
      fd.append('activity[status]', this.form.status)
      fd.append('activity[due_date]', this.form.due_date)
      fd.append('activity[calendar_sync]', this.form.calendar_sync)
      fd.append('activity[event_duration]', this.form.event_duration)
      fd.append('activity[event_location]', this.form.event_location)
      fd.append('activity[event_alert]', this.form.event_alert)
      fd.append('activity[job_due_time]', this.form.job_due_time)
      fd.append('activity[hex_color]', this.form.hex_color)

      for (let i = 0; i < this.form.enrollments.length; i++) {
        fd.append('activity[enrollments][]', this.form.enrollments[i].email)
      }

      Rails.ajax({
        url: `/api/activities`,
        type: 'POST',
        data: fd,
        dataType: 'json',
        success: () => {
          this.resetFields()
          this.$emit('sent')
          this.$root.toast(
            'Sucesso',
            'Atividade adicionada com sucesso!',
            'success'
          )
        },
        error: (data) => {
          this.$root.toast('Erro - ' + data.status, data.status, 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    updateActivity() {
      this.$v.form.$touch()
      if (!this.$v.form.$anyDirty) return
      if (this.$v.form.$invalid) return

      console.log('update activity: ', this.form)

      var fd = new FormData()
      fd.append('activity[notes]', this.form.activity_note)
      fd.append('activity[deal_id]', this.value.deal_id)
      fd.append('activity[activity_type_id]', this.form.type.id)
      fd.append('activity[status]', this.form.status)
      fd.append('activity[due_date]', this.form.due_date)
      fd.append('activity[calendar_sync]', this.form.calendar_sync)
      fd.append('activity[event_duration]', this.form.event_duration)
      fd.append('activity[event_location]', this.form.event_location)
      fd.append('activity[event_alert]', this.form.event_alert)
      fd.append('activity[job_due_time]', this.form.job_due_time)
      fd.append('activity[hex_color]', this.form.hex_color)

      for (let i = 0; i < this.form.enrollments.length; i++) {
        fd.append('activity[enrollments][]', this.form.enrollments[i].email)
      }

      Rails.ajax({
        url: `/api/activities/${this.value.id}`,
        type: 'PATCH',
        data: fd,
        dataType: 'json',
        success: () => {
          this.$bvModal.hide('activity_modal')
          this.$emit('updated')
          this.$root.toast(
            'Sucesso',
            'Atividade atualizada com sucesso!',
            'success'
          )
        },
        error: (data) => {
          this.$root.toast('Erro - ' + data.status, data.status, 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    deleteActivity() {
      Rails.ajax({
        url: `/api/activities/${this.value.id}`,
        type: 'DELETE',
        dataType: 'json',
        success: () => {
          this.$bvModal.hide('activity_modal')
          this.$root.toast(
            'Sucesso',
            'Atividade deletada com sucesso!',
            'success'
          )
        },
        error: (data) => {
          this.$root.toast('Erro - ' + data.status, data.status, 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    fillFields(obj) {
      console.log(obj)
      this.form.type = find(this.activity_types, { id: obj.activity_type_id })
      this.form.due_date = obj.due_date
      this.form.status = obj.status
      this.form.enrollments = obj.enrollments.map((e) => Object({ email: e }))
      this.form.activity_note = obj.notes
      this.form.event_location = obj.event_location
      this.form.event_duration = obj.event_duration
      this.form.event_alert = obj.event_alert
      this.form.job_due_time = obj.job_due_time
      this.form.hex_color = obj.hex_color
    },

    resetFields() {
      this.form.type = this.activity_types[0]
      this.form.due_date = new Date(
        Math.ceil((new Date().getTime() + 120000) / 1800000) * 1800000
      ).toISOString()
      this.form.status = 'Em aberto'
      this.form.enrollments = [
        find(this.owners, {
          email: this.$store.state.user.email
        })
      ]
      this.form.activity_note = ''
      this.form.calendar_sync = 'Não'
      this.form.event_duration = '1 hora'
      this.form.event_location = ''
      this.form.event_alert = 'Não'
      this.form.job_due_time = null
      this.form.hex_color = '#007bff'
    },

    niceMoment(date) {
      return this.moment(date).calendar(null, {
        sameDay: null,
        nextDay: null,
        nextWeek: function () {
          return this.day() === 0 || this.day() === 6
            ? '[Próximo] dddd [às] LT' // Saturday + Sunday
            : '[Próxima] dddd [às] LT' // Monday - Friday
        },
        lastDay: null,
        lastWeek: null,
        sameElse: 'ddd LLL'
      })
    },

    changeDateTimeDefault() {
      this.form.due_date = new Date(
        new Date(
          Math.ceil((new Date().getTime() + 120000) / 1800000) * 1800000
        ).getTime() + (this.form.type.how_much_time_default * 1000 || 0)
      ).toISOString()
    }
  }
}
</script>

<style lang="scss" scoped>
// others css are in dealview at ::v-deep
input {
  background-color: white;
}

.input-pointer::v-deep input {
  cursor: pointer;
  z-index: 1;
}

.v-select {
  background-color: white;
}

.tiptap-main::v-deep {
  & .ProseMirror {
    background-color: white;
    min-height: 100px;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    padding: 0.5rem;
    max-height: 300px;
    overflow: auto;

    &::-webkit-scrollbar {
      height: 20px;
      width: 20px;
    }

    &::-webkit-scrollbar-track {
      //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      border: 6px solid transparent;
      background-clip: content-box;
      background-color: #d6dee1;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #a8bbbf;
    }
  }
}

::v-deep {
  .input-text-info input {
    color: #17a2b8;
  }

  .input-text-danger input {
    color: #dc3545;
  }
}

.tiptap-main-yellow::v-deep {
  & .ProseMirror {
    background-color: lightyellow;
    min-height: 100px;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    padding: 0.5rem;

    p.is-editor-empty:first-child::before {
      color: #6c757d;
    }
  }
}
</style>
