export default {
  name: 'FormWrapper',
  props: {
    validator: {
      type: Object,
      required: true
    },
    messages: {
      type: Object,
      default: () => ({})
    },
    scope: {
      type: String,
      default: () => "teste"
    }

  },
  render(h) {
    return h('div', this.$slots.default)
  },
  provide() {
    return {
      scope: this.scope,
      formValidator: this.validator,
      formMessages: this.messages
    }
  }
}