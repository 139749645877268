<template>
  <div>
    <template v-for="(item, index) in items">
      <div v-if="item.type === 'divider'" :key="index" class="divider" />
      <menu-item v-else :key="index" v-bind="item" />
    </template>
  </div>
</template>

<script>
import MenuItem from './MenuItem.vue'

export default {
  components: {
    MenuItem
  },

  props: {
    editor: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      items: [
        {
          icon: 'type-bold',
          title: 'Negrito',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold')
        },
        {
          icon: 'type-italic',
          title: 'Itálico',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic')
        },
        {
          icon: 'type-strikethrough',
          title: 'Riscar texto',
          action: () => this.editor.chain().focus().toggleStrike().run(),
          isActive: () => this.editor.isActive('strike')
        },
        {
          icon: 'code',
          title: 'Code',
          action: () => this.editor.chain().focus().toggleCode().run(),
          isActive: () => this.editor.isActive('code')
        },
        {
          icon: 'pen',
          title: 'Marca-texto',
          action: () => this.editor.chain().focus().toggleHighlight().run(),
          isActive: () => this.editor.isActive('highlight')
        },
        {
          type: 'divider'
        },
        {
          icon: 'type-h1',
          title: 'Titulo 1',
          action: () =>
            this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 1 })
        },
        {
          icon: 'type-h2',
          title: 'Titulo 2',
          action: () =>
            this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 2 })
        },
        {
          icon: 'paragraph',
          title: 'Parágrafo',
          action: () => this.editor.chain().focus().setParagraph().run(),
          isActive: () => this.editor.isActive('paragraph')
        },
        {
          icon: 'list-ul',
          title: 'Lista com marcadores',
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive('bulletList')
        },
        {
          icon: 'list-ol',
          title: 'Lista numerada',
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive('orderedList')
        },
        {
          icon: 'code-slash',
          title: 'Code Block',
          action: () => this.editor.chain().focus().toggleCodeBlock().run(),
          isActive: () => this.editor.isActive('codeBlock')
        },
        {
          type: 'divider'
        },
        {
          icon: 'text-indent-left',
          title: 'Quotação',
          action: () => this.editor.chain().focus().toggleBlockquote().run(),
          isActive: () => this.editor.isActive('blockquote')
        },
        {
          icon: 'hr',
          title: 'Divisor',
          action: () => this.editor.chain().focus().setHorizontalRule().run()
        },
        {
          type: 'divider'
        },
        {
          icon: 'arrow-down',
          title: 'Enter',
          action: () => this.editor.chain().focus().setHardBreak().run()
        },
        {
          icon: 'slash',
          title: 'Limpar formatação',
          action: () =>
            this.editor.chain().focus().clearNodes().unsetAllMarks().run()
        },
        {
          type: 'divider'
        },
        {
          icon: 'arrow-return-left',
          title: 'Desfazer',
          action: () => this.editor.chain().focus().undo().run()
        },
        {
          icon: 'arrow-return-right',
          title: 'Re-fazer',
          action: () => this.editor.chain().focus().redo().run()
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.divider {
  width: 2px;
  height: 1.25rem;
  background-color: rgba(black, 0.1);
  margin-left: 0.5rem;
  margin-right: 0.75rem;
}
</style>