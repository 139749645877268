<template>
  <b-row
    align-h="center"
    align-v="center"
    class="sticky-to-top"
    style="z-index: 10"
    no-gutters
  >
    <b-col cols="auto" class="mobile-absolute-1">
      <b-link
        :to="{ name: 'Home' }"
        class="d-flex flex-column align-items-center m-md-2"
      >
        <b-icon-filter font-scale="1.5" />
        <span class="small">Funil</span>
      </b-link>
    </b-col>
    <b-col cols="10" md="5" class="mobile-absolute-3">
      <v-select
        v-model="form.search"
        :get-option-key="
          (option) =>
            option.id +
            (option.name || '').substr(4) +
            (option.telephone || '').substr(-4)
        "
        class="h-auto"
        label="name"
        :filterable="false"
        :options="searchOpts"
        :placeholder="'Procure aqui...'"
        :taggable="false"
        :append-to-body="true"
        :calculate-position="dropPos"
        @search="getSearch"
        @input="setSearchGoto"
      >
        <template slot="no-options"> Digite para procurar... </template>
        <template slot="option" slot-scope="option">
          <div class="d-flex flex-row align-items-center">
            <div>
              <b-icon-person
                v-if="'organization_id' in option"
                font-scale="1.5"
                class="mr-2"
              />
              <b-icon-files
                v-else-if="'stage' in option"
                font-scale="1.5"
                class="mr-2"
              />
              <b-icon-building v-else font-scale="1.5" class="mr-2" />
            </div>
            <div class="flex-column">
              <div class="font-weight-bold">{{ option.name }}</div>
              <div v-if="'stage' in option">
                {{ option.status ? option.status.name : '' }} -
                {{ option.stage ? option.stage.name : '' }}
              </div>
              <div v-else>
                {{ option.email }}
                {{
                  option.mobile
                    | facade([
                      '####-####',
                      '#####-####',
                      '(##) ####-####',
                      '(##) #####-####'
                    ])
                }}
                {{
                  option.telephone
                    | facade([
                      '####-####',
                      '#####-####',
                      '(##) ####-####',
                      '(##) #####-####'
                    ])
                }}
              </div>
            </div>
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          <div class="selected d-center">
            {{ option.name }}
          </div>
        </template>
      </v-select>
    </b-col>
    <b-col cols="auto" class="position-static">
      <b-dropdown
        id="dropdown-1"
        class="m-md-2 mobile-absolute-2"
        no-caret
        boundary="window"
      >
        <template #button-content>
          <b-icon-plus scale="1.5" />
        </template>
        <b-dropdown-item :to="{ name: 'Deal New' }"
          ><b-icon-files /> Adicionar Negócio</b-dropdown-item
        >
        <b-dropdown-item v-b-modal.modal-org
          ><b-icon-building /> Adicionar Organização</b-dropdown-item
        >
        <b-modal
          id="modal-org"
          centered
          title="Nova organização"
          hide-footer
          size="lg"
        >
          <FormToOrganizations :organization="{}" :new-form="true" />
        </b-modal>
        <b-dropdown-item v-b-modal.modal-person
          ><b-icon-person /> Adicionar Pessoa</b-dropdown-item
        >
        <b-modal
          id="modal-person"
          centered
          title="Nova pessoa"
          hide-footer
          size="lg"
        >
          <FormToPeople :person="{}" :new-form="true" />
        </b-modal>
      </b-dropdown>
    </b-col>
  </b-row>
</template>

<script>
import debounce from 'lodash/debounce'
import { filter } from 'vue-input-facade'

import FormToOrganizations from './FormToOrganizations.vue'
import FormToPeople from './FormToPeople.vue'

export default {
  filters: { facade: filter },
  components: { FormToOrganizations, FormToPeople },
  data() {
    return {
      searchOpts: [],

      form: {
        search: null
      }
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    dropPos(dropdownList, component, { width, top, left }) {
      dropdownList.style.top = top
      dropdownList.style.left = 0
      dropdownList.style.width = document.body.clientWidth
    },

    getSearch(search, loading) {
      loading(true)
      if (search.length > 2) {
        this.search(loading, search, this)
      } else {
        loading(false)
      }
    },

    search: debounce((loading, search, vm) => {
      Rails.ajax({
        url: `/api/users/search?query=${encodeURI(search)}`,
        type: 'GET',
        dataType: 'json',
        success: (data) => {
          console.log(data)
          vm.searchOpts = data
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        },
        complete: () => {
          loading(false)
        }
      })
    }, 350),

    setSearchGoto(data) {
      if (!data) return
      if ('stage' in data) {
        this.$router.push({
          name: 'Deal View',
          params: {
            id: data.id
          }
        })
      }

      if ('industry_id' in data) {
        this.$router.push({
          name: 'Org View',
          params: {
            id: data.id
          }
        })
      }

      if ('rg' in data) {
        this.$router.push({
          name: 'Person View',
          params: {
            id: data.id
          }
        })
      }

      console.log('set search : ', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.sticky-to-top {
  position: fixed;
  top: 4px;
  background-color: transparent;
  left: 0;
  right: 0;
  margin: 0px 100px 0px 100px;
}

@media all and (max-width: 767px) {
  .sticky-to-top {
    position: relative !important;
    margin: 0px 0px 10px 0px;
  }

  .mobile-absolute-1 {
    position: fixed;
    top: 0px;
    right: 65px;
  }
  .mobile-absolute-2::v-deep button {
    position: fixed !important;
    top: 4px;
    right: 4px;
  }
  .mobile-absolute-3 {
    position: fixed;
    top: 4px;
    width: 200px;
    left: 60px;
  }
}
</style>