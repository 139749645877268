<template>
  <div class="container container-fluid my-2">
    <div class="position-relative d-flex justify-content-center align-items-center">
      <button class="btn p-0" @click="updateCurrentMonthYear(-1)">
        <b-icon icon="arrow-left-circle"></b-icon>
      </button>
      <input
        v-model="currentMonthYear"
        type="month"
        class="custom-month-input"
        style="
          border: none;
          text-transform: uppercase;
          font-weight: bolder;
          text-align: center;
          font-size: 32px;
        "
        :data-month-number="currentMonth"
      />
      <button class="btn p-0" @click="updateCurrentMonthYear(1)">
        <b-icon icon="arrow-right-circle"></b-icon>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentMonthYear:
        localStorage.getItem('currentMonthYear') ||
        new Date().toISOString().substring(0, 7)
    }
  },
  computed: {
    currentMonth() {
      // eslint-disable-next-line no-unused-vars
      const [year, month] = this.currentMonthYear.split('-').map(Number)
      return month
    }
  },
  watch: {
    currentMonthYear(newValue) {
      localStorage.setItem('currentMonthYear', newValue) // update local storage
      const [year, month] = newValue.split('-').map(Number)
      this.$emit('update-current-month-year', year, month - 1)
    }
  },
  methods: {
    changePeriod(period) {
      this.$emit('change-period', period)
    },
    updateCurrentMonthYear(offset) {
      const [year, month] = this.currentMonthYear.split('-').map(Number)
      const newDate = new Date(year, month - 1 + offset)
      this.currentMonthYear = `${newDate.getFullYear()}-${(
        newDate.getMonth() + 1
      )
        .toString()
        .padStart(2, '0')}`
    }
  }
}
</script>

<style>
.custom-month-input::after {
  content: attr(data-month-number);
  position: relative;
  top: 50%;
  right: 24px;
  bottom: -2px;
  font-weight: bold;
  font-size: 18px;
}
</style>