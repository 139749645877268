<template>
  <div
    ref="containerAll"
    class="container-fluid container-all nice-scroll mt-3"
  >
    <div ref="dragContainer" class="drag-container"></div>
    <div class="wrapper-to-fix-last-col d-inline-flex">
      <div ref="boardContainer" class="board">
        <div
          v-for="(list, listIndex) in lists"
          :id="'list-' + list.id"
          :key="list.id"
          ref="lists"
          class="list board-column mt-3"
          :data-position="listIndex + 1"
        >
        <div v-if="$store.state.user && $store.state.user.admin" class="position-absolute text-muted" :style="{ top: '-20px', right: '10px' }">{{ sumListValue(list) }} - {{ sumListQty(list) }}</div>
          <div
            class="board-column-header"
            :style="{
              backgroundColor: list.hex_color + ' !important',
              color: getContrastYIQ(list.hex_color) + ' !important'
            }"
          >
            {{ list.name }}
          </div>
          <div class="red-x" @click="openModalDeleteList(list)">
            <b-icon-x />
          </div>
          <div class="board-column-content-wrapper">
            <div :id="'list-' + list.id" class="board-column-content">
              <div
                v-for="(card, cardIndex) in list.deals"
                :id="'deal-' + card.id"
                :key="card.id"
                class="board-item"
                :data-position="cardIndex + 1"
  @click="clickToView(card.id, $event)"
              >
                <div class="board-item-content">
                  <span class="no-grip">{{ card.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="addListEl" class="board-column position-relative list no-sort">
        <div
          v-click-outside="checkToBlur"
          class="add-column-list"
          @click="startEditing"
        >
          <a v-if="!editing">Adicionar lista...</a>
          <textarea
            v-if="editing"
            ref="message"
            v-model="message"
            class="textareaBox form-control mb-1"
          ></textarea>
          <button v-if="editing" class="btn btn-secondary" @click="createList">
            Adicionar
          </button>
          <a v-if="editing" @click.stop="editing = false">Cancelar</a>
        </div>
      </div>
    </div>

    <!-- MODAL TO DELETE -->
    <b-modal
      id="modal-to-delete"
      title="Arquivar"
      dialog-class="p-3"
      hide-footer
    >
      <template #modal-title>
        <h5>
          Confirmar delete da lista<br />
          <strong>{{ listToDelete ? listToDelete.name : '' }}</strong> ?
        </h5>
      </template>
      <b-row v-if="remainingCards">
        <b-alert show variant="danger" class="w-100 text-center mb-1"
          >Atenção: Ainda existem cartões na lista.<br />Escolha uma nova lista
          para mover todos os cartões</b-alert
        >
      </b-row>
      <b-row v-if="remainingCards" cols="1" no-gutters>
        <v-select
          v-model="newList"
          :options="remainingLists"
          label="name"
          class="select-to-delete mt-2 mb-2"
          :filterable="true"
          :placeholder="'Procure aqui...'"
          :taggable="false"
          :append-to-body="true"
          :calculate-position="dropPos"
        >
        </v-select>
      </b-row>
      <b-row align-content="around" align-h="around" align-v="center">
        <button type="button" class="btn btn-danger" @click="deleteList">
          Confirmar
        </button>
        <button
          type="button"
          class="btn btn-light btn-sm"
          @click="$bvModal.hide('modal-to-delete')"
        >
          Cancelar
        </button>
      </b-row>
    </b-modal>
    <!-- MODAL TO DELETE -->
  </div>
</template>

<script>
import Muuri from 'muuri'
import filter from 'lodash/filter'
import find from 'lodash/find'

export default {
  data: function () {
    return {
      editing: false,
      message: '',
      boardGrid: undefined,
      grid: undefined,
      columnGrids: [],
      isMouseEnter: false,
      loopDrag: 0,
      animateLeft: 0,
      animateRight: 0,
      actualPosScrollX: null,
      activeDeal: null,
      unwatch: null,
      lists: null,
      lastCardData: {},
      lastListData: {},
      localChange: null,
      remainingCards: null,
      listToDelete: null,
      newList: null
    }
  },
  metaInfo() {
    return {
      title:
        find(this.$store.state.pipes, {
          id: parseInt(this.lists[0].pipe_id)
        })?.name || ''
    }
  },

  computed: {
    itemContainers: function () {
      return [].slice.call(document.querySelectorAll('.board-column-content'))
    },
    remainingLists: function () {
      if (!this.listToDelete) return
      return filter(
        this.$store.state.lists,
        (o) => o.id != this.listToDelete.id
      )
    },
  },

  created() {
    var that = this
    this.installWatcher()
    this.lists = this.$store.state.lists
    this.$nextTick(function () {
      // Init board grid so we can drag those columns around.
      this.boardGrid = new Muuri('.board', {
        dragEnabled: true,
        dragHandle: '.board-column-header',
        dragContainer: that.$refs.containerAll,
        layout: {
          horizontal: true
        },
        sortData: {
          position: function (item, element) {
            return parseFloat(element.getAttribute('data-position'))
          }
        },
        dragAutoScroll: {
          targets: () => {
            return [
              {
                element: that.$refs.containerAll,
                priority: 1,
                axis: Muuri.AutoScroller.AXIS_X
              }
            ]
          },
          smoothStop: false,
          threshold: 250
        }
      })
        .on('layoutEnd', function () {
          if (localStorage.getItem('homeScrollPosX-' + that.$store.state.on_pipe)) {
            if (that.$refs.containerAll)
              that.$refs.containerAll.scrollTo(parseFloat(localStorage.getItem('homeScrollPosX-' + that.$store.state.on_pipe)), 0)
          }
        })

        .on('dragMove', function (item, event) {
          that.onDragMoveScroll(event)
        })
        .on('dragInit', function (item) {
          item.getElement().style.width = item.getWidth() + 'px'
          item.getElement().style.height = item.getHeight() + 'px'
          that.lastListData.grid = item.getGrid()
          that.lastListData.items = that.lastListData.grid.getItems()
          that.lastListData.grid_id = parseInt(
            that.lastListData.grid.getElement().id.split('-')[1]
          )
          that.lastListData.index = that.lastListData.items.indexOf(item)
          that.lastListData.deal_id = parseInt(
            item.getElement().id.split('-')[1]
          )
        })
        .on('dragReleaseEnd', function (item) {
          item.getElement().style.width = ''
          item.getElement().style.height = ''
          item.getGrid().refreshItems([item])

          let grid = item.getGrid()
          let items = grid.getItems()
          let grid_id = parseInt(grid.getElement().id.split('-')[1])
          let index = items.indexOf(item)

          // check if card has changed grid and position
          if (
            that.lastListData.grid_id == grid_id &&
            that.lastListData.index == index
          )
            return

          that.listMoved(item)
        })
        .on('add', function (data) {
          console.log('add: ', data)
        })
        .on('remove', function (data) {
          console.log('remove: ', data)
        })

      that.itemContainers.forEach(function (container) {
        var grid = that.addColumn(container)
        grid.sort('position')
        setTimeout(() => {
          grid.synchronize().refreshItems().layout()
        }, 500)

        that.columnGrids.push(grid)
      })
    })

    document.addEventListener('mousedown', this.mouseDownHandler)
  },

  beforeDestroy() {
    document.removeEventListener('mousedown', this.mouseDownHandler)
  },

  mounted() { },

  methods: {
    sumListValue(list) {
      let sum = list.deals.reduce((a, b) => a + parseFloat(b.value), 0)
      return sum.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    },
    sumListQty(list) {
      return list.deals.length
    },
    addColumn(container) {
      let that = this
      return new Muuri(container, {
        items: '.board-item',
        dragEnabled: true,
        dragSort: function () {
          return that.columnGrids
        },
        dragStartPredicate: {
          distance: 10,
          delay: 40
        },
        sortData: {
          position: function (item, element) {
            return parseFloat(element.getAttribute('data-position'))
          }
        },
        dragContainer: that.$refs.containerAll,
        dragAutoScroll: {
          targets: (item) => {
            return [
              {
                element: that.$refs.containerAll,
                priority: 1,
                axis: Muuri.AutoScroller.AXIS_X
              },
              // {
              //   element: window,
              //   priority: 0,
              //   axis: Muuri.AutoScroller.AXIS_X
              // },
              {
                element: item.getGrid().getElement().parentNode,
                priority: 2,
                axis: Muuri.AutoScroller.AXIS_Y
              }
            ]
          },
          smoothStop: false,
          threshold: 250
        },
        dragPlaceholder: {
          enabled: true
        },
        itemPlaceholderClass: 'custom-item-placeholder'
      })
        .on('dragInit', function (item) {
          item.getElement().style.width = item.getWidth() + 'px'
          item.getElement().style.height = item.getHeight() + 'px'
          that.lastCardData.grid = item.getGrid()
          that.lastCardData.items = that.lastCardData.grid.getItems()
          that.lastCardData.grid_id = parseInt(
            that.lastCardData.grid.getElement().id.split('-')[1]
          )
          that.lastCardData.index = that.lastCardData.items.indexOf(item)
          that.lastCardData.deal_id = parseInt(
            item.getElement().id.split('-')[1]
          )
        })
        .on('dragReleaseEnd', function (item) {
          item.getElement().style.width = ''
          item.getElement().style.height = ''
          item.getGrid().refreshItems([item])

          let grid = item.getGrid()
          let items = grid.getItems()
          let grid_id = parseInt(grid.getElement().id.split('-')[1])
          let index = items.indexOf(item)

          // check if card has changed grid and position
          if (
            that.lastCardData.grid_id == grid_id &&
            that.lastCardData.index == index
          )
            return

          that.cardMoved(item)
        })
        .on('add', function (data) {
          console.log('add: ', data)
        })
        .on('remove', function (data) {
          console.log('remove: ', data)
        })
        .on('dragMove', function (item, event) {
          that.onDragMoveScroll(event)
        })
    },
    checkMouseEnter: function () {
      //console.log('mouse entereddddddddddddd')
      this.isMouseEnter = true
    },

    startEditing: function () {
      this.editing = true
      this.$nextTick(() => {
        this.$refs.message.focus()
      })
    },

    checkToBlur: function () {
      this.editing = false

      // if (!evt.target.closest('.list')) {
      //   this.$refs.lists.forEach((i) => i.checkToBlur())
      // }
      // if (!evt.target.closest('#addListEl')) {
      //   this.editing = false
      // }
    },

    createList: function () {
      var data = new FormData()
      data.append('deal_stage[name]', this.message)
      data.append('deal_stage[active]', true)

      Rails.ajax({
        beforeSend: () => true,
        url: '/api/deal_stages',
        type: 'POST',
        data: data,
        dataType: 'json',
        success: () => {
          this.message = ''
          this.editing = false
        }
      })
    },

    installWatcher() {
      this.unwatch = this.$watch(
        '$store.state.lists',
        function (newVal) {
          let vm = this
          this.lists = newVal
          console.log('STORE LISTS CHANGED')
          this.$nextTick(function () {
            vm.syncMuuriAndVue(vm.boardGrid, vm)

            vm.columnGrids.forEach(function (grid) {
              vm.syncMuuriAndVue(grid, vm)
            })
          })
        },
        { deep: true }
      )
    },

    syncMuuriAndVue(grid, vm) {
      // Muuri e Vue não sincronizam - fix complexo abaixo
      // a ideia é sincronizar classes do muuri
      // apenas um item por vez no momento - todo futuro mass add/remove

      // search for cards
      let noMuuriItemClass = [...grid.getElement().childNodes].filter(
        (el) => !el.classList.contains('muuri-item')
      )

      // search for lists
      let noMuuriClass = [...grid.getElement().childNodes].filter(
        (e) =>
          !e.querySelector('.muuri') && e.classList.contains('board-column')
      )

      if (noMuuriItemClass.length > 0) {
        let toRemove = grid.getItems(
          noMuuriItemClass.map(
            (e) => parseInt(e.getAttribute('data-position')) - 1
          )
        )
        grid.remove(grid.getItems(toRemove), {
          removeElements: false,
          layout: false
        })
        grid.add(noMuuriItemClass, {
          layout: false
        })
      }

      if (noMuuriClass.length > 0) {
        let toRemove = grid.getItems(
          noMuuriClass.map((e) => parseInt(e.getAttribute('data-position')) - 1)
        )

        grid.remove(grid.getItems(toRemove), {
          removeElements: false,
          layout: false
        })
        let added = grid.add(noMuuriClass, {
          layout: false
        })
        let element = added[0]
          .getElement()
          .querySelector('.board-column-content')
        let newGrid = this.addColumn(element)
        vm.columnGrids.push(newGrid)
      }

      // when is not local change (backend/cable) need more sync
      if (!vm.localChange) {
        let muuriItems = grid.getItems().map((el) => el.getElement().id)
        let domItems = [...grid.getElement().childNodes].map((e) => e.id)
        let remover = muuriItems.filter((el) => !domItems.includes(el))
        let adicionar = domItems.filter((el) => !muuriItems.includes(el))

        if (remover.length > 0) {
          let indexToRemove = grid
            .getItems()
            .findIndex((e) => e.getElement().id == remover[0])
          grid.remove(grid.getItems(indexToRemove), {
            removeElements: false,
            layout: false
          })
        }

        if (adicionar.length > 0) {
          let indexToAdicionar = document.getElementById(adicionar[0])
          grid.add(indexToAdicionar, {
            layout: false
          })
        }
      }

      grid.sort((a, b) => {
        return (
          a.getElement().getAttribute('data-position') -
          b.getElement().getAttribute('data-position')
        )
      })

      // order is now updated at vuex.store commits, if not, should be here
      // grid.getItems().forEach(function (item) {
      //   item
      //     .getElement()
      //     .setAttribute('data-position', grid.getItems().indexOf(item) + 1)
      // })

      // only refresh layout after everything is added and deleted to maintain order
      grid.synchronize().refreshItems().layout()
    },

    cardMoved: function (item) {
      this.localChange = true

      const grid = item.getGrid()
      const items = grid.getItems()
      const grid_id = parseInt(grid.getElement().id.split('-')[1])
      const index = items.indexOf(item)
      const deal_id = parseInt(item.getElement().id.split('-')[1])

      var data = new FormData()
      data.append('deal[deal_stage_id]', grid_id)
      data.append('deal[position]', index + 1)

      Rails.ajax({
        beforeSend: () => true,
        url: `/api/deals/${deal_id}/move`,
        type: 'PATCH',
        data: data,
        dataType: 'json',
        complete: () => (this.localChange = false) //this.installWatcher()
      })
    },

    listMoved: function (item) {
      console.log('listmoved: ', item)
      this.localChange = true

      const grid = item.getGrid()
      const items = grid.getItems()
      const index = items.indexOf(item)
      const list_id = parseInt(item.getElement().id.split('-')[1])

      var data = new FormData()
      data.append('deal_stage[position]', index + 1)

      console.log('deal stage data:', index + 1)

      Rails.ajax({
        beforeSend: () => true,
        url: `/api/deal_stages/${list_id}/move`,
        type: 'PATCH',
        data: data,
        dataType: 'json',
        complete: () => (this.localChange = false) //this.installWatcher()
      })
    },

    openModalDeleteList: function (list) {
      this.listToDelete = list
      if (list.deals.length > 0) {
        this.remainingCards = true
      } else {
        this.remainingCards = false
      }
      this.$bvModal.show('modal-to-delete')
    },

    deleteList: function () {
      this.localChange = true
      var data = new FormData()
      data.append('deal_stage[id]', this.listToDelete.id)
      if (this.newList) {
        data.append('deal_stage[new_deal_stage_id]', this.newList.id)
      }
      data.append('deal_stage[active]', false)

      // se select não foi escolhido volta

      Rails.ajax({
        beforeSend: () => true,
        url: `/api/deal_stages/${this.listToDelete.id}/archive`,
        type: 'PATCH',
        data: data,
        dataType: 'json',
        success: (data) => console.log('Sucesso...', data),
        error: (error) => this.$root.toast('Erro', error.base, 'danger'),
        complete: () => {
          this.$bvModal.hide('modal-to-delete')
          this.localChange = false
        }
      })
    },


    clickToView(id, event) {
      if (event.ctrlKey || event.metaKey) { // `metaKey` is for MacOS command key
        const url = this.$router.resolve({
          name: 'Deal View',
          params: {
            id: id
          }
        }).href;
        window.open(url, '_blank');
      } else {
        this.$router.push({
          name: 'Deal View',
          params: {
            id: id
          }
        });
      }
    },

    dropPos(dropdownList, component, { width, top, left }) {
      dropdownList.style.zIndex = 1100
      if (component.options.length > 0) {
        dropdownList.style.top = top
        dropdownList.style.left = 0
        dropdownList.style.width = document.body.clientWidth
      } else {
        dropdownList.style.top = top
        dropdownList.style.left = left
        dropdownList.style.width = width
      }
    },

    mouseDownHandler: function (e) {
      let reg = new RegExp(
        '(^|\\s)board-column-header|board-item|board-item-content|no-grip(\\s|$)'
      )
      const checkClass = reg.test(e.target.className)
      //console.log(e.target.className)
      if (!checkClass) {
        //console.log('mouse DOWN')
        document.body.style.userSelect = 'none'

        this.pos = {
          left: this.$refs.containerAll.scrollLeft,
          top: this.$refs.containerAll.scrollTop,
          // Get the current mouse position
          x: e.clientX,
          y: e.clientY
        }

        document.addEventListener('mousemove', this.mouseMoveHandler)
        document.addEventListener('mouseup', this.mouseUpHandler)
      }
    },
    mouseMoveHandler: function (e) {
      //console.log("mouse MOOOOOVE");
      // How far the mouse has been moved
      const dx = e.clientX - this.pos.x
      const dy = e.clientY - this.pos.y

      // Scroll the element
      this.$refs.containerAll.scrollTop = this.pos.top - dy
      this.$refs.containerAll.scrollLeft = this.pos.left - dx
      // this.scrollTo(
      //   this.pos.left - dx,
      //   100,
      //   easing.easeOutQuad,
      //   this.$refs.stagesWrapper
      // );
    },
    mouseUpHandler: function () {
      //console.log('mouse UP')
      document.body.style.removeProperty('user-select')

      document.removeEventListener('mousemove', this.mouseMoveHandler)
      document.removeEventListener('mouseup', this.mouseUpHandler)

      localStorage.setItem('homeScrollPosX-' + this.$store.state.on_pipe, this.$refs.containerAll.scrollLeft)
    },

    onDragMoveScroll(event) {
      this.actualPosScrollX = event.clientX
      if (this.actualPosScrollX > 0) this.animateLeft = 0
      if (this.actualPosScrollX < this.$refs.containerAll.clientWidth)
        this.animateRight = 0

      if (this.animateLeft == 1) return
      if (this.animateRight == 1) return

      if (
        event.clientX < 0 ||
        event.clientX > this.$refs.containerAll.clientWidth
      ) {
        let leftOrRight = Math.sign(event.clientX)
        //console.log(leftOrRight);
        let maxScrollRight =
          this.$refs.containerAll.scrollWidth -
          this.$refs.containerAll.clientWidth

        this.animateLeft = 1
        this.animateRight = 1

        if (
          event.clientX > 0 &&
          event.clientX < this.$refs.containerAll.clientWidth
        )
          return

        this.stepTick(leftOrRight, maxScrollRight)

        if (
          this.actualPosScrollX < 0 ||
          this.actualPosScrollX > this.$refs.containerAll.clientWidth
        ) {
          window.requestAnimationFrame(() =>
            this.stepTick(leftOrRight, maxScrollRight)
          )
        }
      }
    },

    stepTick(leftOrRight, maxScrollRight) {
      if (leftOrRight > 0) {
        if (this.$refs.containerAll.scrollLeft === maxScrollRight) {
          this.$refs.containerAll.scrollTo(maxScrollRight, 0)
        }
      } else {
        if (this.$refs.containerAll.scrollLeft === 0) {
          this.$refs.containerAll.scrollTo(0, 0)
        }
      }

      if (leftOrRight > 0) {
        if (this.$refs.containerAll.scrollLeft === maxScrollRight) return
        this.$refs.containerAll.scrollTo(
          Math.round(this.$refs.containerAll.scrollLeft + 17),
          0
        )
      } else {
        if (this.$refs.containerAll.scrollLeft === 0) return
        this.$refs.containerAll.scrollTo(
          Math.round(this.$refs.containerAll.scrollLeft - 17),
          0
        )
      }

      if (
        this.actualPosScrollX < 0 ||
        this.actualPosScrollX > this.$refs.containerAll.clientWidth
      ) {
        window.requestAnimationFrame(() =>
          this.stepTick(leftOrRight, maxScrollRight)
        )
      }
    },

    getContrastYIQ(hexcolor) {
      if (!hexcolor) return 'white'
      hexcolor = hexcolor.replace('#', '')
      var r = parseInt(hexcolor.substr(0, 2), 16)
      var g = parseInt(hexcolor.substr(2, 2), 16)
      var b = parseInt(hexcolor.substr(4, 2), 16)
      var yiq = (r * 299 + g * 587 + b * 114) / 1000
      return yiq >= 128 ? 'black' : 'white'
    }
  }
}
</script>

<style scoped>
html::v-deep {
  overflow: hidden;
}

.board-column-content::v-deep .custom-item-placeholder {
  border: 2px dashed gray;
  border-radius: 4px;
}

.drag-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
}

.container-all {
  width: 100%;
  overflow-x: scroll;
}

.board {
  position: relative;
  display: flex;
  height: 80vh;
}

.board-column {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 5px;
  width: 250px;
  /* width: calc(100% / 3); */
  z-index: 1;
}

.board-column.muuri-item-releasing {
  z-index: 2;
}

.board-column.muuri-item-dragging {
  z-index: 3;
  cursor: move;
}

.board-column-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.board-column-header {
  position: relative;
  height: 35px;
  line-height: 35px;
  overflow: hidden;
  padding: 0 10px;
  font-size: 80%;
  text-align: center;
  background: #333;
  color: #fff;
  border-radius: 5px 5px 0 0;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.board-column-content-wrapper {
  position: relative;
  padding: 4px;
  background: #f0f0f0;
  height: calc(80vh - 90px);
  overflow-y: auto;
  border-radius: 0 0 5px 5px;
}

.board-column-content {
  position: relative;
  min-height: 100%;
}

.board-item {
  position: absolute;
  width: calc(100% - 16px);
  margin: 8px;
}

.board-item.muuri-item-releasing {
  z-index: 9998;
}

.board-item.muuri-item-dragging {
  z-index: 9999;
  cursor: move;
}

.board-item.muuri-item-hidden {
  z-index: 0;
}

.board-item-content {
  position: relative;
  text-align: center;
  padding: 10px;
  background: #fff;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  -webkit-box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .board-column {
    width: 150px;
    font-size: 50%;
  }

  .board-item-content {
    text-align: center;
  }
}

.nice-scroll::-webkit-scrollbar {
  height: 30px;
}

.nice-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.nice-scroll::-webkit-scrollbar-thumb {
  border-radius: 30px;
  border: 8px solid transparent;
  background-clip: content-box;
  background-color: #d6dee1;
}

.nice-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid transparent;
  background-clip: content-box;
  background-color: #d6dee1;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.add-column-list {
  opacity: 1;
  transform: scale(1);
  border: 2px dashed lightgray;
  color: darkgray;
  font-weight: 500;
  padding: 4px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.red-x {
  position: absolute;
  background-color: transparent;
  color: orangered;
  font-size: 12px;
  right: 0;
  z-index: 1;
  top: 0;
  padding: 0px 5px;
  margin-right: 4px;
  cursor: pointer;
}
</style>
