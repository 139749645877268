<template>
  <div>
    <b-overlay
      :show="loading"
      rounded="sm"
      :no-wrap="noWrap"
      :spinner-small="small"
    >
      <slot></slot>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  components: {},
  props: {
    loading: Boolean,
    noWrap: Boolean,
    small: Boolean
  },

  data() {
    return {}
  },
  computed: {}
}
</script>

<style scoped></style>
