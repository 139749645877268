<template>
  <b-container fluid class="bg-light px-4">
    <b-row align-content="end" align-h="between" align-v="center" class="small">
      <b-button variant="link" @click="$router.back()"
        ><b-icon-arrow-left />Voltar</b-button
      >

      <div v-if="saved">
        Alterações salvas com sucesso!<b-icon-check
          class="ml-2"
          scale="2"
          variant="success"
        />
      </div>
      <div v-else class="d-flex align-items-center">
        <div class="mr-4">Salvando...</div>
        <Loading :loading="true" :small="true"></Loading>
      </div>
    </b-row>
    <form-wrapper :validator="$v.form" scope="deal">
      <div class="bg-white px-3 big-radius border-bottom shadow-1">
        <!-- TITULO -->
        <b-form-row>
          <b-col class="p-0">
            <form-group name="title" class="form-group">
              <b-form-input
                ref="title"
                slot-scope="{ attrs, listeners }"
                placeholder="Título do novo negócio"
                :value="form.title"
                v-bind="attrs"
                :class="{
                  'form-control edit-title': true,
                  'no-valid-vuelidate': () => avoidValidClass()
                }"
                name="title"
                type="text"
                autocomplete="off"
                @change="form.title = $event"
                v-on="listeners"
              ></b-form-input>
            </form-group>
          </b-col>
        </b-form-row>
        <!-- TITULO -->
        <!-- FUNIL -->
        <b-form-row class="mb-2">
          <div class="w-100 nice-scroll">
            <form-group name="pipe" class="form-group align-items-center">
              <v-select
                v-model="form.pipe"
                slot-scope="{ attrs, listeners }"
                :options="pipes"
                class="w-100"
                label="name"
                v-bind="attrs"
                v-on="listeners"
              ></v-select>
            </form-group>
          </div>
        </b-form-row>
        <!-- FUNIL -->
      </div>

      <hr />
      <!-- <b-row align-v="center">
        <b-button
          class="col-auto p-0 m-0 px-3"
          size="sm"
          variant="link"
          @click="returnNextNumber()"
          >Retornar próximos números (google): </b-button
        ><Loading
          style="width: 25px; align-self: center; color: dodgerblue"
          :small="true"
          :loading="nextNumberLoading"
        />
        <b-link
          class="col font-weight-bold small mx-3"
          @click="form.title = nextNumber[0] + ' - ' + form.title"
          >{{ nextNumber[0] }}</b-link
        >
        <b-link
          class="col font-weight-bold small mx-3"
          @click="form.title = nextNumber[1] + ' - ' + form.title"
          >{{ nextNumber[1] }}</b-link
        >
      </b-row> -->

      <b-row align-v="center">
        <b-button
          class="col-auto p-0 m-0 px-3"
          size="sm"
          variant="link"
          @click="returnNextNumberServer()"
          >Retornar próximos números (servidor): </b-button
        ><Loading
          style="width: 25px; align-self: center; color: dodgerblue"
          :small="true"
          :loading="nextNumberServerLoading"
        />
        <b-link
          class="col font-weight-bold small mx-3"
          @click="form.title = nextNumberServer[0] + ' - ' + form.title"
          >{{ nextNumberServer[0] }}</b-link
        >
        <b-link
          class="col font-weight-bold small mx-3"
          @click="form.title = nextNumberServer[1] + ' - ' + form.title"
          >{{ nextNumberServer[1] }}</b-link
        >
      </b-row>

      <b-row align-v="center">
        <form-group
          name="create_folder"
          class="form-group small mb-0 px-3 form-group-inline"
          label="Criar pasta automático ?"
        >
          <b-form-checkbox
            v-model="form.create_folder"
            slot-scope="{ attrs, listeners }"
            inline
            value="Sim"
            unchecked-value="Não"
            v-bind="attrs"
            class="w-100 h-100 input-pointer"
            name="create_folder"
            size="sm"
            v-on="listeners"
          >
            <span
              :style="{
                cursor: 'pointer',
                color: form.create_folder == 'Não' ? '#dc3545' : undefined
              }"
              >{{ form.create_folder }}</span
            >
          </b-form-checkbox>
        </form-group>
      </b-row>

      <!-- SIDEBAR -->
      <b-row align-content="center" align-h="center" align-v="center">
        <b-col cols="12" md="4">
          <!-- DETALHES -->
          <div class="p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius">
            <span class="font-weight-bold"
              ><b-icon-pen class="mr-1" /> Detalhes:</span
            >
            <hr class="my-1" />
            <form-group
              name="value"
              label="Valor"
              class="form-group align-items-center"
              :prepend="'R$'"
            >
              <CurrencyInput
                slot-scope="{ attrs, listeners }"
                :value="form.value"
                class="form-control"
                :currency="null"
                :distraction-free="false"
                :auto-decimal-mode="true"
                locale="pt-BR"
                v-bind="attrs"
                @change="form.value = $event"
                v-on="listeners"
              ></CurrencyInput>
            </form-group>
            <form-group
              name="status"
              label="Status"
              class="form-group align-items-center"
            >
              <v-select
                v-model="form.status"
                slot-scope="{ attrs, listeners }"
                :options="statuses"
                class="w-100"
                label="name"
                v-bind="attrs"
                v-on="listeners"
              ></v-select>
            </form-group>
            <form-group
              name="owner"
              label="Proprietário"
              class="form-group align-items-center"
            >
              <v-select
                v-model="form.owner"
                slot-scope="{ attrs, listeners }"
                :options="owners"
                class="w-100"
                label="email"
                v-bind="attrs"
                v-on="listeners"
              ></v-select>
            </form-group>
          </div>
          <!-- DETALHES -->

          <!-- ORGANIZACAO -->
          <div class="p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius">
            <form-group name="organization" class="form-group font-weight-bold">
              <template #label>
                <div class="align-items-center d-flex">
                  <b-icon-building class="mr-1" />
                  Organização
                </div>
              </template>
              <v-select
                v-model="form.organization"
                slot-scope="{ attrs, listeners }"
                class="w-100 h-auto"
                label="name"
                v-bind="attrs"
                :filterable="false"
                :options="orgOptions"
                :placeholder="'Procure aqui...'"
                :taggable="false"
                :append-to-body="true"
                :calculate-position="dropPos"
                v-on="listeners"
                @search="getOrganizations"
                @input="setOrganization"
              >
                <template slot="no-options"> Digite para procurar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-flex flex-row align-items-center">
                    <div>
                      <b-icon-building font-scale="1.5" class="mr-2" />
                    </div>
                    <div class="flex-column">
                      <div class="font-weight-bold">{{ option.name }}</div>
                      <div>
                        {{ option.email }}
                        {{
                          option.mobile
                            | facade([
                              '####-####',
                              '#####-####',
                              '(##) ####-####',
                              '(##) #####-####'
                            ])
                        }}
                        {{
                          option.telephone
                            | facade([
                              '####-####',
                              '#####-####',
                              '(##) ####-####',
                              '(##) #####-####'
                            ])
                        }}
                      </div>
                    </div>
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }}
                  </div>
                </template>
              </v-select>
            </form-group>
            <div v-if="form.organization" class="small">
              <hr />
              <b-link
                :to="{
                  name: 'Org View',
                  params: { id: form.organization.id }
                }"
                ><b-icon-building
                  font-scale="2"
                  class="w-100"
                  variant="primary"
              /></b-link>
              <FormToOrganizations :organization="form.organization" />
            </div>
          </div>
          <!-- ORGANIZACAO -->

          <!-- CONTATO -->
          <div class="p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius">
            <form-group name="contact" class="form-group font-weight-bold">
              <template #label>
                <div class="align-items-center d-flex">
                  <b-icon-person-fill />
                  Contato
                </div>
              </template>
              <v-select
                v-model="form.contact"
                slot-scope="{ attrs, listeners }"
                class="w-100 h-auto"
                label="name"
                v-bind="attrs"
                :filterable="false"
                :options="peopleOptions"
                :placeholder="'Procure aqui...'"
                :taggable="false"
                :append-to-body="true"
                :calculate-position="dropPos"
                v-on="listeners"
                @search="getPeople"
              >
                <template slot="no-options"> Digite para procurar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-flex flex-row align-items-center">
                    <div>
                      <b-icon-person-circle font-scale="1.5" class="mr-2" />
                    </div>
                    <div class="flex-column">
                      <div class="font-weight-bold">{{ option.name }}</div>
                      <div>
                        {{ option.email }}
                        {{
                          option.mobile
                            | facade([
                              '####-####',
                              '#####-####',
                              '(##) ####-####',
                              '(##) #####-####'
                            ])
                        }}
                        {{
                          option.telephone
                            | facade([
                              '####-####',
                              '#####-####',
                              '(##) ####-####',
                              '(##) #####-####'
                            ])
                        }}
                      </div>
                    </div>
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }}
                  </div>
                </template>
              </v-select>
            </form-group>
            <div v-if="form.contact" class="small">
              <hr />
              <b-link
                :to="{
                  name: 'Person View',
                  params: { id: form.contact.id }
                }"
                ><b-icon-person-fill
                  font-scale="2"
                  class="w-100"
                  variant="primary"
              /></b-link>
              <FormToPeople :person="form.contact" />
            </div>
            <div v-else-if="form.organization">
              <div v-if="seePeople">
                <ul>
                  <li v-for="p in seePeople" :key="p.id" class="triggerSelect">
                    <b-link @click="selectPerson(p)">{{ p.name }}</b-link>
                  </li>
                </ul>
                <div v-if="seePeople.length >= 9">
                  <b-link v-if="!seeMorePeople" @click="seeMorePeople = true"
                    >Ver mais...</b-link
                  >
                  <b-link v-else @click="seeMorePeople = false"
                    >Reduzir...</b-link
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- CONTATO -->

          <!-- CLIENTE -->
          <div class="p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius">
            <form-group name="customer" class="form-group font-weight-bold">
              <template #label>
                <div class="align-items-center d-flex">
                  <b-icon-person />
                  Cliente
                </div>
              </template>
              <v-select
                v-model="form.customer"
                slot-scope="{ attrs, listeners }"
                class="w-100 h-auto"
                label="name"
                v-bind="attrs"
                :filterable="false"
                :options="peopleOptions"
                :placeholder="'Procure aqui...'"
                :taggable="false"
                :append-to-body="true"
                :calculate-position="dropPos"
                v-on="listeners"
                @search="getPeople"
              >
                <template slot="no-options"> Digite para procurar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-flex flex-row align-items-center">
                    <div>
                      <b-icon-person-circle font-scale="1.5" class="mr-2" />
                    </div>
                    <div class="flex-column">
                      <div class="font-weight-bold">{{ option.name }}</div>
                      <div>
                        {{ option.email }}
                        {{
                          option.mobile
                            | facade([
                              '####-####',
                              '#####-####',
                              '(##) ####-####',
                              '(##) #####-####'
                            ])
                        }}
                        {{
                          option.telephone
                            | facade([
                              '####-####',
                              '#####-####',
                              '(##) ####-####',
                              '(##) #####-####'
                            ])
                        }}
                      </div>
                    </div>
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }}
                  </div>
                </template>
              </v-select>
            </form-group>
            <div v-if="form.customer" class="small">
              <hr />
              <b-link
                :to="{
                  name: 'Person View',
                  params: { id: form.customer.id }
                }"
                ><b-icon-person-fill
                  font-scale="2"
                  class="w-100"
                  variant="primary"
              /></b-link>
              <FormToPeople :person="form.customer" />
            </div>
          </div>
          <!-- CLIENTE -->
          <b-row align-h="end">
            <b-button block class="m-5" variant="success" @click="submit()"
              >Salvar</b-button
            >
          </b-row>
        </b-col>
      </b-row>
      <!-- SIDEBAR -->

      <b-row class="small">
        Criado em: {{ createdAt }} <br />
        Criado por: {{ creatorId }} <br /><br />
        Atualizado em: {{ updatedAt }} <br />
        Atualizado por: {{ lastUpdateById }}
      </b-row>
    </form-wrapper>
    <go-top
      :size="35"
      :right="15"
      :bottom="25"
      fg-color="#000000"
      bg-color="#eaeaea"
      weight="bold"
    ></go-top>
  </b-container>
</template>

<script>
import { CurrencyInput } from 'vue-currency-input' // setValue
import Loading from './Loading.vue'

import { required } from 'vuelidate/lib/validators'
import find from 'lodash/find'
import debounce from 'lodash/debounce'

import FormToOrganizations from './FormToOrganizations.vue'
import FormToPeople from './FormToPeople.vue'

import { filter } from 'vue-input-facade'

import GoTop from '@inotom/vue-go-top'

export default {
  metaInfo: {
    title: 'Novo negócio'
  },
  filters: { facade: filter },
  components: {
    CurrencyInput,
    Loading,
    FormToOrganizations,
    FormToPeople,
    GoTop
  },
  data() {
    return {
      saved: true,
      unwatchers: [],
      orgOptions: [],
      peopleOptions: [],
      statuses: null,
      pipes: null,
      createdAt: null,
      updatedAt: null,
      creatorId: null,
      lastUpdateById: null,
      form: {
        title: null,
        owner: null,
        value: null,
        status: null,
        pipe: null,
        organization: null,
        customer: null,
        contact: null,
        create_folder: 'Sim'
      },
      seeMorePeople: false,
      nextNumber: ['', ''],
      nextNumberLoading: false,
      nextNumberServer: ['', ''],
      nextNumberServerLoading: false
    }
  },

  validations: {
    form: {
      title: {
        required,
        existent: function (value) {
          if (value.length < 4) return true
          //let temp = true

          return new Promise((resolve, reject) => {
            Rails.ajax({
              url: `/api/title/search?deal[title]=${encodeURI(value)}`,
              type: 'GET',
              dataType: 'json',
              success: (data) => {
                console.log(data)
                resolve(!data)
              },
              error: (data) => {
                console.log(data)
                reject()
              }
            })
          })
        }
      },
      owner: {},
      value: {},
      status: { required },
      pipe: { required },
      organization: {},
      customer: {},
      contact: {},
      create_folder: {}
    }
  },

  computed: {
    entity: function () {
      let f = {}
      f.deal = {
        title: this.form.title,
        value: this.form.value,
        deal_status_id: this.form.status.id,
        deal_stage_id: this.form.pipe.deal_stages[0].id,
        owner_id: this.form.owner.email,
        organization_id: this.form.organization
          ? this.form.organization.id
          : '',
        customer_person_id: this.form.customer ? this.form.customer.id : '',
        contact_person_id: this.form.contact ? this.form.contact.id : '',
        create_folder: this.form.create_folder
      }
      return f
    },
    seePeople: function () {
      if (!this.form.organization.people) return
      if (this.seeMorePeople) {
        return this.form.organization.people
      }
      return this.form.organization.people.slice(0, 10)
    },
    owners: function () {
      return this.$store.state.usersEmailsList
    }
  },

  created() {
    // RESET DATA
    this.statuses = this.$store.getters.statuses
    this.pipes = this.$store.state.pipes

    this.form.title = ''
    this.form.value = 0.0
    this.form.status = this.statuses[0]
    this.form.pipe = this.pipes[0]

    if (this.$store.state.user) {
      this.form.owner = find(this.owners, {
        email: this.$store.state.user.email
      })
    } else {
      Rails.ajax({
        url: '/api/users',
        type: 'GET',
        dataType: 'json',
        success: (data) => {
          console.log(data)
          this.$store.state.user = data
          this.form.owner = find(this.owners, {
            email: this.$store.state.user.email
          })
        },
        error: () => {
          console.log('App mounted without user logged.')
        }
      })
    }
  },

  methods: {
    dropPos(dropdownList, component, { width, top, left }) {
      if (component.options.length > 0) {
        dropdownList.style.top = top
        dropdownList.style.left = 0
        dropdownList.style.width = document.body.clientWidth
      } else {
        dropdownList.style.top = top
        dropdownList.style.left = left
        dropdownList.style.width = width
      }
    },

    submit() {
      console.log('submited!')

      this.$v.$touch()
      if (this.$v.$invalid) return

      this.saved = false

      var fd = new FormData()

      fd.append('deal[title]', this.entity.deal.title)
      fd.append('deal[value]', this.entity.deal.value)
      fd.append('deal[deal_status_id]', this.entity.deal.deal_status_id)
      fd.append('deal[deal_stage_id]', this.entity.deal.deal_stage_id)
      fd.append('deal[owner_id]', this.entity.deal.owner_id)
      fd.append('deal[organization_id]', this.entity.deal.organization_id)
      fd.append('deal[customer_person_id]', this.entity.deal.customer_person_id)
      fd.append('deal[contact_person_id]', this.entity.deal.contact_person_id)
      fd.append('deal[active]', true)
      fd.append('create_folder', this.entity.deal.create_folder)

      console.log(fd)

      Rails.ajax({
        url: `/api/deals`,
        type: 'POST',
        data: fd,
        dataType: 'json',
        success: (data) => {
          console.log('retorno: ', data)
          if (data.id) {
            this.$router.push({ name: 'Deal View', params: { id: data.id } })
          }
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    getOrganizations(search, loading) {
      loading(true)
      if (search.length > 2) {
        this.searchOrgs(loading, search, this)
      } else {
        loading(false)
      }
    },

    searchOrgs: debounce((loading, search, vm) => {
      Rails.ajax({
        url: `/api/organizations.json?name=${encodeURI(search)}`,
        type: 'GET',
        //data: fd,
        dataType: 'json',
        success: (data) => {
          console.log(data)
          vm.orgOptions = data
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        },
        complete: () => {
          loading(false)
        }
      })
    }, 350),

    getPeople(search, loading) {
      loading(true)
      if (search.length > 2) {
        this.searchPeople(loading, search, this)
      } else {
        loading(false)
      }
    },

    searchPeople: debounce((loading, search, vm) => {
      Rails.ajax({
        url: `/api/people.json?name=${encodeURI(search)}`,
        type: 'GET',
        //data: fd,
        dataType: 'json',
        success: (data) => {
          console.log(data)
          vm.peopleOptions = data
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        },
        complete: () => {
          loading(false)
        }
      })
    }, 350),

    setOrganization(obj) {
      this.saved = false
      let vm = this
      console.log('set : ', obj)
      Rails.ajax({
        url: `/api/organizations/${obj.id}?include=people`,
        type: 'GET',
        dataType: 'json',
        success: (data) => {
          console.log(data)
          vm.form.organization = data
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    setCustomer(obj) {
      console.log('set cust: ', obj)
      this.form.customer = obj
    },

    setContact(obj) {
      console.log('set cont : ', obj)
      this.form.contact = obj
    },

    selectPerson(person) {
      this.form.contact = person
    },

    avoidValidClass() {
      if (this.$refs.title === undefined) return false
      if (this.$refs.title.$el.classList.contains('is-valid')) {
        return true
      } else {
        return false
      }
    },

    returnNextNumber() {
      this.nextNumberLoading = true
      Rails.ajax({
        url: `/api/title/next_number`,
        type: 'GET',
        dataType: 'json',
        success: (data) => {
          this.nextNumber = data
          this.nextNumberLoading = false
        },
        error: (data) => {
          console.log(data)
        }
      })
    },

    returnNextNumberServer() {
      this.nextNumberSererLoading = true
      Rails.ajax({
        url: `/api/title/next_number_server`,
        type: 'GET',
        dataType: 'json',
        success: (data) => {
          this.nextNumberServer = data
        },
        error: (data) => {
          console.log(data)
        },
        complete: () => {
          this.nextNumberServerLoading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.no-valid-vuelidate {
  border-color: #6c757d !important;
  background-image: none !important;
}

.edit-title {
  height: auto;
  color: #6c757d;
  font-weight: bold;
  width: 100%s;
  font-weight: bold;
  border: none;
  border-bottom: solid 2px transparent !important;
  border-radius: 0;
  font-size: xx-large;
  padding: 0;
  margin: 0 0 16px 0;
  &:focus {
    color: black;
    border: 0;
    border-bottom: solid 2px black !important;
    box-shadow: none;
  }
}

.custom-inline::v-deep {
  & legend {
    margin-right: 5px;
    float: left !important;
    order: 0;
    padding: 0;
    flex-basis: 0;
  }
  & > div {
    width: 100%;
  }
}

.stage-progress {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid transparent;
  background-color: transparent;
}

.arrow-right::before {
  content: '';
  position: relative;
  display: block;
  width: 30px;
  height: 30px;
  border-right: 3px solid white;
  border-top: 3px solid white;
  transform: rotate(45deg) translate(-36px, 14px);
}

.progress {
  border-radius: 0rem;
  min-width: 1000px;
}

.progress-bar:last-child .arrow-right {
  border-top: 20px solid white;
  border-bottom: 20px solid white;
}

.active-stage .arrow-right {
  border-top: 20px solid #6c757d;
  border-bottom: 20px solid #6c757d;
}

.cursor-pointer {
  cursor: pointer;
}

.big-radius {
  border-radius: 12px;
}

.nice-scroll::-webkit-scrollbar {
  height: 20px;
}

.nice-scroll::-webkit-scrollbar-track {
  //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
  background-color: transparent;
}

.nice-scroll::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
  background-color: #d6dee1;
}

.nice-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

input {
  background-color: white;
}

.v-select {
  background-color: white;
}

.form-group-inline::v-deep {
  display: flex;

  & legend {
    float: left;
  }

  & div[role='group'] {
    width: auto;
    margin-left: 15px;
  }
}
</style>