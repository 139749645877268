<!--
TODO
Address from Google
-->

<template>
  <b-container
    :fluid="!newForm"
    :class="{
      'position-relative mt-4': true,
      row: newForm ? newForm : false
    }"
  >
    <div
      v-if="!newForm"
      class="position-absolute"
      style="right: 15px; top: -20px"
    >
      <div v-if="saved">
        Salvo! <b-icon-check class="ml-2" scale="2" variant="success" />
      </div>
      <div v-else>
        <Loading class="mt-2" :loading="true" :small="true"></Loading>
      </div>
    </div>
    <form-wrapper
      :validator="$v.form"
      scope="organizations"
      :class="{ 'col-12 col-md-7 mb-4': newForm ? newForm : false }"
    >
      <b-alert
        :show="!form.active && !newForm"
        variant="dark"
        class="text-black-50 font-weight-bold mx-n4"
      >
        Organização Deletada
        <b-button
          class="float-right m-0 p-0 w-25"
          variant="outline-secondary"
          @click="
            $v.$touch()
            emitLocalChange()
            form.active = true
          "
          >Re-ativar</b-button
        >
      </b-alert>
      <!-- NAME -->
      <b-form-row class="mb-2">
        <b-col>
          <form-group label="Nome" name="name" class="form-group new-legend">
            <b-form-input
              slot-scope="{ attrs, listeners }"
              v-bind="attrs"
              :value="form.name"
              class="form-control"
              name="name"
              type="text"
              autocomplete="off"
              v-on="listeners"
              @change="
                emitLocalChange()
                form.name = $event
                searchSimilar($event)
              "
            ></b-form-input>
          </form-group>
        </b-col>
      </b-form-row>
      <!-- NAME -->

      <!-- TEL MOB -->
      <b-form-row class="mb-2">
        <b-col>
          <form-group
            label="Telefone"
            name="telephone"
            class="form-group new-legend"
          >
            <input-facade
              v-model="form.telephone"
              slot-scope="{ attrs, listeners }"
              :mask="[
                '####-####',
                '#####-####',
                '(##) ####-####',
                '(##) #####-####'
              ]"
              :masked="true"
              v-bind="attrs"
              class="form-control"
              name="telephone"
              :lazy="true"
              type="text"
              autocomplete="off"
              v-on="listeners"
              @change="
                emitLocalChange()
                form.telephone = $event
                searchSimilar($event)
              "
            ></input-facade>
          </form-group>
        </b-col>
        <b-col>
          <form-group
            label="Celular"
            name="mobile"
            class="form-group new-legend"
          >
            <input-facade
              v-model="form.mobile"
              slot-scope="{ attrs, listeners }"
              :mask="[
                '####-####',
                '#####-####',
                '(##) ####-####',
                '(##) #####-####'
              ]"
              :masked="true"
              v-bind="attrs"
              class="form-control"
              name="mobile"
              :lazy="true"
              type="text"
              autocomplete="off"
              v-on="listeners"
              @change="
                emitLocalChange()
                form.mobile = $event
                searchSimilar($event)
              "
            ></input-facade>
          </form-group>
        </b-col>
      </b-form-row>
      <!-- TEL MOB -->

      <!-- E-MAIL -->
      <b-form-row class="mb-2">
        <b-col>
          <form-group label="E-mail" name="email" class="form-group new-legend">
            <b-form-input
              slot-scope="{ attrs, listeners }"
              v-bind="attrs"
              :value="form.email"
              class="form-control"
              name="email"
              type="text"
              autocomplete="off"
              v-on="listeners"
              @change="
                emitLocalChange()
                form.email = $event
                searchSimilar($event)
              "
            ></b-form-input>
          </form-group>
        </b-col>
      </b-form-row>
      <!-- E-MAIL -->

      <!-- bank_details -->
      <b-form-row class="mb-2">
        <b-col>
          <form-group
            label="Dados bancários"
            name="bank_details"
            class="form-group new-legend"
          >
            <b-form-input
              slot-scope="{ attrs, listeners }"
              v-bind="attrs"
              :value="form.bank_details"
              class="form-control"
              name="bank_details"
              type="text"
              autocomplete="off"
              v-on="listeners"
              @change="
                emitLocalChange()
                form.bank_details = $event
              "
            ></b-form-input>
          </form-group>
        </b-col>
      </b-form-row>
      <!-- bank_details -->

      <!-- cpf_cnpj -->
      <b-form-row class="mb-2">
        <b-col>
          <form-group
            label="CPF ou CNPJ"
            name="cpf_cnpj"
            class="form-group new-legend"
          >
            <input-facade
              v-model="form.cpf_cnpj"
              slot-scope="{ attrs, listeners }"
              :mask="['###.###.###-##', '##.###.###/####-##']"
              :masked="true"
              v-bind="attrs"
              class="form-control"
              name="cpf_cnpj"
              :lazy="true"
              type="text"
              autocomplete="off"
              v-on="listeners"
              @change="
                emitLocalChange()
                form.cpf_cnpj = $event
              "
            ></input-facade>
          </form-group>
        </b-col>
      </b-form-row>
      <!-- cpf_cnpj -->

      <!-- address -->
      <b-form-row class="mb-2">
        <b-col>
          <form-group
            label="Endereço"
            name="address"
            class="form-group new-legend"
          >
            <b-form-input
              slot-scope="{ attrs, listeners }"
              v-bind="attrs"
              :value="form.address"
              class="form-control"
              name="address"
              type="text"
              autocomplete="off"
              v-on="listeners"
              @change="
                emitLocalChange()
                form.address = $event
              "
            ></b-form-input>
          </form-group>
        </b-col>
      </b-form-row>
      <!-- address -->

      <!-- website -->
      <b-form-row class="mb-2">
        <b-col>
          <form-group label="Site" name="website" class="form-group new-legend">
            <b-form-input
              slot-scope="{ attrs, listeners }"
              v-bind="attrs"
              :value="form.website"
              class="form-control"
              name="website"
              type="text"
              autocomplete="off"
              v-on="listeners"
              @change="
                emitLocalChange()
                form.website = $event
              "
            ></b-form-input>
          </form-group>
        </b-col>
      </b-form-row>
      <!-- website -->

      <!-- observations -->
      <b-form-row class="mb-2">
        <b-col>
          <form-group
            label="Observações"
            name="observations"
            class="form-group new-legend"
          >
            <b-form-textarea
              slot-scope="{ attrs, listeners }"
              rows="4"
              max-rows="10"
              v-bind="attrs"
              :value="form.observations"
              class="form-control"
              name="observations"
              type="text"
              autocomplete="off"
              v-on="listeners"
              @change="
                emitLocalChange()
                form.observations = $event
              "
            ></b-form-textarea>
          </form-group>
        </b-col>
      </b-form-row>
      <!-- observations -->

      <!-- industry_id -->
      <b-form-row class="mb-2">
        <b-col>
          <form-group
            label="Ramo de atividade"
            name="industry_id"
            class="form-group new-legend"
          >
            <v-select
              v-model="form.industry_id"
              slot-scope="{ attrs, listeners }"
              :options="industries"
              class="w-100"
              label="name"
              :reduce="(option) => option.name"
              v-bind="attrs"
              @input="emitLocalChange()"
              v-on="listeners"
            ></v-select>
          </form-group>
        </b-col>
      </b-form-row>
      <!-- industry_id -->

      <b-row
        v-if="!newForm"
        align-h="end"
        align-content="end"
        class="small text-right"
      >
        ID: {{ form.id }}<br />
        Criado em: {{ moment(form.created_at).format('ddd LLL') }}<br />
        Atualizado em:
        {{ moment(form.updated_at).format('ddd LLL') }}
      </b-row>
      <b-row v-else align-h="center" align-content="center" class="small">
        <b-button block variant="success" :disabled="!saved" @click="submit"
          >Salvar <b-spinner v-if="!saved" small></b-spinner
        ></b-button>
      </b-row>

      <b-row class="mt-4">
        <b-button
          v-if="form.active && $route.name == 'Org View'"
          variant="danger"
          @click="
            $v.$touch()
            emitLocalChange()
            form.active = false
          "
          >Deletar</b-button
        >
      </b-row>
    </form-wrapper>
    <b-col v-if="newForm" cols="auto" md="5" class="mt-n4">
      <div v-if="similarsView">
        <span class="small">Similares</span>
        <div
          v-for="s in similarsView"
          :key="s.id + new Date().getTime()"
          class="border border-bottom small p-1"
        >
          <span class="font-weight-bold">{{ s.title || s.name }}</span>
          (ID: {{ s.id }})<br />
          {{ s.telephone || '-' }}<br />
          {{ s.mobile || '-' }}<br />
          {{ s.email || '-' }}
        </div>
      </div>
    </b-col>
  </b-container>
</template>

<script>
import Loading from './Loading.vue'
import { required } from 'vuelidate/lib/validators'
import {
  isBetweenLength,
  isValidMultipleEmails,
  anyOfRequired
} from '../packs/vuelidate/extras'

import isEmpty from 'lodash/isEmpty'

import { InputFacade } from 'vue-input-facade'

export default {
  components: { Loading, InputFacade },
  props: {
    organization: { type: [Object], default: () => {} },
    newForm: { type: [Boolean], default: false }
  },
  data() {
    return {
      saved: true,
      unwatchers: [],
      industries: [],
      localChange: null,

      similars: [],
      form: {
        id: null,
        name: null,
        telephone: null,
        mobile: null,
        email: null,
        bank_details: null,
        cpf_cnpj: null,
        address: null,
        website: null,
        observations: null,
        industry_id: null,
        created_at: null,
        updated_at: null,
        active: null
      }
    }
  },

  validations() {
    return {
      form: {
        id: {},
        name: { required },
        telephone: {
          any: anyOfRequired(['email', 'telephone', 'mobile'])
        },
        mobile: { any: anyOfRequired(['email', 'telephone', 'mobile']) },
        email: {
          size: isBetweenLength(5, 128),
          isValidMultipleEmails,
          any: anyOfRequired(['email', 'telephone', 'mobile'])
        },
        bank_details: {},
        cpf_cnpj: {},
        address: {},
        website: {},
        observations: {},
        industry_id: {},
        created_at: {},
        updated_at: {},
        active: {}
      }
    }
  },

  computed: {
    similarsView() {
      return this.similars.slice(0, 8)
    }
  },

  watch: {
    organization: {
      immediate: true,
      deep: true,
      handler() {
        if (this.newForm) return
        this.localChange = false
        this.$emit('update:localChange', false)
        this.fillFields()
      }
    }
  },

  created() {
    this.industries = this.$store.getters.industries
    if (this.newForm) return
    this.installWatchers()
  },

  mounted() {
    this.$root.$on('bv::modal::hide', this.listenToCancel)
  },

  destroyed() {
    this.$root.$off('bv::modal::hide', this.listenToCancel)
  },

  methods: {
    fillFields() {
      if (isEmpty(this.organization)) {
        Object.keys(this.form).forEach((i) => (this.form[i] = null))
        return
      }

      Object.keys(this.form).forEach(
        (i) => (this.form[i] = this.organization[i])
      )
    },

    installWatchers() {
      this.unwatchers = []
      for (let key in this.form) {
        let unwatch = this.$watch(
          'form.' + key,
          (newVal, oldVal) => this.handleChange(newVal, oldVal, key),
          {
            deep: true
          }
        )
        this.unwatchers.push(unwatch)
      }
    },

    emitLocalChange() {
      if (this.newForm) return
      this.localChange = true
      this.$emit('update:localChange', true)
    },

    searchSimilar(search) {
      if (!this.newForm) return
      if (!search) return

      console.log('search similar : ', search)

      let vm = this
      this.similarLoading = true

      Rails.ajax({
        url: `/api/organizations.json?name=${encodeURI(search)}`,
        type: 'GET',
        dataType: 'json',
        success: (data) => {
          console.log(data)
          vm.similars = data
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        },
        complete: () => {
          vm.similarLoading = false
        }
      })
    },

    handleChange(newVal, oldVal, key) {
      console.log('changed before! === ', key)

      if (this.localChange == false) return

      if (isEmpty(this.organization)) return
      if (!this.organization.id) return

      console.log('changed after! === ', key)

      // check form any changes on form
      if (!this.$v.$anyDirty) return

      // check specific changed field if valid
      if (this.$v.form[key].$invalid) return

      this.saved = false

      var fd = new FormData()

      switch (key) {
        // case 'status':
        //   fd.append('organization[deal_status_id]', this.entity.deal.deal_status_id)
        //   break

        // case 'stage':
        //   fd.append('deal[deal_stage_id]', this.entity.deal.deal_stage_id)
        //   isMove = '/move'
        //   break

        // case 'owner':
        //   fd.append('deal[owner_id]', this.entity.deal.owner_id)
        //   break

        // case 'organization':
        //   fd.append('deal[organization_id]', this.entity.deal.organization_id)
        //   break

        // case 'customer':
        //   fd.append('deal[customer_id]', this.entity.deal.customer_id)
        //   break

        // case 'contact':
        //   fd.append('deal[contact_id]', this.entity.deal.contact_id)
        //   break

        default:
          fd.append('organization[' + key + ']', newVal)
          break
      }

      // console.log(fd)

      Rails.ajax({
        //beforeSend: () => true,
        url: `/api/organizations/${this.organization.id}`,
        type: 'PATCH',
        data: fd,
        dataType: 'json',
        success: (data) => {
          console.log(data)
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    listenToCancel(bvEvent, modalId) {
      if (modalId == 'modal-org') {
        if (this.$v.form.$anyDirty && this.newForm) {
          const answer = window.confirm(
            'Deseja mesmo sair? Todas as alterações serão perdidas !'
          )
          if (answer) {
            return
          } else {
            bvEvent.preventDefault()
          }
        } else {
          return
        }
      }
    },

    submit() {
      console.log('submited!')

      this.$v.$touch()
      if (this.$v.$invalid) return

      this.saved = false

      var fd = new FormData()

      fd.append('organization[name]', this.form.name ? this.form.name : '')
      fd.append(
        'organization[telephone]',
        this.form.telephone ? this.form.telephone : ''
      )
      fd.append(
        'organization[mobile]',
        this.form.mobile ? this.form.mobile : ''
      )
      fd.append('organization[email]', this.form.email ? this.form.email : '')
      fd.append(
        'organization[bank_details]',
        this.form.bank_details ? this.form.bank_details : ''
      )
      fd.append(
        'organization[cpf_cnpj]',
        this.form.cpf_cnpj ? this.form.cpf_cnpj : ''
      )
      fd.append(
        'organization[address]',
        this.form.address ? this.form.address : ''
      )
      fd.append(
        'organization[website]',
        this.form.website ? this.form.website : ''
      )
      fd.append(
        'organization[observations]',
        this.form.observations ? this.form.observations : ''
      )
      fd.append(
        'organization[industry_id]',
        this.form.industry_id ? this.form.industry_id : ''
      )

      console.log(fd)

      Rails.ajax({
        url: `/api/organizations`,
        type: 'POST',
        data: fd,
        dataType: 'json',
        success: (data) => {
          console.log('retorno: ', data)
          if (data.id) {
            this.showToastSuccess(data)
          }
          this.$root.$off('bv::modal::hide', this.listenToCancel)
          this.$bvModal.hide('modal-org')
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },
    showToastSuccess(org) {
      const h = this.$createElement
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, [
        'Organização criada com sucesso!',
        h('br'),
        h(
          'b-link',
          {
            props: { to: { name: 'Org View', params: { id: org.id } } }
          },
          `Acessar nova organização ${org.name}`
        )
      ])
      this.$root.$bvToast.toast([vNodesMsg], {
        title: 'Sucesso',
        autoHideDelay: 10000,
        solid: true,
        variant: 'success'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.new-legend::v-deep {
  & legend {
    font-size: 10px;
    top: -18px;
    position: absolute;
  }

  & .form-control {
    font-size: 14px;
  }
}

.is-valid {
  border-color: #ced4da !important;
  background-image: none !important;
}
</style>