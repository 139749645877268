<template>
  <div ref="table" class="tabulator"></div>
</template>

<script>
import Tabulator from 'tabulator-tables'

export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    options: {
      type: Tabulator.options,
      default: () => {}
    }
  },
  data() {
    return {
      tabulatorInstance: null,
      resolvedOptions: (Tabulator.Options = {})
    }
  },

  watch: {
    tableData: {
      handler: function (newData) {
        this.tabulatorInstance.replaceData(newData)
      },
      deep: true
    },

    options: {
      immediate: true,
      deep: true,
      handler() {
        let vm = this
        this.resolvedOptions = {
          ...this.options,
          rowClick: function (e, row) {
            vm.$emit('row-click', ...[e, row])
          },
          tableBuilt: function (data) {
            vm.$emit('table-built', data)
          },
          dataLoaded: function (data) {
            vm.$emit('data-loaded', data)
          },
          columnMoved: function (column, columns) {
            vm.$emit('column-moved', ...[column, columns])
          },
          ajaxResponse: function (url, params, response) {
            vm.$emit('ajax-response', ...[url, params, response])
            return response
          },
          data: this.tableData,
          reactiveData: true
        }
        this.createTable()
      }
    }
  },

  created() {
    document.addEventListener('mousedown', this.mouseDownHandler)
  },

  beforeDestroy() {
    document.removeEventListener('mousedown', this.mouseDownHandler)
  },

  mounted() {
    this.createTable()
  },

  methods: {
    createTable() {
      if (!this.$refs.table) return
      this.tabulatorInstance = new Tabulator(
        this.$refs.table,
        this.resolvedOptions
      )
    },

    mouseDownHandler: function (e) {
      let reg = new RegExp(
        '(^|\\s)board-column-header|board-item|board-item-content|tabulator-col|no-grip(\\s|$)'
      )
      const checkClass = reg.test(e.target.className)
      if (!checkClass) {
        document.body.style.userSelect = 'none'
        this.pos = {
          left: this.tableHolder().scrollLeft,
          top: this.tableHolder().scrollTop,
          x: e.clientX,
          y: e.clientY
        }
        document.addEventListener('mousemove', this.mouseMoveHandler)
        document.addEventListener('mouseup', this.mouseUpHandler)
      }
    },
    mouseMoveHandler: function (e) {
      const dx = e.clientX - this.pos.x
      const dy = e.clientY - this.pos.y
      this.tableHolder().scrollTop = this.pos.top - dy
      this.tableHolder().scrollLeft = this.pos.left - dx
    },
    mouseUpHandler: function () {
      document.body.style.removeProperty('user-select')
      document.removeEventListener('mousemove', this.mouseMoveHandler)
      document.removeEventListener('mouseup', this.mouseUpHandler)
    },
    tableHolder() {
      return document.querySelector('.tabulator-tableHolder')
    }
  }
}
</script>

<style scoped>
</style>