<template>
  <b-container fluid class="bg-light px-4">
    <b-row align-content="end" align-h="between" align-v="center" class="small">
      <b-button variant="link" @click="$router.back()"
        ><b-icon-arrow-left />Voltar</b-button
      >

      <div v-if="saved">
        Alterações salvas com sucesso!<b-icon-check
          class="ml-2"
          scale="2"
          variant="success"
        />
      </div>
      <div v-else class="d-flex align-items-center">
        <div class="mr-4">Salvando...</div>
        <Loading :loading="true" :small="true"></Loading>
      </div>
    </b-row>
    <form-wrapper :validator="$v.form" scope="deal">
      <!-- SIDEBAR -->
      <b-row>
        <b-col cols="12" md="4">
          <!-- PESSOAS -->
          <div class="p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius">
            <form-group name="person" class="form-group font-weight-bold">
              <template #label>
                <div class="align-items-center d-flex">
                  <b-icon-person class="mr-1" />
                  Pessoas
                </div>
              </template>
              <v-select
                v-model="form.person"
                slot-scope="{ attrs, listeners }"
                class="w-100 h-auto"
                label="name"
                v-bind="attrs"
                :filterable="false"
                :clearable="false"
                :options="personOptions"
                :placeholder="'Procure aqui...'"
                :taggable="false"
                :append-to-body="true"
                :calculate-position="dropPos"
                v-on="listeners"
                @search="getPeople"
                @input="setPeople"
              >
                <template slot="no-options"> Digite para procurar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-flex flex-row align-items-center">
                    <div>
                      <b-icon-building font-scale="1.5" class="mr-2" />
                    </div>
                    <div class="flex-column">
                      <div class="font-weight-bold">{{ option.name }}</div>
                      <div>
                        {{ option.email }}
                        {{
                          option.mobile
                            | facade([
                              '####-####',
                              '#####-####',
                              '(##) ####-####',
                              '(##) #####-####'
                            ])
                        }}
                        {{
                          option.telephone
                            | facade([
                              '####-####',
                              '#####-####',
                              '(##) ####-####',
                              '(##) #####-####'
                            ])
                        }}
                      </div>
                    </div>
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }}
                  </div>
                </template>
              </v-select>
            </form-group>
            <div v-if="form.person" class="small">
              <hr />
              <b-icon-building font-scale="2" class="w-100" />
              <FormToPeople
                :person="form.person"
                @update:localChange="localChange = $event"
              />
            </div>
          </div>
          <!-- PESSOAS -->
        </b-col>
        <b-col cols="12" md="4">
          <b-row>
            <div class="w-100 p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius">
              <div class="align-items-center d-flex font-weight-bold">
                <b-icon-files class="mr-1" />
                Negócios ({{
                  form.person.deals ? form.person.deals.length : 0
                }})
              </div>
              <ul>
                <li v-for="deal in seeDeals" :key="deal.id">
                  <b-link
                    :to="{
                      name: 'Deal View',
                      params: {
                        id: deal.id
                      }
                    }"
                    >{{ deal.title }}</b-link
                  >
                </li>
              </ul>
              <b-link v-if="!seeMoreDeals" @click="seeMoreDeals = true"
                >Ver mais...</b-link
              >
              <b-link v-else @click="seeMoreDeals = false">Reduzir...</b-link>
            </div>
          </b-row>
        </b-col>
        <b-col cols="12" md="4">
          <b-col v-if="timeline">
            <b-row v-for="(note, index) in timeline" :key="index">
              <div v-if="note.spec == 'history'" class="w-100 my-2">
                <div
                  class="text-black-50 text-center"
                  style="
                    background-color: transparent;
                    border-radius: 10px;
                    border: dashed 1px lightgray;
                  "
                >
                  <span class="small text-left d-block font-italic">
                    {{ niceMoment(note.created_at) }} por {{ note.actor }}
                  </span>
                  Atualizou
                  <span class="font-italic font-weight-bold">{{
                    note.attribute
                  }}</span>
                  de ({{ note.name[0] }}) para ({{ note.name[1] }})
                </div>
              </div>
              <div v-else>
                {{ JSON.stringify(note) }}
              </div>
            </b-row>
          </b-col>
          <b-col v-else>
            <hr />
            <div class="w-100 text-center text-black-50">
              linha do tempo vazia
            </div>
            <hr />
          </b-col>
        </b-col>
      </b-row>
      <!-- SIDEBAR -->
    </form-wrapper>
    <go-top
      :size="35"
      :right="15"
      :bottom="25"
      fg-color="#000000"
      bg-color="#eaeaea"
      weight="bold"
    ></go-top>
  </b-container>
</template>

<script>
import Loading from './Loading.vue'

import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'

import FormToPeople from './FormToPeople.vue'

import { filter } from 'vue-input-facade'

import GoTop from '@inotom/vue-go-top'

export default {
  filters: { facade: filter },
  components: {
    Loading,
    FormToPeople,
    GoTop
  },
  data() {
    return {
      saved: true,
      unwatchers: [],
      personOptions: [],
      createdAt: null,
      updatedAt: null,
      creatorId: null,
      lastUpdateById: null,
      seeingPerson: null,
      localChange: null,
      form: {
        person: {}
      },
      timeline: null,
      seeMoreDeals: false,
      upHere: false
    }
  },

  validations: {
    form: {
      person: {}
    }
  },

  computed: {
    // GET DEAL FROM PARAMS NOT FROM PROP
    paramID: function () {
      return parseInt(this.$route.params.id)
    },
    seeDeals: function () {
      if (!this.form.person.deals) return
      if (this.seeMoreDeals) {
        return this.form.person.deals
      }
      return this.form.person.deals.slice(0, 10)
    }
  },

  watch: {
    '$store.state.needToUpdate': {
      immediate: true,
      deep: true,
      handler(value) {
        if (isEmpty(value)) return
        console.log('localchange: ', this.localChange)
        if (this.localChange == true) {
          this.localChange = false
          return
        }

        if (value[0] == 'person') {
          this.fetchPerson(this.seeingPerson.id)
          this.fetchTimeline(this.seeingPerson.id)
        }

        this.$store.state.needToUpdate = []
      }
    },
    paramID() {
      this.startTheFetch()
    }
  },

  created() {
    this.installWatchers()
    this.startTheFetch()
  },

  destroyed() {
    this.$store.state.seeingPerson = {}
  },

  methods: {
    startTheFetch() {
      let vm = this
      this.fetchPerson(this.paramID).then(function (data) {
        vm.$store.state.seeingPerson = data
        vm.fetchTimeline(data.id)
        vm.form.person = data
        vm.seeingPerson = data
        vm.localChange = false
        if (!isEmpty(vm.seeingPerson)) vm.fillFields(vm.seeingPerson)
      })
    },
    dropPos(dropdownList, component, { width, top, left }) {
      if (component.options.length > 0) {
        dropdownList.style.top = top
        dropdownList.style.left = 0
        dropdownList.style.width = document.body.clientWidth
      } else {
        dropdownList.style.top = top
        dropdownList.style.left = left
        dropdownList.style.width = width
      }
    },
    // por enquanto handlechange nao faz nada, mas depois temos q arrumar pro timeline
    handleChange(newVal, oldVal, key) {
      console.log(newVal)
      if (newVal) return
      console.log('changed before! === ', key)

      if (this.localChange == false) return

      console.log('changed after! === ', key)

      // check form any changes on form
      if (!this.$v.$anyDirty) return

      // check specific changed field if valid
      if (this.$v.form[key].$invalid) return

      this.saved = false

      var fd = new FormData()
      var isMove = ''

      switch (key) {
        case 'status':
          fd.append('deal[deal_status_id]', this.entity.deal.deal_status_id)
          break

        case 'stage':
          fd.append('deal[deal_stage_id]', this.entity.deal.deal_stage_id)
          isMove = '/move'
          break

        case 'owner':
          fd.append('deal[owner_id]', this.entity.deal.owner_id)
          break

        case 'organization':
          fd.append('deal[organization_id]', this.entity.deal.organization_id)
          break

        case 'customer':
          fd.append(
            'deal[customer_person_id]',
            this.entity.deal.customer_person_id
          )
          break

        case 'contact':
          fd.append(
            'deal[contact_person_id]',
            this.entity.deal.contact_person_id
          )
          break

        default:
          fd.append('deal[' + key + ']', newVal)
          break
      }

      // console.log(fd)

      Rails.ajax({
        //beforeSend: () => true,
        url: `/api/people/${this.seeingOrg.id}${isMove}`,
        type: 'PATCH',
        data: fd,
        dataType: 'json',
        success: (data) => {
          console.log(data)
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    // removePerson(person) {
    //   this.$bvModal
    //     .msgBoxConfirm(
    //       `Desconectar ${person.name} de ${this.seeingOrg.name} ?`,
    //       {
    //         title: 'Remover',
    //         //            size: 'sm',
    //         buttonSize: 'sm',
    //         okVariant: 'danger',
    //         okTitle: 'SIM',
    //         cancelTitle: 'NÃO',
    //         footerClass: 'p-2',
    //         hideHeaderClose: false,
    //         centered: true
    //       }
    //     )
    //     .then((value) => {
    //       if (value) {
    //         this.disconnectPerson(person)
    //       }
    //     })
    //     .catch((err) => {
    //       console.error(err)
    //     })
    // },

    // disconnectPerson(person) {
    //   console.log(person)

    //   var fd = new FormData()
    //   fd.append('person[organization_id]', '')

    //   Rails.ajax({
    //     url: `/api/people/${person.id}`,
    //     type: 'PATCH',
    //     data: fd,
    //     dataType: 'json',
    //     success: (data) => {
    //       console.log(data)
    //     },
    //     error: (data) => {
    //       this.$root.toast('Erro', data, 'danger'),
    //     },
    //     complete: () => {
    //       this.saved = true
    //     }
    //   })
    // },

    installWatchers() {
      this.unwatchers = []
      for (let key in this.form) {
        console.log(key)
        let unwatch = this.$watch(
          'form.' + key,
          (newVal, oldVal) => this.handleChange(newVal, oldVal, key),
          {
            deep: true
          }
        )
        this.unwatchers.push(unwatch)
      }
    },

    async fetchPerson(id) {
      const response = new Promise(function (resolve, reject) {
        if (id) {
          Rails.ajax({
            url: `/api/people/${id}?include=deals`,
            type: 'GET',
            dataType: 'json',
            success: (data) => {
              console.log(data)
              resolve(data)
            },
            error: (data) => {
              this.$root.toast('Erro', data, 'danger'), reject(data)
            },
            complete: (data) => resolve(data)
          })
        }
      })
      this.form.person = await response
      return response
    },

    async fillFields(deal) {
      console.log(deal)
      console.log('fill')
      this.form.person = deal
      this.createdAt = new Date(deal.created_at).toLocaleString()
      this.updatedAt = new Date(deal.updated_at).toLocaleString()
    },

    getPeople(search, loading) {
      loading(true)
      if (search.length > 2) {
        this.searchPeople(loading, search, this)
      } else {
        loading(false)
      }
    },

    searchPeople: debounce((loading, search, vm) => {
      Rails.ajax({
        url: `/api/people.json?name=${encodeURI(search)}`,
        type: 'GET',
        //data: fd,
        dataType: 'json',
        success: (data) => {
          console.log(data)
          vm.personOptions = data
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        },
        complete: () => {
          loading(false)
        }
      })
    }, 350),

    setPeople(obj) {
      console.log('set : ', obj)
      this.$router.push({
        name: 'Person View',
        params: {
          id: obj.id
        }
      })
    },
    fetchTimeline(id) {
      Rails.ajax({
        url: `/api/person_timeline/${id}`,
        type: 'GET',
        dataType: 'json',
        success: (data) => {
          if (data) {
            this.timeline = data.length > 0 ? data : null
          }
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    niceMoment(date) {
      //format('ddd LLL')
      return this.moment(date).calendar(null, {
        sameDay: null,
        nextDay: null,
        nextWeek: null,
        lastDay: null,
        lastWeek: null,
        sameElse: 'ddd LLL'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}

.big-radius {
  border-radius: 12px;
}

input {
  background-color: white;
}

.v-select {
  background-color: white;
}

.hiddenRemove {
  display: none;
}
.triggerRemove:hover > .hiddenRemove {
  display: inline;
}
</style>