var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wrapper',{staticClass:"d-flex flex-column",attrs:{"validator":_vm.$v.form,"scope":"deal"}},[(_vm.value)?_c('b-row',{attrs:{"align-h":"center"}},[(_vm.value.status == 'Em aberto')?_c('div',[(_vm.moment(_vm.value.due_date).isBefore())?_c('b-badge',{staticClass:"float-right",attrs:{"pill":"","variant":"danger"}},[_vm._v("Atrasado")]):_c('b-badge',{staticClass:"float-right",attrs:{"pill":"","variant":"success"}},[_vm._v("Planejado")])],1):_vm._e()]):_vm._e(),_vm._v(" "),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":_vm.form.type &&
          (_vm.form.type.name === 'Medição' || _vm.form.type.name === 'Cronograma')
          ? '3'
          : '6'}},[_c('form-group',{staticClass:"form-group",attrs:{"name":"type","label":"Tipo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var attrs = ref.attrs;
          var listeners = ref.listeners;
return _c('v-select',_vm._g(_vm._b({staticClass:"w-100 h-100",attrs:{"options":_vm.activity_types,"name":"type","label":"name","clearable":false},on:{"input":_vm.changeDateTimeDefault},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},'v-select',attrs,false),listeners))}}])})],1),_vm._v(" "),(_vm.form.type &&
          (_vm.form.type.name === 'Medição' || _vm.form.type.name === 'Cronograma')
          )?_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('form-group',{staticClass:"form-group",attrs:{"name":"job_due_time","label":"Prazo de entrega"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var attrs = ref.attrs;
          var listeners = ref.listeners;
return _c('flat-pickr',_vm._g(_vm._b({staticClass:"form-control bg-white text-center",attrs:{"config":_vm.flatpickrOptsJob,"name":"due_date"},model:{value:(_vm.form.job_due_time),callback:function ($$v) {_vm.$set(_vm.form, "job_due_time", $$v)},expression:"form.job_due_time"}},'flat-pickr',attrs,false),listeners))}}],null,false,618590814)}),_vm._v(" "),_c('div',{staticClass:"small text-muted mt-2"},[_vm._v("\n          "+_vm._s(_vm.daysDiff)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"p-1",attrs:{"id":'popover-color-target'}},[_c('span',[_vm._v("Cor")]),_vm._v(" "),_c('div',{style:({
              backgroundColor: _vm.form.hex_color || '#007bff',
              width: '100%',
              height: '10px'
            })})]),_vm._v(" "),_c('b-popover',{attrs:{"target":'popover-color-target',"triggers":"hover","placement":"auto","boundary":"window"}},[_c('form-group',{staticClass:"form-group",attrs:{"name":"hex_color","label":"Color"}},[_c('color-picker',{attrs:{"value":_vm.form.hex_color || '#007bff'},on:{"input":function($event){_vm.form.hex_color = $event.hex}}})],1)],1)],1):_vm._e(),_vm._v(" "),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('form-group',{staticClass:"form-group",attrs:{"name":"status","label":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var attrs = ref.attrs;
            var listeners = ref.listeners;
return _c('b-form-checkbox',_vm._g(_vm._b({staticClass:"w-100 h-100 input-pointer",attrs:{"value":"Concluído","unchecked-value":"Em aberto","name":"status","size":"lg"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},'b-form-checkbox',attrs,false),listeners),[_c('span',{class:{ 'text-primary': _vm.form.status == 'Em aberto' },style:({
                textDecoration:
                  _vm.form.status == 'Concluído' ? 'line-through' : undefined,
                cursor: 'pointer',
                color: _vm.form.status == 'Concluído' ? '#28a745' : undefined
              })},[_vm._v(_vm._s(_vm.form.status))])])}}])})],1)],1),_vm._v(" "),_c('form-group',{class:[
        'form-group',
        _vm.computeIsAfter ? 'input-text-info' : 'input-text-danger'
      ],attrs:{"name":"due_date","label":"Data de vencimento","required":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var attrs = ref.attrs;
      var listeners = ref.listeners;
return _c('flat-pickr',_vm._g(_vm._b({staticClass:"form-control bg-white text-center",attrs:{"config":_vm.flatpickrOpts,"name":"due_date"},model:{value:(_vm.form.due_date),callback:function ($$v) {_vm.$set(_vm.form, "due_date", $$v)},expression:"form.due_date"}},'flat-pickr',attrs,false),listeners))}}])}),_vm._v(" "),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"9"}},[_c('form-group',{staticClass:"form-group",attrs:{"name":"enrollments","label":"Atribuir a usuários","required":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var attrs = ref.attrs;
      var listeners = ref.listeners;
return _c('v-select',_vm._g(_vm._b({staticClass:"w-100 h-100 bg-white",attrs:{"placeholder":"Selecione...","options":_vm.owners,"multiple":true,"name":"enrollments","label":"email"},model:{value:(_vm.form.enrollments),callback:function ($$v) {_vm.$set(_vm.form, "enrollments", $$v)},expression:"form.enrollments"}},'v-select',attrs,false),listeners))}}])})],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('form-group',{staticClass:"form-group small mb-0",attrs:{"name":"calendar_sync","label":"Google Calendar ?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var attrs = ref.attrs;
      var listeners = ref.listeners;
return _c('b-form-checkbox',_vm._g(_vm._b({staticClass:"w-100 h-100 input-pointer",attrs:{"value":"Sim","unchecked-value":"Não","name":"calendar_sync","size":"sm"},model:{value:(_vm.form.calendar_sync),callback:function ($$v) {_vm.$set(_vm.form, "calendar_sync", $$v)},expression:"form.calendar_sync"}},'b-form-checkbox',attrs,false),listeners),[_c('span',{style:({
                cursor: 'pointer',
                color: _vm.form.calendar_sync == 'Não' ? '#dc3545' : undefined
              })},[_vm._v(_vm._s(_vm.form.calendar_sync))])])}}])})],1)],1),_vm._v(" "),(_vm.form.calendar_sync == 'Sim')?_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticStyle:{"position":"absolute","right":"15px"}},_vm._l((_vm.addresses),function(val,index){return _c('b-button',{key:index,staticClass:"m-0 p-0 small",attrs:{"variant":"link"},on:{"click":function($event){_vm.form.event_location = val.value}}},[_vm._v(_vm._s(index))])}),1),_vm._v(" "),_c('form-group',{staticClass:"form-group small",attrs:{"name":"event_location","label":"Local"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var attrs = ref.attrs;
              var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({staticClass:"w-100 h-100",attrs:{"name":"event_location","size":"sm"},model:{value:(_vm.form.event_location),callback:function ($$v) {_vm.$set(_vm.form, "event_location", $$v)},expression:"form.event_location"}},'b-input',attrs,false),listeners))}}],null,false,2058868002)})],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('form-group',{staticClass:"form-group small",attrs:{"name":"event_duration","label":"Período"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var attrs = ref.attrs;
              var listeners = ref.listeners;
return _c('v-select',_vm._g(_vm._b({staticClass:"w-100 h-100",attrs:{"options":['1 hora', 'Dia inteiro'],"name":"event_duration","clearable":false},model:{value:(_vm.form.event_duration),callback:function ($$v) {_vm.$set(_vm.form, "event_duration", $$v)},expression:"form.event_duration"}},'v-select',attrs,false),listeners))}}],null,false,3997374864)})],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('form-group',{staticClass:"form-group small",attrs:{"name":"event_alert","label":"Alertar ?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var attrs = ref.attrs;
              var listeners = ref.listeners;
return _c('b-form-checkbox',_vm._g(_vm._b({staticClass:"w-100 h-100 input-pointer",attrs:{"value":"Sim","unchecked-value":"Não","name":"event_alert","size":"sm"},model:{value:(_vm.form.event_alert),callback:function ($$v) {_vm.$set(_vm.form, "event_alert", $$v)},expression:"form.event_alert"}},'b-form-checkbox',attrs,false),listeners),[_c('span',{style:({
                cursor: 'pointer',
                color: _vm.form.event_alert == 'Não' ? '#dc3545' : undefined
              })},[_vm._v(_vm._s(_vm.form.event_alert))])])}}],null,false,899590406)})],1)],1):_vm._e(),_vm._v(" "),_c('tip-tap',{staticClass:"tiptap-main-yellow w-100 nice-scroll",model:{value:(_vm.form.activity_note),callback:function ($$v) {_vm.$set(_vm.form, "activity_note", $$v)},expression:"form.activity_note"}}),_vm._v(" "),(!_vm.value)?_c('b-button',{staticClass:"ml-auto btn-sm mx-3 p-1 mt-2",attrs:{"variant":"primary","block":""},on:{"click":_vm.addActivity}},[_vm._v("Adicionar atividade")]):_c('b-button',{staticClass:"ml-auto mx-3 mt-2",attrs:{"variant":"primary"},on:{"click":_vm.updateActivity}},[_vm._v("Editar")])],1),_vm._v(" "),(_vm.value)?_c('b-button',{staticStyle:{"position":"absolute","right":"15px","bottom":"15px"},attrs:{"variant":"danger","size":"sm"},on:{"click":_vm.deleteActivity}},[_vm._v("Deletar")]):_vm._e(),_vm._v(" "),(_vm.value)?_c('div',{staticClass:"small text-muted"},[_vm._v("\n    Criado em: "+_vm._s(_vm.niceMoment(_vm.value.created_at))),_c('br'),_vm._v("\n    Criado por: "+_vm._s(_vm.value.creator.name)),_c('br'),_vm._v("\n    Atualizado em: "+_vm._s(_vm.niceMoment(_vm.value.updated_at))),_c('br'),_vm._v("\n    Atualizado por: "+_vm._s(_vm.value.last_update_by_id)),_c('br'),_vm._v("\n    Concluído em: "+_vm._s(_vm.niceMoment(_vm.value.closed_date))),_c('br'),_vm._v(" "),(_vm.value.event_id)?_c('div',[_vm._v("\n      Evento:\n      "),(_vm.value.event_link)?_c('b-link',{attrs:{"target":"_blank","href":_vm.value.event_link}},[_vm._v("\n        Link para o evento do Google Calendar\n      ")]):_c('div',[_vm._v("nenhum evento registrado")])],1):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }