import Vue from 'vue/dist/vue.esm'
import Vuelidate from "vuelidate"
import VuelidateErrorExtractor from "vuelidate-error-extractor"
import FormGroup from "../../components/forms/FormGroup.vue"
import formWrapper from "../../components/forms/FormWrapper"

Vue.use(Vuelidate);
Vue.use(VuelidateErrorExtractor, {
  i18n: 'errors',
  i18nAttributes: { __default: 'attributes' },

});

Vue.component("FormGroup", FormGroup);
Vue.component("formWrapper", formWrapper);

