
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)


// TODO: Make it lazy load
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /(.*)\.json/i)
  const messages = {}
  locales.keys().forEach(file => {
    let regex = new RegExp("^\\.\\/(?<locale>[A-Za-z0-9-_]+)\\/(?<tag>[A-Za-z0-9-_]+).*$")
    const matched = file.match(regex)
    if (matched && matched.length > 2) {
      const locale = matched[1]
      const tag = matched[2]
      if (!Object.prototype.hasOwnProperty.call(messages, locale)) {
        messages[locale] = {}
      }

      messages[locale][tag] = locales(file)
    }
  })
  console.log("i18n messages are loaded.")
  return messages
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  missing: (locale, key) => {
    return "[" + locale + "." + key + "]"
  },
  messages: loadLocaleMessages()
})
