<template>
  <div class="app-container">
    <!-- floating menu component -->
    <CronogramaTitle @update-current-month-year="updateCurrentMonthYear" />

    <div ref="firstScrollContainer" class="scroll-container" :style="{ 'max-height': maxHeight }">
      <table>
        <thead>
          <tr>
            <th style="width: 140px">Projeto <b-button variant="link" class="p-0 m-0 ml-1" @click="adjustFirstTableToScreenHeight()"><b-icon icon="arrows-fullscreen" scale="0.8"></b-icon></b-button></th>
            <th style="width: 20px"></th>
            <th style="width: 80px">Início</th>
            <th style="width: 80px">Fim</th>
            <th
              v-for="day in getDaysInMonth(currentMonth, currentYear)"
              :key="day"
              :class="{ weekend: isWeekend(day), today: isToday(day) }"
            >
              <small>{{ day }}</small>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(task, index) in tasks" :key="index">
            <td
              class="p-0"
              draggable="true"
              :style="{
                textWrap: 'nowrap',
                overflow: 'hidden'
              }"
              @dragstart="drag($event, task)"
              @dragend="isDragging = false"
            >
              <b-link
                :title="task.name"
                :to="{ name: 'Deal View', params: { id: task.deal_id } }"
                >{{ task.name }}</b-link
              >
            </td>
            <td class="p-0">
              <b-button
                class="p-0 m-0 border-0 w-100"
                style="font-size: large; line-height: 0;"
                variant="outline-primary"
                @click="openActivity(task)"
                ><b-icon
                  icon="list"
                  font-scale="0.75"
                  style="vertical-align: middle"
                ></b-icon
              ></b-button>

              <div :id="'popover-target-' + task.activity_id + index"  class="p-0">
                <div
                  :style="{
                    backgroundColor: task.hex_color || '#007bff',
                    width: '100%',
                    height: '10px'
                  }"
                ></div>
              </div>
              <b-popover
                :key="'popover-target-' + task.activity_id + index"
                :target="'popover-target-' + task.activity_id + index"
                triggers="hover"
                placement="auto"
                boundary="window"
              >
                <form-group name="hex_color" class="form-group" label="Color">
                  <color-picker
                    :value="task.hex_color || '#007bff'"
                    @input="updateActivityHexColorDebounced($event, task)"
                  />
                  <span><b-link @click="task.hex_color = getRandomColor()">Random</b-link></span>
                </form-group>
              </b-popover>
            </td>
            <td class="p-0">
              {{ moment(task.start_date).format('DD-MM-YY') }}
            </td>
            <td class="p-0">{{ moment(task.end_date).format('DD-MM-YY') }}</td>
            <td
              v-for="n in getDaysInMonth(currentMonth, currentYear)"
              :key="n"
              :class="{
                'p-0': true,
                weekend: isWeekend(n),
                today: isToday(n)
              }"
              :style="{
                backgroundColor: isTaskActive(task, n)
                  ? task.hex_color || '#007bff'
                  : null
              }"
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div ref="secondScrollContainer" class="scroll-container" :style="{ 'max-height': maxHeight }">
      <table>
        <thead>
          <tr>
            <th style="width: 140px">
              <div class="d-flex">
                Recurso
                <b-button variant="link" class="p-0 m-0 ml-1" @click="adjustSecondTableToScreenHeight()"><b-icon icon="arrows-fullscreen" scale="0.8"></b-icon></b-button>
                <!-- plus button -->
                <b-button
                  v-b-modal.modal-add-person
                  variant="outline-primary"
                  size="sm"
                  class="ml-auto py-0 px-1"
                  style="border-radius: 100%"
                  ><b-icon-plus scale="1.4"
                /></b-button>
              </div>
            </th>
            <th style="width: 180px"></th>
            <th :colspan="getDaysInMonth(currentMonth, currentYear)"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(resource, index) in resources" :key="index">
            <td>
              <div class="d-flex">
                {{ resource.name }}
                <button
                  class="btn btn-outline-danger btn-sm px-1 py-0 ml-auto"
                  style="border-radius: 100%"
                  @click="removeResource(index)"
                >
                  <b-icon-x scale="1.4" />
                </button>
              </div>
            </td>
            <td></td>
            <td
              v-for="day in getDaysInMonth(currentMonth, currentYear)"
              :key="day + currentMonth + currentYear"
              class="p-0 py-2 td-on-hover"
              :style="{
                verticalAlign: 'top'
              }"
              :class="{
                dragging: isDragging,
                weekend: isWeekend(day),
                today: isToday(day)
              }"
              @drop="drop($event, resource, day)"
              @dragover="allowDrop($event)"
              @dragenter="handleDragEnter"
              @dragleave="handleDragLeave"
            >
              <div
                :style="{
                  height: '8px',
                  backgroundColor: 'transparent',
                  pointerEvents: 'none'
                }"
              ></div>
              <div
                v-for="(task, taskIndex) in resource.tasks"
                :key="'resource-task-' + day + task.id + '-lane-' + taskIndex"
              >
                <div
                  v-if="dateCheck(day, task.start_date, task.end_date, 'range')"
                  variant="primary"
                  :style="{
                    width: '105%',
                    height: '8px',
                    backgroundColor: task.hex_color || '#007bff',
                    position: 'relative'
                  }"
                >
                  <div
                    v-if="dateCheck(day, task.start_date, null, 'sameOrFirst')"
                    :style="{
                      width: calculateTaskWidth(task) + 'px',
                      height: '8px',
                      position: 'relative',
                      zIndex: 99
                    }"
                    @mouseenter="$set(task, 'showName', true)"
                    @mouseleave="mouseLeaveShowNameTimeout(task)"
                  >
                    <div
                      v-if="((day == new Date(task.start_date).getDate() &&
                        currentMonth ==
                        new Date(task.start_date).getMonth() &&
                        currentYear ==
                        new Date(task.start_date).getFullYear()) ||
                        (new Date(currentYear, currentMonth, 1).getTime() >=
                          new Date(task.start_date).getTime() &&
                          day == 1)) &&
                        task.showName
                        "
                      :ref="'taskName'"
                      class="d-flex"
                      :style="{
                        position: 'absolute',
                        bottom: '0px',
                        textWrap: 'nowrap',
                        width: 'auto',
                        left: willOverflow(task),
                        backgroundColor: task.hex_color || '#007bff',
                        color: task.text_color_1,
                        zIndex: 100
                      }"
                    >
                      <div class="flex flex-column ml-2">
                        <span>{{ task.name }}</span>
                        <button
                          class="btn btn-danger border btn-sm p-0"
                          style="border-radius: 100%"
                          @click="removeResourceTask(task)"
                        >
                          <b-icon-x scale="1.4" />
                        </button>
                      </div>
                      <div
                        v-if="activeResourceTask"
                        class="flex flex-column ml-2"
                      >
                        <div class="row no-gutters flex-nowrap">
                          <b-form-input
                            :style="{
                              width: '140px',
                              fontSize: 'smaller',
                              border: 'none',
                              borderNottom: '1px solid black',
                              background: 'transparent',
                              color: task.text_color_1
                            }"
                            :class="{
                              'h-100 p-0': true,
                              'white-color': task.text_color_1 === 'white'
                            }"
                            :value="formattedStartDateTime"
                            type="datetime-local"
                            @blur="formattedStartDateTime = $event.target.value"
                          ></b-form-input>
                        </div>
                        <div class="row no-gutters flex-nowrap">
                          <b-form-input
                            :style="{
                              width: '140px',
                              fontSize: 'smaller',
                              border: 'none',
                              borderNottom: '1px solid black',
                              background: 'transparent',
                              color: task.text_color_1
                            }"
                            :class="{
                              'h-100 p-0': true,
                              'white-color': task.text_color_1 === 'white'
                            }"
                            :value="formattedEndDateTime"
                            type="datetime-local"
                            @blur="formattedEndDateTime = $event.target.value"
                          ></b-form-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  :style="{
                    height: '8px',
                    backgroundColor: 'transparent',
                    pointerEvents: 'none'
                  }"
                ></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-modal id="modal-add-person" centered hide-footer hide-header>
      <b-form-group class="form-group font-weight-bold">
        <template #label>
          <div class="align-items-center d-flex">
            <b-icon-person-fill />
            Pessoa
          </div>
        </template>
        <v-select
          v-model="newPerson"
          slot-scope="{ attrs, listeners }"
          class="w-100 h-auto"
          style="z-index: 1000"
          label="name"
          v-bind="attrs"
          :filterable="false"
          :options="peopleOptions"
          :placeholder="'Procure aqui...'"
          :taggable="false"
          :append-to-body="true"
          :calculate-position="dropPos"
          v-on="listeners"
          @search="getPeople"
          @search:blur="$refs.addPersonButton.focus()"
        >
          <template slot="no-options"> Digite para procurar... </template>
          <template slot="option" slot-scope="option">
            <div class="d-flex flex-row align-items-center">
              <div>
                <b-icon-person-circle font-scale="1.5" class="mr-2" />
              </div>
              <div class="flex-column">
                <div class="font-weight-bold">{{ option.name }}</div>
                <div>
                  {{ option.email }}
                  {{
                    option.mobile
                    | facade([
                      '####-####',
                      '#####-####',
                      '(##) ####-####',
                      '(##) #####-####'
                    ])
                  }}
                  {{
                    option.telephone
                    | facade([
                      '####-####',
                      '#####-####',
                      '(##) ####-####',
                      '(##) #####-####'
                    ])
                  }}
                </div>
              </div>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
      </b-form-group>
      <b-button
        ref="addPersonButton"
        block
        variant="success"
        @click="addResource()"
        >Adicionar à Recursos
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import ActivityForm from './ActivityForm.vue'
import CronogramaTitle from './CronogramaTitle.vue'

import debounce from 'lodash/debounce'

import { filter } from 'vue-input-facade'
import { Chrome } from 'vue-color'

export default {
  filters: { facade: filter },
  components: {
    CronogramaTitle,
    // eslint-disable-next-line vue/no-unused-components
    ActivityForm,
    'color-picker': Chrome
  },
  data() {
    return {
      isDragging: false,
      tasks: [],
      resources: [],
      maxHeight: 0,
      updateActivityHexColorDebounced: null,
      currentMonth: localStorage.getItem('month') || new Date().getMonth(),
      currentYear: localStorage.getItem('year') || new Date().getFullYear(),
      activeResourceTask: null,
      activeResource: null,
      firstColWidth: 120,
      secondColWidth: 100,
      thirdColWidth: 100,
      windowWidth: window.innerWidth, // Initial value
      resizeTimeout: null, // timeout for debouncing
      lanes: {},
      newPerson: null,
      peopleOptions: [],
      activity: {
        hex_color: '#007bff',
        id: null
        // Other activity fields...
      }
    }
  },
  metaInfo() {
    return {
      title: this.getMonthName(this.currentMonth) + ' - Cronograma'
    }
  },
  computed: {
    formattedStartDateTime: {
      get() {
        var date = this.moment(this.activeResourceTask.start_date)
          .local()
          .format('YYYY-MM-DDTHH:mm:ss')
        return date ? date : null
      },
      set(value) {
        console.log(value)
        var dateValue = new Date(value)
        const startDateTime = dateValue.toISOString()

        if (
          new Date(startDateTime) > new Date(this.activeResourceTask.end_date)
        ) {
          alert('Dia inicial não pode ser maior que dia final.')
          return
        }

        this.updateActivitiesResource(this.activeResourceTask, {
          start: startDateTime
        })
          .then(() => {
            this.activeResourceTask.start_date = startDateTime
            this.sortTasks(this.activeResource)
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
    formattedEndDateTime: {
      get() {
        var date = this.moment(this.activeResourceTask.end_date)
          .local()
          .format('YYYY-MM-DDTHH:mm:ss')
        return date ? date : null
      },
      set(value) {
        var dateValue = new Date(value)
        const endDateTime = dateValue.toISOString()

        if (
          new Date(endDateTime) < new Date(this.activeResourceTask.start_date)
        ) {
          alert('Dia inicial não pode ser maior que dia final.')
          return
        }
        this.updateActivitiesResource(this.activeResourceTask, {
          end: endDateTime
        })
          .then(() => {
            this.activeResourceTask.end_date = endDateTime
            this.sortTasks(this.activeResource)
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
    fixedColumnsWidth() {
      return this.firstColWidth + this.secondColWidth + this.thirdColWidth
    },
    previousColors() {
      // return array with all hex_color from all tasks
      return this.tasks.map((task) => task.hex_color || "#007bff")
    }
  },

  watch: {
    resources: {
      handler(resources) {
        resources.forEach((resource) => {
          resource.tasks?.forEach((task) => {
            if (task.showName) {
              this.activeResource = resource
              this.activeResourceTask = task
              // Your action here
            }
          })
        })
      },
      deep: true
    }
  },

  created() {
    window.addEventListener('resize', this.debouncedHandleResize)
  },
  mounted() {
    this.debouncedHandleResize()
    this.updateActivityHexColorDebounced = debounce(
      this.updateActivityHexColor,
      1000
    ).bind(this)
    this.fetchTasksAndResources()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debouncedHandleResize)
    if (
      this.updateActivityHexColorDebounced &&
      this.updateActivityHexColorDebounced.cancel
    ) {
      this.updateActivityHexColorDebounced.cancel()
    }
  },

  methods: {
    createDateFromISOString(isoDateString) {
      let date = new Date(isoDateString)
      return new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
      )
    },
    dateCheck(day, start_date, end_date, checkType) {
      let startDate = this.createDateFromISOString(start_date)
      let endDate = this.createDateFromISOString(end_date)
      let currentDate = new Date(
        Date.UTC(this.currentYear, this.currentMonth, day, 0, 0, 0)
      )
      let firstDayOfMonth = new Date(
        Date.UTC(this.currentYear, this.currentMonth, 1, 0, 0, 0)
      )

      switch (checkType) {
        case 'range':
          return (
            startDate.getTime() <= currentDate.getTime() &&
            currentDate.getTime() <= endDate.getTime()
          )
        case 'sameOrFirst':
          return (
            startDate.getTime() == currentDate.getTime() ||
            (startDate.getTime() <= firstDayOfMonth.getTime() && day == 1)
          )
        default:
          return false
      }
    },
    dropPos(dropdownList, component, { width, top, left }) {
      dropdownList.style.zIndex = 1100
      if (component.options.length > 0) {
        dropdownList.style.top = top
        dropdownList.style.left = 0
        dropdownList.style.width = document.body.clientWidth
      } else {
        dropdownList.style.top = top
        dropdownList.style.left = left
        dropdownList.style.width = width
      }
    },
    getPeople(search, loading) {
      loading(true)
      if (search.length > 2) {
        this.searchPeople(loading, search, this)
      } else {
        loading(false)
      }
    },

    searchPeople: debounce((loading, search, vm) => {
      Rails.ajax({
        url: `/api/people.json?name=${encodeURI(search)}`,
        type: 'GET',
        //data: fd,
        dataType: 'json',
        success: (data) => {
          vm.peopleOptions = data
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        },
        complete: () => {
          loading(false)
        }
      })
    }, 350),
    async willOverflow() {
      await this.$nextTick()
      if (!this.$refs['taskName']) return false
      if (this.$refs['taskName'].length == 0) return false
      const maxAllowedWidth = this.windowWidth
      const taskNameWidth =
        this.$refs['taskName'][0].getBoundingClientRect().right
      const willOverflow = taskNameWidth > maxAllowedWidth
      const leftStyle = willOverflow
        ? -(taskNameWidth - this.windowWidth) + 'px'
        : '0px'
      this.$refs['taskName'][0].style.left = leftStyle
    },
    // Function to generate a random color
    getRandomColor() {
      const colorRange = 16777215 // Total number of possible hex colors
      const minColorDistance = 20 // Minimum color distance to consider colors visually distinct

      // Generate a random hex color code
      const randomHexColor =
        '#' +
        Math.floor(Math.random() * colorRange)
          .toString(16)
          .padStart(6, '0')

      // Check color distance with previously generated colors
      for (const color of this.previousColors) {
        if (this.getColorDistance(randomHexColor, color) < minColorDistance) {
          // If color distance is too close, recursively call the function again
          return this.getRandomColor()
        }
      }

      // Return the random color
      return randomHexColor
    },

    // Function to calculate color distance
    getColorDistance(color1, color2) {
      const hexToRGB = (hex) => {
        const r = parseInt(hex.slice(1, 3), 16)
        const g = parseInt(hex.slice(3, 5), 16)
        const b = parseInt(hex.slice(5, 7), 16)
        return [r, g, b]
      }

      const [r1, g1, b1] = hexToRGB(color1)
      const [r2, g2, b2] = hexToRGB(color2)

      return Math.sqrt((r2 - r1) ** 2 + (g2 - g1) ** 2 + (b2 - b1) ** 2)
    },
    async openActivity(task) {
      let activity = await this.getActivity(task)
      let h = this.$createElement
      let that = this
      const vnode = h('ActivityForm', {
        props: { value: activity },
        on: {
          updated: () => that.fetchTasksAndResources()
        }
      })
      this.$bvModal.msgBoxOk([vnode], {
        id: 'activity_modal',
        footerClass: 'd-none',
        title: 'Atividades',
        centered: true,
        size: 'lg'
      })
    },
    async getActivity(task) {
      let id = task.activity_id
      if (!id) return
      return new Promise((resolve, reject) => {
        Rails.ajax({
          url: `/api/activities/${id}`,
          type: 'GET',
          dataType: 'json',
          success: (data) => {
            if (data) {
              resolve(data)
            }
          },
          error: (data) => {
            this.$root.toast('Erro - ' + data.status, data.status, 'danger')
            reject(data)
          },
          complete: () => {
            // this.saved = true
            // this.loading = false
          }
        })
      })
    },

    updateActivitiesResource(activityResource, date) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append(
          'activities_resource[start_date]',
          date.start ? date.start : activityResource.start_date
        )
        formData.append(
          'activities_resource[end_date]',
          date.end ? date.end : activityResource.end_date
        )

        Rails.ajax({
          url: `/api/activities_resources/${activityResource.activities_resources_id}`,
          type: 'PATCH',
          data: formData,
          dataType: 'json',
          success: () => {
            resolve()
          },
          error: (data) => {
            this.$root.toast('Erro - ' + data.status, data.status, 'danger')
            reject()
          }
        })
      })
    },

    handleDragEnter(event) {
      event.target.classList.add('hovered')
    },

    handleDragLeave(event) {
      event.target.classList.remove('hovered')
    },
    calculateTaskWidth(task) {
      let startDate = new Date(task.start_date)
      let endDate = new Date(task.end_date)
      let currentMonth = parseInt(this.currentMonth)

      const currentMonthStartDate = new Date(this.currentYear, currentMonth, 1)
      const currentMonthEndDate = new Date(
        this.currentYear,
        currentMonth + 1,
        0,
        23,
        59,
        59
      )
      // If task start date is before current month, cap it to the start of the current month
      if (startDate.getTime() < currentMonthStartDate.getTime()) {
        startDate = currentMonthStartDate
      }

      // If task end date is after current month, cap it to the end of the current month
      if (endDate.getTime() > currentMonthEndDate.getTime()) {
        endDate = currentMonthEndDate
      }

      // Plus 1 because both the start and end dates are inclusive
      const duration =
        Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1

      const monthDays = currentMonthEndDate.getDate()

      // Get the available width for the days
      const availableWidth = this.windowWidth - this.fixedColumnsWidth

      // Calculate the width of each day
      const dayWidth = availableWidth / monthDays

      // Calculate and return the width of the task
      return duration * dayWidth
    },

    isTaskActive(task, day) {
      // refactor to midnight to correctly handle day highlight
      const taskStartDate = new Date(task.start_date)
      const taskStartDateMidnight = new Date(
        taskStartDate.getFullYear(),
        taskStartDate.getMonth(),
        taskStartDate.getDate()
      )
      const taskEndDate = new Date(task.end_date)
      const taskEndDateMidnight = new Date(
        taskEndDate.getFullYear(),
        taskEndDate.getMonth(),
        taskEndDate.getDate()
      )
      const dayDate = new Date(this.currentYear, this.currentMonth, day)
      const dayDateMidnight = new Date(
        dayDate.getFullYear(),
        dayDate.getMonth(),
        dayDate.getDate()
      )
      return (
        dayDateMidnight >= taskStartDateMidnight &&
        dayDateMidnight <= taskEndDateMidnight
      )
    },
    mouseLeaveShowNameTimeout(task) {
      setTimeout(() => {
        this.$set(task, 'showName', false)
      }, 200)
    },

    allowDrop(event) {
      event.preventDefault()
      this.isDragging = true
    },
    drag(event, task) {
      this.isDragging = true
      event.dataTransfer.setData('task', JSON.stringify(task))
    },
    drop(event, resource, day) {
      event.preventDefault()
      this.isDragging = false
      event.target.classList.remove('hovered')
      const task = JSON.parse(event.dataTransfer.getData('task'))
      task.start_date = new Date(
        this.currentYear,
        this.currentMonth,
        day
      ).toISOString()
      task.end_date = new Date(
        this.currentYear,
        this.currentMonth,
        day + 4
      ).toISOString() // adjust as necessary
      task.hex_color = task.hex_color || '#007bff'
      task.text_color_1 = this.getContrastYIQ(task.hex_color)

      // Check if a lane exists for the current resource
      if (!Object.prototype.hasOwnProperty.call(this.lanes, resource.id)) {
        // If a lane does not exist, set the lane number to 1
        this.$set(this.lanes, resource.id, 1)
      }

      // Assign the lane number to the task and increment the lane number
      task.lane = this.lanes[resource.id]
      this.$set(this.lanes, resource.id, this.lanes[resource.id] + 1)

      // Create a new FormData object
      const formData = new FormData()

      // Append the necessary fields to the FormData
      formData.append('activity_id', task.activity_id) // Make sure this is set with the correct activity ID
      formData.append('resource_id', resource.id)
      formData.append('start_date', task.start_date)
      formData.append('end_date', task.end_date)
      formData.append('is_deleted', false)

      // Make an API call to save the activity-resource correlation
      Rails.ajax({
        url: `/api/activities_resources`,
        type: 'POST',
        data: formData,
        dataType: 'json',
        success: (data) => {
          if (data) {
            task.activities_resources_id = data.id
          }
        },
        error: (data) => {
          this.$root.toast('Erro - ' + data.status, data.status, 'danger')
        }
      })

      // Add the task to the resource's tasks array
      if (!Object.prototype.hasOwnProperty.call(resource, 'tasks')) {
        this.$set(resource, 'tasks', [task])
      } else {
        resource.tasks.push(task)
      }

      // Sort the tasks array by start_date and then end_date
      this.sortTasks(resource)
    },

    sortTasks(resource) {
      resource.tasks.sort((a, b) => {
        const startDateComparison =
          new Date(a.start_date) - new Date(b.start_date)
        if (startDateComparison !== 0) {
          return startDateComparison
        }
        return new Date(a.end_date) - new Date(b.end_date)
      })
    },

    getContrastYIQ(hexcolor) {
      if (!hexcolor) return 'white'
      hexcolor = hexcolor.replace('#', '')
      var r = parseInt(hexcolor.substr(0, 2), 16)
      var g = parseInt(hexcolor.substr(2, 2), 16)
      var b = parseInt(hexcolor.substr(4, 2), 16)
      var yiq = (r * 299 + g * 587 + b * 114) / 1000
      return yiq >= 128 ? 'black' : 'white'
    },

    handleResize() {
      // update maxHeight based on viewport height minus header & footer height
      // 92 = 62 +  30 (it should be dynamically)
      this.$refs.firstScrollContainer.style.height = null
      this.$refs.secondScrollContainer.style.height = null
      this.maxHeight = ((window.innerHeight - 92) / 2) + 'px' // adjust as necessary
      this.windowWidth = window.innerWidth // Update window width on resize
    },

    adjustFirstTableToScreenHeight() {
      this.maxHeight = null
      let height = this.$refs.firstScrollContainer.style.height
      if (height == window.innerHeight + 'px') {
        this.$refs.firstScrollContainer.style.height = null
      } else {
        this.$refs.firstScrollContainer.style.height = window.innerHeight + 'px'
      }
    },

    adjustSecondTableToScreenHeight() {
      this.maxHeight = null
      let height = this.$refs.secondScrollContainer.style.height
      if (height == window.innerHeight + 'px') {
        this.$refs.secondScrollContainer.style.height = null
      } else {
        this.$refs.secondScrollContainer.style.height = window.innerHeight + 'px'
      }
    },

    debouncedHandleResize() {
      clearTimeout(this.resizeTimeout)
      this.resizeTimeout = setTimeout(this.handleResize, 200) // delay of 200ms
    },
    fetchTasksAndResources() {
      return new Promise((resolve, reject) => {
        const startDate = new Date(this.currentYear, this.currentMonth, 1)
        const endDate = new Date(
          this.currentYear,
          parseInt(this.currentMonth) + 1,
          0
        )

        Rails.ajax({
          url: '/api/gantt_view',
          type: 'GET',
          data: `start_date=${startDate}&end_date=${endDate}`,
          success: (data) => {
            this.tasks = data.activities
            this.resources = data.resources
            this.updateDomTasks()
            resolve() // Promise resolved here
          },
          error: (data) => {
            console.log('error', data)
            reject() // Promise rejected here
          }
        })
      })
    },

    async updateDomTasks() {
      await this.$nextTick()
      this.lanes = {}
      this.resources.forEach((resource) => {
        resource.tasks?.forEach((task) => {
          task.text_color_1 = this.getContrastYIQ(task.hex_color)

          // Check if a lane exists for the current resource
          if (!Object.prototype.hasOwnProperty.call(this.lanes, resource.id)) {
            // If a lane does not exist, set the lane number to 1
            this.$set(this.lanes, resource.id, 1)
          }

          // Assign the lane number to the task and increment the lane number
          task.lane = this.lanes[resource.id]
          this.$set(this.lanes, resource.id, this.lanes[resource.id] + 1)
        })
        this.sortTasks(resource)
      })
      this.sortTasks({ tasks: this.tasks })
    },

    getDaysInMonth(month, year) {
      return new Date(year, parseInt(month) + 1, 0).getDate()
    },
    isWeekend(day) {
      const date = new Date(this.currentYear, this.currentMonth, day)
      const dayOfWeek = date.getDay()
      return dayOfWeek === 0 || dayOfWeek === 6
    },
    isToday(day) {
      const date = new Date(this.currentYear, this.currentMonth, day)
      const today = new Date()
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      )
    },
    getMonthName(month) {
      const monthNames = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ]
      return monthNames[month]
    },

    addResource() {
      let person = this.newPerson

      Rails.ajax({
        url: '/api/resources',
        type: 'POST',
        data: `resource[people_id]=${person.id}&resource[name]=${person.name}`, // adjusted to send as form data
        success: (data) => {
          this.resources.push(data)
          this.$bvModal.hide('modal-add-person')
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        }
      })
    },

    removeResource(resourceIndex) {
      const resource = this.resources[resourceIndex]

      if (!confirm(`Tem certeza que deseja remover ${resource.name}?`)) return

      Rails.ajax({
        url: `/api/resources/${resource.id}`,
        type: 'DELETE',
        success: () => {
          this.resources.splice(resourceIndex, 1)
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        }
      })
    },

    removeResourceTask(task) {
      if (!confirm(`Tem certeza que deseja remover ${task.name}?`)) return
      let taskIndex

      let resourceIndex = -1
      for (let i = 0; i < this.resources.length; i++) {
        taskIndex = this.resources[i].tasks.findIndex(
          (t) => t.activities_resources_id === task.activities_resources_id
        )
        if (taskIndex !== -1) {
          resourceIndex = i
          break
        }
      }

      if (resourceIndex === -1) {
        console.error('Task not found in any resource')
        return
      }

      Rails.ajax({
        url: `/api/activities_resources/${task.activities_resources_id}`,
        type: 'DELETE',
        success: () => {
          this.resources[resourceIndex].tasks.splice(taskIndex, 1)
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        }
      })
    },

    updateCurrentMonthYear(year, month) {
      this.currentYear = year
      this.currentMonth = month
      localStorage.setItem('month', month)
      localStorage.setItem('year', year)
      this.debouncedHandleResize()
      this.fetchTasksAndResources()
    },

    updateActivityHexColor(newColor, task) {
      task.hex_color = newColor.hex

      let formData = new FormData()
      formData.append('activity[hex_color]', task.hex_color)

      Rails.ajax({
        url: `/api/activities/${task.activity_id}`,
        type: 'PATCH',
        dataType: 'json',
        data: formData,
        success: () => {
          //
        },
        error: (error) => {
          this.$root.toast('Erro', error, 'danger')
        }
      })
    }
  }
}
</script>

<style>
.scroll-container {
  overflow-y: auto;
}

.app-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
}

table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

th,
td {
  border: 1px solid #ccc;
  padding: 4px;
  text-align: center;
}

.task-active {
  background-color: #007bff;
}

.weekend {
  background-image: linear-gradient(0deg,
      rgba(204, 213, 222, 0.5),
      rgba(204, 213, 222, 0.5));
}

.today {
  background-image: linear-gradient(0deg,
      rgba(249, 255, 0, 0.5),
      rgba(249, 255, 0, 0.5));
}

.hovered {
  background-color: #e5e5e5;
}

.hover-boundary {
  position: relative;
}

.hover-boundary::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
}

.dragging * {
  pointer-events: none !important;
}

.white-color::-webkit-calendar-picker-indicator {
  filter: invert(100%);
  opacity: 1;
  display: block;
  width: 15px;
  height: 15px;
  border-width: thin;
}
</style>
