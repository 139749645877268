<template>
  <b-container fluid class="bg-light px-4">
    <b-row align-content="end" align-h="between" align-v="center" class="small">
      <b-button variant="link" @click="$router.back()"
        ><b-icon-arrow-left />Voltar</b-button
      >

      <div v-if="saved">
        Alterações salvas com sucesso!<b-icon-check
          class="ml-2"
          scale="2"
          variant="success"
        />
      </div>
      <div v-else class="d-flex align-items-center">
        <div class="mr-4">Salvando...</div>
        <Loading :loading="true" :small="true"></Loading>
      </div>
    </b-row>
    <form-wrapper :validator="$v.form" scope="deal">
      <!-- SIDEBAR -->
      <b-row>
        <b-col cols="12" md="4">
          <!-- ORGANIZACAO -->
          <div class="p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius">
            <form-group name="organization" class="form-group font-weight-bold">
              <template #label>
                <div class="align-items-center d-flex">
                  <b-icon-building class="mr-1" />
                  Organização
                </div>
              </template>
              <v-select
                v-model="form.organization"
                slot-scope="{ attrs, listeners }"
                class="w-100 h-auto"
                label="name"
                v-bind="attrs"
                :filterable="false"
                :clearable="false"
                :options="orgOptions"
                :placeholder="'Procure aqui...'"
                :taggable="false"
                :append-to-body="true"
                :calculate-position="dropPos"
                v-on="listeners"
                @search="getOrganizations"
                @input="setOrganization"
              >
                <template slot="no-options"> Digite para procurar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-flex flex-row align-items-center">
                    <div>
                      <b-icon-building font-scale="1.5" class="mr-2" />
                    </div>
                    <div class="flex-column">
                      <div class="font-weight-bold">{{ option.name }}</div>
                      <div>
                        {{ option.email }}
                        {{
                          option.mobile
                          | facade([
                            '####-####',
                            '#####-####',
                            '(##) ####-####',
                            '(##) #####-####'
                          ])
                        }}
                        {{
                          option.telephone
                          | facade([
                            '####-####',
                            '#####-####',
                            '(##) ####-####',
                            '(##) #####-####'
                          ])
                        }}
                      </div>
                    </div>
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }}
                  </div>
                </template>
              </v-select>
            </form-group>
            <div v-if="form.organization" class="small">
              <hr />
              <b-icon-building font-scale="2" class="w-100" />
              <FormToOrganizations
                :organization="form.organization"
                @update:localChange="localChange = $event"
              />
            </div>
          </div>
          <!-- ORGANIZACAO -->
        </b-col>
        <b-col v-if="form.organization" cols="12" md="4">
          <b-row>
            <div class="w-100 p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius">
              <div class="align-items-center d-flex font-weight-bold">
                <b-icon-person-fill class="mr-1" />
                Pessoas ({{
                  form.organization.people
                  ? form.organization.people.length
                  : 0
                }})
                <b-button
                  v-b-modal.modal-add-person
                  size="sm"
                  class="ml-auto py-0"
                  ><b-icon-plus scale="1.2"
                /></b-button>
              </div>
              <ul>
                <li
                  v-for="person in seePeople"
                  :key="person.id"
                  class="triggerRemove"
                >
                  <b-link
                    :to="{ name: 'Person View', params: { id: person.id } }"
                    >{{ person.name }}</b-link
                  >
                  <b-link
                    class="small text-danger hiddenRemove float-right"
                    @click="removePerson(person)"
                    >Remover</b-link
                  >
                </li>
              </ul>
              <b-link v-if="!seeMorePeople" @click="seeMorePeople = true"
                >Ver mais...</b-link
              >
              <b-link v-else @click="seeMorePeople = false">Reduzir...</b-link>
            </div>
          </b-row>
          <b-modal id="modal-add-person" centered hide-footer hide-header>
            <b-form-group class="form-group font-weight-bold">
              <template #label>
                <div class="align-items-center d-flex">
                  <b-icon-person-fill />
                  Contato
                </div>
              </template>
              <v-select
                v-model="newPerson"
                slot-scope="{ attrs, listeners }"
                class="w-100 h-auto"
                style="z-index: 1000"
                label="name"
                v-bind="attrs"
                :filterable="false"
                :options="peopleOptions"
                :placeholder="'Procure aqui...'"
                :taggable="false"
                :append-to-body="true"
                :calculate-position="dropPos"
                v-on="listeners"
                @search="getPeople"
                @search:blur="$refs.addPersonButton.focus()"
              >
                <template slot="no-options"> Digite para procurar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-flex flex-row align-items-center">
                    <div>
                      <b-icon-person-circle font-scale="1.5" class="mr-2" />
                    </div>
                    <div class="flex-column">
                      <div class="font-weight-bold">{{ option.name }}</div>
                      <div>
                        {{ option.email }}
                        {{
                          option.mobile
                          | facade([
                            '####-####',
                            '#####-####',
                            '(##) ####-####',
                            '(##) #####-####'
                          ])
                        }}
                        {{
                          option.telephone
                          | facade([
                            '####-####',
                            '#####-####',
                            '(##) ####-####',
                            '(##) #####-####'
                          ])
                        }}
                      </div>
                    </div>
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }}
                  </div>
                </template>
              </v-select>
            </b-form-group>
            <b-button
              ref="addPersonButton"
              block
              variant="success"
              :disabled="!saved"
              @click="addToOrg()"
              >Adicionar à organização
              <b-spinner v-if="!saved" small></b-spinner
            ></b-button>
          </b-modal>
          <b-row>
            <div class="w-100 p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius">
              <div class="align-items-center d-flex font-weight-bold">
                <b-icon-files class="mr-1" />
                Negócios ({{
                  form.organization.deals ? form.organization.deals.length : 0
                }})
              </div>
              <ul>
                <li v-for="deal in seeDeals" :key="deal.id">
                  <b-link
                    :to="{
                      name: 'Deal View',
                      params: {
                        id: deal.id
                      }
                    }"
                    >{{ deal.title }}</b-link
                  >
                </li>
              </ul>
              <b-link v-if="!seeMoreDeals" @click="seeMoreDeals = true"
                >Ver mais...</b-link
              >
              <b-link v-else @click="seeMoreDeals = false">Reduzir...</b-link>
            </div>
          </b-row>
        </b-col>
        <b-col cols="12" md="4">
          <b-col v-if="timeline">
            <b-row v-for="(note, index) in timeline" :key="index">
              <div v-if="note.spec == 'history'" class="w-100 my-2">
                <div
                  class="text-black-50 text-center"
                  style="
                    background-color: transparent;
                    border-radius: 10px;
                    border: dashed 1px lightgray;
                  "
                >
                  <span class="small text-left d-block font-italic">
                    {{ niceMoment(note.created_at) }} por {{ note.actor }}
                  </span>
                  Atualizou
                  <span class="font-italic font-weight-bold">{{
                    note.attribute
                  }}</span>
                  de ({{ note.name[0] }}) para ({{ note.name[1] }})
                </div>
              </div>
              <div v-else>
                {{ JSON.stringify(note) }}
              </div>
            </b-row>
          </b-col>
          <b-col v-else>
            <hr />
            <div class="w-100 text-center text-black-50">
              linha do tempo vazia
            </div>
            <hr />
          </b-col>
        </b-col>
      </b-row>
      <!-- SIDEBAR -->
    </form-wrapper>
    <go-top
      :size="35"
      :right="15"
      :bottom="25"
      fg-color="#000000"
      bg-color="#eaeaea"
      weight="bold"
    ></go-top>
  </b-container>
</template>

<script>
import Loading from './Loading.vue'

import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'

import FormToOrganizations from './FormToOrganizations.vue'

import { filter } from 'vue-input-facade'

import GoTop from '@inotom/vue-go-top'

export default {
  filters: { facade: filter },
  components: {
    Loading,
    FormToOrganizations,
    GoTop
  },
  data() {
    return {
      saved: true,
      unwatchers: [],
      orgOptions: [],
      peopleOptions: [],
      createdAt: null,
      updatedAt: null,
      creatorId: null,
      lastUpdateById: null,
      seeingOrg: null,
      localChange: null,
      form: {
        organization: null
      },
      timeline: null,
      newPerson: null,
      seeMorePeople: false,
      seeMoreDeals: false,
      upHere: false
    }
  },

  validations: {
    form: {
      organization: {}
    }
  },

  computed: {
    // GET DEAL FROM PARAMS NOT FROM PROP
    paramID: function () {
      return parseInt(this.$route.params.id)
    },
    seePeople: function () {
      if (!this.form.organization) return
      if (!this.form.organization.people) return
      if (this.seeMorePeople) {
        return this.form.organization.people
      }
      return this.form.organization.people.slice(0, 10)
    },
    seeDeals: function () {
      if (!this.form.organization) return
      if (!this.form.organization.deals) return
      if (this.seeMoreDeals) {
        return this.form.organization.deals
      }
      return this.form.organization.deals.slice(0, 10)
    }
  },

  watch: {
    '$store.state.needToUpdate': {
      immediate: true,
      deep: true,
      handler(value) {
        if (isEmpty(value)) return
        console.log('localchange: ', this.localChange)
        if (this.localChange == true) {
          this.localChange = false
          return
        }

        if (value[0] == 'organization') {
          this.fetchOrganization(this.seeingOrg.id)
          this.fetchTimeline(this.seeingOrg.id)
        }

        this.$store.state.needToUpdate = []
      }
    },
    paramID() {
      this.startTheFetch()
    }
  },

  created() {
    this.installWatchers()
    this.startTheFetch()
  },

  destroyed() {
    this.$store.state.seeingOrg = {}
  },

  methods: {
    startTheFetch() {
      let vm = this
      this.fetchOrganization(this.paramID).then(function (data) {
        vm.$store.state.seeingOrg = data
        vm.fetchTimeline(data.id)
        vm.form.organization = data
        vm.seeingOrg = data
        vm.localChange = false
        if (!isEmpty(vm.seeingOrg)) vm.fillFields(vm.seeingOrg)
      })
    },
    dropPos(dropdownList, component, { width, top, left }) {
      dropdownList.style.zIndex = 1100
      if (component.options.length > 0) {
        dropdownList.style.top = top
        dropdownList.style.left = 0
        dropdownList.style.width = document.body.clientWidth
      } else {
        dropdownList.style.top = top
        dropdownList.style.left = left
        dropdownList.style.width = width
      }
    },
    // por enquanto handlechange nao faz nada, mas depois temos q arrumar pro timeline
    handleChange(newVal, oldVal, key) {
      console.log(newVal)
      if (newVal) return
      console.log('changed before! === ', key)

      if (this.localChange == false) return

      console.log('changed after! === ', key)

      // check form any changes on form
      if (!this.$v.$anyDirty) return

      // check specific changed field if valid
      if (this.$v.form[key].$invalid) return

      this.saved = false

      var fd = new FormData()
      var isMove = ''

      switch (key) {
        case 'status':
          fd.append('deal[deal_status_id]', this.entity.deal.deal_status_id)
          break

        case 'stage':
          fd.append('deal[deal_stage_id]', this.entity.deal.deal_stage_id)
          isMove = '/move'
          break

        case 'owner':
          fd.append('deal[owner_id]', this.entity.deal.owner_id)
          break

        case 'organization':
          fd.append('deal[organization_id]', this.entity.deal.organization_id)
          break

        case 'customer':
          fd.append(
            'deal[customer_person_id]',
            this.entity.deal.customer_person_id
          )
          break

        case 'contact':
          fd.append(
            'deal[contact_person_id]',
            this.entity.deal.contact_person_id
          )
          break

        default:
          fd.append('deal[' + key + ']', newVal)
          break
      }

      // console.log(fd)

      Rails.ajax({
        //beforeSend: () => true,
        url: `/api/organizations/${this.seeingOrg.id}${isMove}`,
        type: 'PATCH',
        data: fd,
        dataType: 'json',
        success: (data) => {
          console.log(data)
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    removePerson(person) {
      let h = this.$createElement
      let title = h('p', [
        'Desconectar ',
        [h('strong', person.name)],
        ' de ',
        [h('strong', this.seeingOrg.name)],
        ' ?'
      ])
      this.$bvModal
        .msgBoxConfirm(title, {
          title: 'Remover',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SIM',
          cancelTitle: 'NÃO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        .then((value) => {
          if (value) {
            this.patchPerson(person, '')
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },

    patchPerson(person, option) {
      console.log(person)

      var fd = new FormData()
      fd.append('person[organization_id]', option)

      Rails.ajax({
        url: `/api/people/${person.id}`,
        type: 'PATCH',
        data: fd,
        dataType: 'json',
        success: (data) => {
          console.log(data)
          this.$bvModal.hide('modal-add-person')
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    addToOrg() {
      this.patchPerson(this.newPerson, this.seeingOrg.id)
    },

    installWatchers() {
      this.unwatchers = []
      for (let key in this.form) {
        let unwatch = this.$watch(
          'form.' + key,
          (newVal, oldVal) => this.handleChange(newVal, oldVal, key),
          {
            deep: true
          }
        )
        this.unwatchers.push(unwatch)
      }
    },

    async fetchOrganization(id) {
      const response = new Promise(function (resolve, reject) {
        if (id) {
          Rails.ajax({
            url: `/api/organizations/${id}?include=people,deals`,
            type: 'GET',
            dataType: 'json',
            success: (data) => {
              console.log(data)
              resolve(data)
            },
            error: (data) => {
              this.$root.toast('Erro', data, 'danger'), reject(data)
            },
            complete: (data) => resolve(data)
          })
        }
      })
      this.form.organization = await response
      return response
    },

    async fillFields(deal) {
      console.log(deal)
      console.log('fill')
      this.form.organization = deal
      this.createdAt = new Date(deal.created_at).toLocaleString()
      this.updatedAt = new Date(deal.updated_at).toLocaleString()
    },

    getOrganizations(search, loading) {
      loading(true)
      if (search.length > 2) {
        this.searchOrgs(loading, search, this)
      } else {
        loading(false)
      }
    },

    searchOrgs: debounce((loading, search, vm) => {
      Rails.ajax({
        url: `/api/organizations.json?name=${encodeURI(search)}`,
        type: 'GET',
        //data: fd,
        dataType: 'json',
        success: (data) => {
          console.log(data)
          vm.orgOptions = data
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        },
        complete: () => {
          loading(false)
        }
      })
    }, 350),

    setOrganization(obj) {
      console.log('set : ', obj)
      this.$router.push({
        name: 'Org View',
        params: {
          id: obj.id
        }
      })
    },

    getPeople(search, loading) {
      loading(true)
      if (search.length > 2) {
        this.searchPeople(loading, search, this)
      } else {
        loading(false)
      }
    },

    searchPeople: debounce((loading, search, vm) => {
      Rails.ajax({
        url: `/api/people.json?name=${encodeURI(search)}`,
        type: 'GET',
        //data: fd,
        dataType: 'json',
        success: (data) => {
          console.log(data)
          vm.peopleOptions = data
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        },
        complete: () => {
          loading(false)
        }
      })
    }, 350),

    fetchTimeline(id) {
      Rails.ajax({
        url: `/api/org_timeline/${id}`,
        type: 'GET',
        dataType: 'json',
        success: (data) => {
          if (data) {
            this.timeline = data.length > 0 ? data : null
          }
        },
        error: (data) => {
          this.$root.toast('Erro', data, 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    niceMoment(date) {
      //format('ddd LLL')
      return this.moment(date).calendar(null, {
        sameDay: null,
        nextDay: null,
        nextWeek: null,
        lastDay: null,
        lastWeek: null,
        sameElse: 'ddd LLL'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}

.big-radius {
  border-radius: 12px;
}

input {
  background-color: white;
}

.v-select {
  background-color: white;
}

.hiddenRemove {
  display: none;
}

.triggerRemove:hover>.hiddenRemove {
  display: inline;
}
</style>