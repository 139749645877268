/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
//= require jquery
//= require jquery_ujs


require("@rails/ujs")
require("turbolinks").start()
require("channels")


Rails.start()

document.addEventListener("turbolinks:load", () => {
  $('.tooltip').tooltip()
  $('[rel="tooltip"]').tooltip()
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  $('[data-toggle="dropdown"]').dropdown()
  $('.toast').toast({ autohide: false })
  $('#toast').toast('show')
})

import flatMap from 'lodash/flatMap'
import map from 'lodash/map'
import find from 'lodash/find'
import filter from 'lodash/filter'


import Vue from 'vue/dist/vue.esm'

import Vuex from 'vuex'
Vue.use(Vuex)

import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)

import TurbolinksAdapter from 'vue-turbolinks'
Vue.use(TurbolinksAdapter)

import router from '../router'

// Install BootstrapVue
import "bootstrap"
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import "tabulator-tables/dist/css/bootstrap/tabulator_bootstrap4.min.css"


import 'vue-select/dist/vue-select.css';

import moment from 'moment'
import 'moment/locale/pt-br'

moment.locale('pt-br')

Vue.prototype.moment = moment

window.store = new Vuex.Store({
  state: {
    lists: [],
    seeingDeal: {},
    seeingOrg: {},
    seeingPerson: {},
    statuses: [],
    industries: [],
    activity_types: [],
    lastData: [],
    user: null,
    usersEmailsList: [],
    needToUpdate: [],
    pipes: [],
    on_pipe: null,
    on_status: null
  },

  mutations: {
    addList(state, data) {
      state.lists.push(data)
      // update the order of position
      state.lists.forEach(function (list, i) {
        list.position = i + 1
      })
    },

    // this only take care to remove list and moveRemainingDeals re-manage deals
    deleteList(state, data) {
      const index = state.lists.findIndex(item => item.id == data.id)
      state.lists.splice(index, 1)
      // update the order of position
      state.lists.forEach(function (list, i) {
        list.position = i + 1
      })
    },

    moveList(state, data) {
      const index = state.lists.findIndex(item => item.id == data.id)
      state.lists.splice(index, 1)
      state.lists.splice(data.position - 1, 0, data)
      // update the order of position
      state.lists.forEach(function (list, i) {
        list.position = i + 1
      })
    },

    moveRemainingDeals(state, data) {
      const index = state.lists.findIndex(item => item.id == data.stage_id)
      data.deals.forEach(function (item) {
        // TODO: improve to check this, to check as string is bad
        if (find(state.statuses, { id: item.deal_status_id }).name !== "Em aberto") return
        item.deal_stage_id = data.stage_id
        state.lists[index].deals.push(item)
      })
      // update the order of position
      state.lists[index].deals.forEach(function (deal, i) {
        deal.position = i + 1
      })

    },

    addCard(state, data) {
      // if card is not 'Em aberto'
      // TODO: improve to check this, to check as string is bad
      if (find(state.statuses, { id: data.deal_status_id }).name !== "Em aberto") return
      const index = state.lists.findIndex(item => item.id == data.deal_stage_id)
      state.lists[index].deals.push(data)
      // update the order of position
      state.lists[index].deals.forEach(function (deal, i) {
        deal.position = i + 1
      })
    },

    editCard(state, data) {

      // if editing another card (not a rendered card, ex lost deal)
      if (data.id == state.seeingDeal.id) {
        state.seeingDeal = data
      }

      // if seeingorg when deal changed...
      if (find(state.seeingOrg.deals, { id: data.id })) {
        state.needToUpdate.push('organization')
      }

      if (find(state.seeingPerson.deals, { id: data.id })) {
        state.needToUpdate.push('person')
      }

      const list_index = state.lists.findIndex((item) => item.id == data.deal_stage_id)
      
      // update timeline
      state.needToUpdate.push('timeline')

      if (list_index >= 0) {
        const card_index = state.lists[list_index].deals.findIndex((item) => item.id == data.id)


        // if stage is not 'Em aberto'
        // TODO: improve to check this, to check as string is bad
        if (find(state.statuses, { id: data.deal_status_id }).name !== "Em aberto") {
          state.lists[list_index].deals.splice(card_index, 1)
          return
        }

        if (card_index >= 0) {
          console.log(data.active);
          if (!data.active) {
            state.lists[list_index].deals.splice(card_index, 1)
            return
          }
          // repeated code
          if (data.archived) {
            state.lists[list_index].deals.splice(card_index, 1)
            return
          }
          state.lists[list_index].deals.splice(card_index, 1, data)
        } else {
          if (data.archived) {
            return
          }
          state.lists[list_index].deals.push(data)
        }

        // update the order of position
        state.lists[list_index].deals.forEach(function (deal, i) {
          deal.position = i + 1
        })
      } else {
        this.commit('moveCard', data)
      }
    },

    moveCard(state, data) {
      // to avoid updating when drag card doesnt change position and remains at the same place
      // works together with muuri options
      state.lastData = data

      // if editing
      if (data.id == state.seeingDeal.id) {
        state.seeingDeal = data
      }

      // update timeline
      state.needToUpdate.push('timeline')

      // if is not 'Em aberto' dont add/remove anything
      // TODO: improve to check this, to check as string is bad
      // if (find(state.statuses, { id: data.deal_status_id }).name !== "Em aberto") return

      const old_list_index = state.lists.findIndex(list => {
        return list.deals.find(card => {
          return card.id === data.id
        })
      })

      let old_card_index = null

      if (old_list_index >= 0) {
        old_card_index = state.lists[old_list_index].deals.findIndex(item => item.id == data.id)
      }
      const new_list_index = state.lists.findIndex(item => item.id == data.deal_stage_id)

      // mudou de funil , ou, está editando um cartão que não está no atual pipeview
      if (new_list_index < 0) {
        if (old_list_index >= 0) {
          // acha o card no funil atual e remove, e não continua
          const card_index = state.lists[old_list_index].deals.findIndex(item => item.id == data.id)
          if (card_index >= 0) {
            state.lists[old_list_index].deals.splice(card_index, 1)
          }
        }
        return
      }

      if (old_list_index != new_list_index) {
        // Remove card from old list, add to new one
        if (old_card_index >= 0) state.lists[old_list_index].deals.splice(old_card_index, 1)
        state.lists[new_list_index].deals.splice(data.position - 1, 0, data)
      } else {
        if (old_card_index >= 0) state.lists[new_list_index].deals.splice(old_card_index, 1)
        state.lists[new_list_index].deals.splice(data.position - 1, 0, data)
      }

      // update the order of position
      state.lists[old_list_index].deals.forEach(function (deal, i) {
        deal.position = i + 1
      })
      state.lists[new_list_index].deals.forEach(function (deal, i) {
        deal.position = i + 1
      })

    },

    editOrganization(state, data) {
      if (data.id == state.seeingDeal.organization_id) {
        state.needToUpdate.push('organization')
      }
      if (data.id == state.seeingOrg.id) {
        state.needToUpdate.push('organization')
      }
    },

    editPeople(state, data) {
      // TODO: fix to when updates both fields needed
      if (data.id == state.seeingDeal.contact_person_id || data.id == state.seeingDeal.customer_person_id) {
        state.needToUpdate.push('person')
      }

      if (find(state.seeingOrg.people, { id: data.id }) || data.organization_id == state.seeingOrg.id) {
        state.needToUpdate.push('organization')
      }


      if (data.id == state.seeingPerson.id) {
        state.needToUpdate.push('person')
      }

    },

    updateTimeline(state, data) {
      state.needToUpdate = []
      state.needToUpdate.push('timeline')
    }

  },

  getters: {
    deals: state => {
      return flatMap(state.lists, 'deals')
    },
    statuses: state => {
      return map(state.statuses, status => ({ id: status.id, name: status.name }))
    },
    industries: state => {
      return map(state.industries, industry => ({ id: industry.id, name: industry.name }))
    },
    activity_types: state => {
      return map(state.activity_types, act => ({ id: act.id, name: act.name, how_much_time_default: act.how_much_time_default }))
    },
    stages_per_deal_stage: (state) => (deal_stage_id) => {
      let pipe = find(state.pipes, { deal_stages: [{ id: deal_stage_id }] })
      return map(filter(pipe.deal_stages, { active: true }), deal_stage => ({ id: deal_stage.id, name: deal_stage.name, position: deal_stage.position, hex_color: deal_stage.hex_color }))
    },
    stages: (state) => {
      return state.pipes.reduce(function (r, v, k) {
        r = r.concat(v.deal_stages)
        return r
      }, [])
    }
  }

})


import App from '../app.vue'
import './vuelidate/validation'
import vSelect from 'vue-select'
import i18n from './i18n'
Vue.component('VSelect', vSelect)

import VueMeta from 'vue-meta'

Vue.use(VueMeta)

import toastMixin from './toastMixin.js'

document.addEventListener("turbolinks:load", function () {

  // const app = new Vue({
  //   el: '[data-behavior="vue"]',
  // })

  var element = document.querySelector("#boards")
  if (element != undefined) {
    window.store.state.lists = JSON.parse(element.dataset.lists)
    window.store.state.statuses = JSON.parse(element.dataset.statuses)
    window.store.state.industries = JSON.parse(element.dataset.industries)
    window.store.state.activity_types = JSON.parse(element.dataset.activitytypes)
    window.store.state.pipes = JSON.parse(element.dataset.pipes)
    window.store.state.on_pipe = JSON.parse(element.dataset.onpipe)
    window.store.state.on_status = JSON.parse(element.dataset.onstatus)

    const app = new Vue({
      el: element,
      store: window.store,
      components: { App },
      mixins: [toastMixin],
      template: "<App />",
      router,
      i18n
    })
  }
});