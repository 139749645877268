import { render, staticRenderFns } from "./OrgView.vue?vue&type=template&id=0aaa5531&scoped=true&"
import script from "./OrgView.vue?vue&type=script&lang=js&"
export * from "./OrgView.vue?vue&type=script&lang=js&"
import style0 from "./OrgView.vue?vue&type=style&index=0&id=0aaa5531&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0aaa5531",
  null
  
)

export default component.exports