<template>
  <div
    class="d-flex align-items-center w-100 justify-content-center mt-5 pt-5 h1 text-center"
  >
    <br /><b-icon-emoji-frown /> 404 ERROR <br />
    SEM RESULTADOS <br /><b-icon-emoji-frown />
    <router-link :to="{ name: 'Home' }"
      ><span>Ir para página principal</span></router-link
    >
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
</style>