var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{class:{
    'position-relative mt-4': true,
    row: _vm.newForm ? _vm.newForm : false
  },attrs:{"fluid":!_vm.newForm}},[(!_vm.newForm)?_c('div',{staticClass:"position-absolute",staticStyle:{"right":"15px","top":"-20px"}},[(_vm.saved)?_c('div',[_vm._v("\n      Salvo! "),_c('b-icon-check',{staticClass:"ml-2",attrs:{"scale":"2","variant":"success"}})],1):_c('div',[_c('Loading',{staticClass:"mt-2",attrs:{"loading":true,"small":true}})],1)]):_vm._e(),_vm._v(" "),(!_vm.newForm)?_c('div',[(_vm.person.organization)?_c('div',{staticClass:"mb-4"},[_vm._v("\n      Pertence a organização:"),_c('br'),_vm._v(" "),_c('b-link',{attrs:{"to":{ name: 'Org View', params: { id: _vm.person.organization.id } }}},[_vm._v(_vm._s(_vm.person.organization.name))])],1):_c('div',{staticClass:"mb-4"},[_vm._v("Não adicionado a nenhuma organização.")])]):_vm._e(),_vm._v(" "),_c('form-wrapper',{class:{ 'col-12 col-md-7 mb-4': _vm.newForm ? _vm.newForm : false },attrs:{"validator":_vm.$v.form,"scope":"people"}},[_c('b-alert',{staticClass:"text-black-50 font-weight-bold mx-n4",attrs:{"show":!_vm.form.active && !_vm.newForm,"variant":"dark"}},[_vm._v("\n      Pessoa Deletada\n      "),_c('b-button',{staticClass:"float-right m-0 p-0 w-25",attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.$v.$touch()
          _vm.emitLocalChange()
          _vm.form.active = true}}},[_vm._v("Re-ativar")])],1),_vm._v(" "),_c('b-form-row',{staticClass:"mb-2"},[_c('b-col',[_c('form-group',{staticClass:"form-group new-legend",attrs:{"label":"Nome","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var attrs = ref.attrs;
          var listeners = ref.listeners;
return _c('b-form-input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"value":_vm.form.name,"name":"name","type":"text","autocomplete":"off"},on:{"change":function($event){_vm.emitLocalChange()
              _vm.form.name = $event
              _vm.searchSimilar($event)}}},'b-form-input',attrs,false),listeners))}}])})],1)],1),_vm._v(" "),_c('b-form-row',{staticClass:"mb-2"},[_c('b-col',[_c('form-group',{staticClass:"form-group new-legend",attrs:{"label":"Telefone","name":"telephone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var attrs = ref.attrs;
              var listeners = ref.listeners;
return _c('input-facade',_vm._g(_vm._b({staticClass:"form-control",attrs:{"mask":[
              '####-####',
              '#####-####',
              '(##) ####-####',
              '(##) #####-####'
            ],"masked":true,"name":"telephone","lazy":true,"type":"text","autocomplete":"off"},on:{"change":function($event){_vm.emitLocalChange()
              _vm.form.telephone = $event
              _vm.searchSimilar($event)}},model:{value:(_vm.form.telephone),callback:function ($$v) {_vm.$set(_vm.form, "telephone", $$v)},expression:"form.telephone"}},'input-facade',attrs,false),listeners))}}])})],1),_vm._v(" "),_c('b-col',[_c('form-group',{staticClass:"form-group new-legend",attrs:{"label":"Celular","name":"mobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var attrs = ref.attrs;
              var listeners = ref.listeners;
return _c('input-facade',_vm._g(_vm._b({staticClass:"form-control",attrs:{"mask":[
              '####-####',
              '#####-####',
              '(##) ####-####',
              '(##) #####-####'
            ],"masked":true,"name":"mobile","lazy":true,"type":"text","autocomplete":"off"},on:{"change":function($event){_vm.emitLocalChange()
              _vm.form.mobile = $event
              _vm.searchSimilar($event)}},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}},'input-facade',attrs,false),listeners))}}])})],1)],1),_vm._v(" "),_c('b-form-row',{staticClass:"mb-2"},[_c('b-col',[_c('form-group',{staticClass:"form-group new-legend",attrs:{"label":"E-mail","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var attrs = ref.attrs;
              var listeners = ref.listeners;
return _c('b-form-input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"value":_vm.form.email,"name":"email","type":"text","autocomplete":"off"},on:{"change":function($event){_vm.emitLocalChange()
              _vm.form.email = $event
              _vm.searchSimilar($event)}}},'b-form-input',attrs,false),listeners))}}])})],1)],1),_vm._v(" "),_c('b-form-row',{staticClass:"mb-2"},[_c('b-col',[_c('form-group',{staticClass:"form-group new-legend",attrs:{"label":"Dados bancários","name":"bank_details"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var attrs = ref.attrs;
              var listeners = ref.listeners;
return _c('b-form-input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"value":_vm.form.bank_details,"name":"bank_details","type":"text","autocomplete":"off"},on:{"change":function($event){_vm.emitLocalChange()
              _vm.form.bank_details = $event}}},'b-form-input',attrs,false),listeners))}}])})],1)],1),_vm._v(" "),_c('b-form-row',{staticClass:"mb-2"},[_c('b-col',[_c('form-group',{staticClass:"form-group new-legend",attrs:{"label":"RG","name":"rg"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var attrs = ref.attrs;
              var listeners = ref.listeners;
return _c('input-facade',_vm._g(_vm._b({staticClass:"form-control",attrs:{"mask":['##.###.###-#', '#.###.###-#'],"masked":true,"name":"rg","lazy":true,"type":"text","autocomplete":"off"},on:{"change":function($event){_vm.emitLocalChange()
              _vm.form.rg = $event}},model:{value:(_vm.form.rg),callback:function ($$v) {_vm.$set(_vm.form, "rg", $$v)},expression:"form.rg"}},'input-facade',attrs,false),listeners))}}])})],1)],1),_vm._v(" "),_c('b-form-row',{staticClass:"mb-2"},[_c('b-col',[_c('form-group',{staticClass:"form-group new-legend",attrs:{"label":"CPF ou CNPJ","name":"cpf_cnpj"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var attrs = ref.attrs;
              var listeners = ref.listeners;
return _c('input-facade',_vm._g(_vm._b({staticClass:"form-control",attrs:{"mask":['###.###.###-##', '##.###.###/####-##'],"masked":true,"name":"cpf_cnpj","lazy":true,"type":"text","autocomplete":"off"},on:{"change":function($event){_vm.emitLocalChange()
              _vm.form.cpf_cnpj = $event}},model:{value:(_vm.form.cpf_cnpj),callback:function ($$v) {_vm.$set(_vm.form, "cpf_cnpj", $$v)},expression:"form.cpf_cnpj"}},'input-facade',attrs,false),listeners))}}])})],1)],1),_vm._v(" "),_c('b-form-row',{staticClass:"mb-2"},[_c('b-col',[_c('form-group',{staticClass:"form-group new-legend",attrs:{"label":"Data de nascimento","name":"date_of_birth"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var attrs = ref.attrs;
              var listeners = ref.listeners;
return _c('input-facade',_vm._g(_vm._b({staticClass:"form-control",attrs:{"mask":'##/##/####',"masked":true,"name":"date_of_birth","lazy":true,"type":"text","autocomplete":"off"},on:{"change":function($event){_vm.emitLocalChange()
              _vm.form.date_of_birth = $event}},model:{value:(_vm.form.date_of_birth),callback:function ($$v) {_vm.$set(_vm.form, "date_of_birth", $$v)},expression:"form.date_of_birth"}},'input-facade',attrs,false),listeners))}}])})],1)],1),_vm._v(" "),_c('b-form-row',{staticClass:"mb-2"},[_c('b-col',[_c('form-group',{staticClass:"form-group new-legend",attrs:{"label":"Endereço","name":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var attrs = ref.attrs;
              var listeners = ref.listeners;
return _c('b-form-input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"value":_vm.form.address,"name":"address","type":"text","autocomplete":"off"},on:{"change":function($event){_vm.emitLocalChange()
              _vm.form.address = $event}}},'b-form-input',attrs,false),listeners))}}])})],1)],1),_vm._v(" "),_c('b-form-row',{staticClass:"mb-2"},[_c('b-col',[_c('form-group',{staticClass:"form-group new-legend",attrs:{"label":"Observações","name":"observations"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var attrs = ref.attrs;
              var listeners = ref.listeners;
return _c('b-form-textarea',_vm._g(_vm._b({staticClass:"form-control",attrs:{"rows":"4","max-rows":"10","value":_vm.form.observations,"name":"observations","type":"text","autocomplete":"off"},on:{"change":function($event){_vm.emitLocalChange()
              _vm.form.observations = $event}}},'b-form-textarea',attrs,false),listeners))}}])})],1)],1),_vm._v(" "),(!_vm.newForm)?_c('b-row',{staticClass:"small",attrs:{"align-h":"end","align-content":"end"}},[_vm._v("\n      ID: "+_vm._s(_vm.form.id)),_c('br'),_vm._v("\n      Criado em: "+_vm._s(_vm.moment(_vm.form.created_at).format('ddd LLL'))),_c('br'),_vm._v("\n      Atualizado em:\n      "+_vm._s(_vm.moment(_vm.form.updated_at).format('ddd LLL'))+"\n    ")]):_c('b-row',{staticClass:"small",attrs:{"align-h":"center","align-content":"center"}},[_c('b-button',{attrs:{"block":"","variant":"success","disabled":!_vm.saved},on:{"click":_vm.submit}},[_vm._v("Salvar "),(!_vm.saved)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1),_vm._v(" "),_c('b-row',{staticClass:"mt-4"},[(_vm.form.active && _vm.$route.name == 'Person View')?_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){_vm.$v.$touch()
          _vm.emitLocalChange()
          _vm.form.active = false}}},[_vm._v("Deletar")]):_vm._e()],1)],1),_vm._v(" "),(_vm.newForm)?_c('b-col',{staticClass:"mt-n4",attrs:{"cols":"auto","md":"5"}},[(_vm.similarsView)?_c('div',[_c('span',{staticClass:"small"},[_vm._v("Similares")]),_vm._v(" "),_vm._l((_vm.similarsView),function(s){return _c('div',{key:s.id + new Date().getTime(),staticClass:"border border-bottom small p-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(s.title || s.name))]),_vm._v("\n        (ID: "+_vm._s(s.id)+")"),_c('br'),_vm._v("\n        "+_vm._s(s.telephone || '-')),_c('br'),_vm._v("\n        "+_vm._s(s.mobile || '-')),_c('br'),_vm._v("\n        "+_vm._s(s.email || '-')+"\n      ")])})],2):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }