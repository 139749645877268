import Vue from 'vue'
import Router from 'vue-router'

import Home from './components/Home.vue'
import DealView from './components/DealView.vue'
import DealNew from './components/DealNew.vue'
import OrgView from './components/OrgView.vue'
import PersonView from './components/PersonView.vue'
import TabulatorView from './components/TabulatorView.vue'
import CronogramaView from './components/CronogramaView.vue'

import NotFound from './components/NotFound.vue'

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/Home',
            name: 'Home',
            component: Home,
            alias: '/'
        },
        {
            path: '/deals/:id(\\d+)',
            name: 'Deal View',
            component: DealView,
            props: true
        },
        {
            path: '/deals/new',
            name: 'Deal New',
            component: DealNew,
            props: true
        },
        {
            path: '/organizations/:id(\\d+)',
            name: 'Org View',
            component: OrgView,
            props: true
        },
        {
            path: '/people/:id(\\d+)',
            name: 'Person View',
            component: PersonView,
            props: true
        },
        {
            path: '/tabulator',
            name: 'Tabulator',
            component: TabulatorView,
            props: true
        },
        {
            path: '/cronograma',
            name: 'Cronograma',
            component: CronogramaView,
            props: true
        },
        {
            name: '404',
            path: '/404',
            alias: '*',
            component: NotFound
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        // SAVEDPOSITION OR ALWAYS WILL SCROLL TO TOP
        return new Promise(resolve =>
            setTimeout(() => {
                if (savedPosition) {
                    return resolve(savedPosition);
                }
                resolve({ x: 0, y: 0 })
            }, 150) // exactly the time of animation transition (it bugs if emit to transition with children routes)
        )
    }

})

Vue.use(Router)

export default router