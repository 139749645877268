<!--

TODO:
- LOADING IS A MESS

-->

<template>
  <b-container fluid class="bg-light px-4">
    <b-row
      align-content="end"
      align-h="between"
      align-v="center"
      class="small"
      no-gutters
    >
      <b-button variant="link" @click="$router.back()"
        ><b-icon-arrow-left />Voltar</b-button
      >
      <div v-if="saved">
        Alterações salvas com sucesso!<b-icon-check
          class="ml-2"
          scale="2"
          variant="success"
        />
      </div>
      <div v-else class="d-flex align-items-center">
        <div class="mr-4">Salvando...</div>
        <Loading :loading="true" :small="true"></Loading>
      </div>
    </b-row>
    <form-wrapper :validator="$v.form" scope="deal">
      <div
        :class="[
          'bg-white px-3 big-radius shadow-1 border-bottom',
          { 'border border-success': form.status && form.status.name == 'Ganho' },
          { 'border border-danger': form.status && form.status.name == 'Perdido' }
        ]"
      >
        <b-alert
          v-if="!loading"
          :show="!form.active"
          variant="dark"
          class="text-black-50 font-weight-bold m-0 mx-n3"
        >
          Negócio Deletado
          <b-button
            class="float-right m-0 p-0 w-25"
            variant="outline-secondary"
            @click="
              $v.form.$touch()
            localChangeEmitter()
            form.active = true
              "
            >Re-ativar</b-button
          >
        </b-alert>
        <b-alert
          :show="form.archived"
          variant="warning"
          class="h3 text-center text-uppercase font-weight-bold m-0 mx-n3"
          style="border: 5px dashed #d9d626;"
        >
          Negócio Arquivado
        </b-alert>        
        <!-- TITULO -->
        <b-form-row>
          <b-col class="p-0">
            <form-group name="title" class="form-group">
              <b-form-input
                ref="title"
                slot-scope="{ attrs, listeners }"
                :value="form.title"
                v-bind="attrs"
                :class="{
                  'form-control edit-title': true,
                  'no-valid-vuelidate': () => avoidValidClass()
                }"
                name="title"
                type="text"
                autocomplete="off"
                @change="
                  localChangeEmitter()
                checkTitleReady($event)
                  "
                v-on="listeners"
              ></b-form-input>
            </form-group>
          </b-col>
        </b-form-row>
        <!-- TITULO -->
        <!-- FUNIL -->
        <b-form-row v-if="form.stage" class="mb-2">
          <div class="d-flex mw-100 w-100 overflow-auto nice-scroll">
            <b-progress
              :max="stages.length"
              height="2rem"
              class="d-flex mw-100 w-100 overflow-auto"
            >
              <b-progress-bar
                v-for="stage in stages"
                :key="stage.id"
                :value="1"
                :variant="form.stage.position >= stage.position
                  ? 'primary'
                  : 'secondary'
                  "
                :class="{
                  small: true,
                  'active-stage': form.stage.id === stage.id
                }"
              >
                <div
                  class="d-flex align-items-center pl-2 cursor-pointer"
                  @click="
                    $v.form.$touch()
                  localChangeEmitter()
                  form.stage = stage
                    "
                >
                  <div class="stage-progress w-100" :title="stage.name">
                    {{ stage.name }}
                  </div>
                  <div class="arrow-right"></div>
                </div>
                <div
                  :style="{
                    width: '100%',
                    minHeight: '5px',
                    position: 'relative',
                    bottom: '5px',
                    backgroundColor: stage.hex_color
                  }"
                ></div>
              </b-progress-bar>
            </b-progress>
          </div>
        </b-form-row>
        <!-- FUNIL -->
      </div>
      <hr />
      <b-row>
        <!-- START SIDEBAR -->
        <b-col cols="12" md="4">
          <!-- DETALHES -->
          <div class="p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius">
            <span class="font-weight-bold"
              ><b-icon-pen class="mr-1" /> Detalhes:</span
            >
            <hr class="my-1" />
            <form-group
              name="value"
              label="Valor"
              class="form-group align-items-center"
              :prepend="'R$'"
            >
              <CurrencyInput
                slot-scope="{ attrs, listeners }"
                :value="form.value"
                class="form-control"
                :currency="null"
                :distraction-free="false"
                :auto-decimal-mode="true"
                locale="pt-BR"
                v-bind="attrs"
                @change="
                  localChangeEmitter()
                form.value = $event
                  "
                v-on="listeners"
              ></CurrencyInput>
            </form-group>
                <form-group
                  name="status"
                  label="Status"
                  class="form-group align-items-center"
                >
                  <b-row
                    slot-scope="{ attrs, listeners }"
                    align-h="between"
                    no-gutters
                    class="w-100"
                    v-bind="attrs"
                    v-on="listeners"
                  >
                    <b-button
                      v-for="(status, i) in statuses"
                      :key="status.name + i"
                      pill
                      size="sm"
                      :variant="statusVariant(status.name)"
                      :pressed="form.status == status"
                      @click="
                        $v.form.$touch()
                      localChangeEmitter()
                      form.status = status
                        "
                      >{{ status.name }}</b-button
                    >
                  </b-row>
                </form-group>
                <b-row>
              <b-col>
                <form-group name="archived" class="form-group align-items-center p-0 m-0">
                  <b-row
                    slot-scope="{ attrs, listeners }"
                    align-h="end"
                    no-gutters
                    class="w-100"
                    v-bind="attrs"
                    v-on="listeners">
                    <b-form-checkbox
                      size="sm"
                      :checked="form.archived"
                      @input="
                        $v.form.$touch()
                      localChangeEmitter()
                      form.archived = $event
                        "
                      ><span class="go-cursor-pointer">Arquivar ?</span>
                    </b-form-checkbox>
                  </b-row>
                </form-group>
              </b-col>
            </b-row>
            <form-group
              name="owner"
              label="Proprietário"
              class="form-group align-items-center"
            >
              <v-select
                v-model="form.owner"
                slot-scope="{ attrs, listeners }"
                :options="owners"
                class="w-100"
                label="email"
                v-bind="attrs"
                :clearable="false"
                @input="localChangeEmitter"
                v-on="listeners"
              ></v-select>
            </form-group>
            <form-group
              name="pipe"
              label="Alterar funil"
              class="form-group align-items-center"
            >
              <v-select
                v-model="form.pipe"
                slot-scope="{ attrs, listeners }"
                :options="pipes"
                class="w-100"
                label="name"
                v-bind="attrs"
                :clearable="false"
                @input="localChangeEmitter"
                v-on="listeners"
              ></v-select>
            </form-group>
          </div>
          <!-- DETALHES -->
          <!-- ORGANIZACAO -->
          <div class="p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius">
            <form-group name="organization" class="form-group font-weight-bold">
              <template #label>
                <div class="align-items-center d-flex">
                  <b-icon-building class="mr-1" />
                  Organização
                </div>
              </template>
              <v-select
                v-model="form.organization"
                slot-scope="{ attrs, listeners }"
                class="w-100 h-auto"
                label="name"
                v-bind="attrs"
                :filterable="false"
                :options="orgOptions"
                :placeholder="'Procure aqui...'"
                :taggable="false"
                :append-to-body="true"
                :calculate-position="dropPos"
                v-on="listeners"
                @search="getOrganizations"
                @input="setOrganization"
              >
                <template slot="no-options"> Digite para procurar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-flex flex-row align-items-center">
                    <div>
                      <b-icon-building font-scale="1.5" class="mr-2" />
                    </div>
                    <div class="flex-column">
                      <div class="font-weight-bold">{{ option.name }}</div>
                      <div>
                        {{ option.email }}
                        {{
                          option.mobile
                          | facade([
                            '####-####',
                            '#####-####',
                            '(##) ####-####',
                            '(##) #####-####'
                          ])
                        }}
                        {{
                          option.telephone
                          | facade([
                            '####-####',
                            '#####-####',
                            '(##) ####-####',
                            '(##) #####-####'
                          ])
                        }}
                      </div>
                    </div>
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }}
                  </div>
                </template>
              </v-select>
            </form-group>
            <div v-if="form.organization" class="small">
              <hr />
              <b-link
                :to="{
                  name: 'Org View',
                  params: { id: form.organization.id }
                }"
                ><b-icon-building
                  font-scale="2"
                  class="w-100"
                  variant="primary"
              /></b-link>
              <FormToOrganizations :organization="form.organization" />
            </div>
          </div>
          <!-- ORGANIZACAO -->
          <!-- CONTATO -->
          <div class="p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius">
            <form-group name="contact" class="form-group font-weight-bold">
              <template #label>
                <div class="align-items-center d-flex">
                  <b-icon-person-fill />
                  Contato
                </div>
              </template>
              <v-select
                v-model="form.contact"
                slot-scope="{ attrs, listeners }"
                class="w-100 h-auto"
                label="name"
                v-bind="attrs"
                :filterable="false"
                :options="peopleOptions"
                :placeholder="'Procure aqui...'"
                :taggable="false"
                :append-to-body="true"
                :calculate-position="dropPos"
                v-on="listeners"
                @search="getPeople"
                @input="localChangeEmitter"
              >
                <template slot="no-options"> Digite para procurar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-flex flex-row align-items-center">
                    <div>
                      <b-icon-person-circle font-scale="1.5" class="mr-2" />
                    </div>
                    <div class="flex-column">
                      <div class="font-weight-bold">{{ option.name }}</div>
                      <div>
                        {{ option.email }}
                        {{
                          option.mobile
                          | facade([
                            '####-####',
                            '#####-####',
                            '(##) ####-####',
                            '(##) #####-####'
                          ])
                        }}
                        {{
                          option.telephone
                          | facade([
                            '####-####',
                            '#####-####',
                            '(##) ####-####',
                            '(##) #####-####'
                          ])
                        }}
                      </div>
                    </div>
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }}
                  </div>
                </template>
              </v-select>
            </form-group>
            <div v-if="form.contact" class="small">
              <hr />
              <b-link
                :to="{
                  name: 'Person View',
                  params: { id: form.contact.id }
                }"
                ><b-icon-person-fill
                  font-scale="2"
                  class="w-100"
                  variant="primary"
              /></b-link>
              <FormToPeople :person="form.contact" />
            </div>
            <div v-else-if="form.organization">
              <div v-if="seePeople">
                <ul>
                  <li v-for="p in seePeople" :key="p.id" class="triggerSelect">
                    <b-link @click.native="selectPerson(p)">{{
                      p.name
                    }}</b-link>
                  </li>
                </ul>
                <div v-if="seePeople.length >= 9">
                  <b-link v-if="!seeMorePeople" @click="seeMorePeople = true"
                    >Ver mais...</b-link
                  >
                  <b-link v-else @click="seeMorePeople = false"
                    >Reduzir...</b-link
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- CONTATO -->
          <!-- CLIENTE -->
          <div class="p-2 shadow-6 mb-2 mt-4 bg-graylight2 big-radius">
            <form-group name="customer" class="form-group font-weight-bold">
              <template #label>
                <div class="align-items-center d-flex">
                  <b-icon-person />
                  Cliente
                </div>
              </template>
              <v-select
                v-model="form.customer"
                slot-scope="{ attrs, listeners }"
                class="w-100 h-auto"
                label="name"
                v-bind="attrs"
                :filterable="false"
                :options="peopleOptions"
                :placeholder="'Procure aqui...'"
                :taggable="false"
                :append-to-body="true"
                :calculate-position="dropPos"
                v-on="listeners"
                @search="getPeople"
                @input="localChangeEmitter"
              >
                <template slot="no-options"> Digite para procurar... </template>
                <template slot="option" slot-scope="option">
                  <div class="d-flex flex-row align-items-center">
                    <div>
                      <b-icon-person-circle font-scale="1.5" class="mr-2" />
                    </div>
                    <div class="flex-column">
                      <div class="font-weight-bold">{{ option.name }}</div>
                      <div>
                        {{ option.email }}
                        {{
                          option.mobile
                          | facade([
                            '####-####',
                            '#####-####',
                            '(##) ####-####',
                            '(##) #####-####'
                          ])
                        }}
                        {{
                          option.telephone
                          | facade([
                            '####-####',
                            '#####-####',
                            '(##) ####-####',
                            '(##) #####-####'
                          ])
                        }}
                      </div>
                    </div>
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }}
                  </div>
                </template>
              </v-select>
            </form-group>
            <div v-if="form.customer" class="small">
              <hr />
              <b-link
                :to="{
                  name: 'Person View',
                  params: { id: form.customer.id }
                }"
                ><b-icon-person-fill
                  font-scale="2"
                  class="w-100"
                  variant="primary"
              /></b-link>
              <FormToPeople :person="form.customer" />
            </div>
          </div>
          <!-- CLIENTE -->
          <div v-if="!form.customer" style="min-height: 300px"></div>
        </b-col>
        <!-- END SIDEBAR -->
        <!-- START TIMELINE -->
        <b-col cols="12" md="8">
          <b-row class="d-block">
            <div class="mt-4">
              <b-tabs
                v-model="activeTab"
                small
                active-nav-item-class="font-weight-bold border-radius-on-top"
                content-class="m-2"
              >
                <b-tab>
                  <template #title>
                    <b-icon-chevron-right small></b-icon-chevron-right>
                  </template>
                </b-tab>
                <b-tab title="Nota">
                  <div class="d-flex flex-column">
                    <tip-tap
                      v-model="timeline.newNote"
                      class="tiptap-main-yellow w-100"
                    />
                    <b-button
                      variant="primary"
                      block
                      class="ml-auto btn-sm mx-3 p-1 mt-2"
                      @click="addNote"
                      >Adicionar nota</b-button
                    >
                  </div>
                </b-tab>
                <b-tab title="Atividade">
                  <div>
                    <ActivityForm @sent="activeTab = 0" />
                  </div>
                </b-tab>
                <b-tab title="GDrive">
                  <b-row>
                    <b-button variant="link" size="sm" @click="openFolderModal"
                      ><b-icon-plus-circle /> Linkar nova pasta</b-button
                    >
                  </b-row>
                  <b-modal
                    id="modal-add-folder"
                    centered
                    title="Linkar pasta"
                    size="lg"
                    hide-footer
                  >
                    <b-container>
                      <b-row
                        ><p>
                          Procure pelo nome, ou cole o ID da pasta, ou
                          <b-button
                            class="p-0 border-0"
                            style="vertical-align: top"
                            variant="link"
                            @click="createNewFolder"
                            >crie uma pasta com o nome do negócio</b-button
                          >
                        </p></b-row
                      >

                      <b-row class="my-2">
                        <b-input v-model="queryFile" class="col"></b-input>
                        <b-button class="col-auto" @click="listFiles"
                          ><b-icon-search
                        /></b-button>
                      </b-row>
                      <Loading :loading="searchFiles" :small="true">
                        <span v-if="searchFilesEmpty" class="text-danger"
                          >Nenhum resultado encontrado...</span
                        >

                        <Tabulator
                          class="table-bordered table-striped table-sm border-0 nice-scroll"
                          :table-data="tableData"
                          :options="tableOptions"
                          @row-click="linkNewFolder"
                        />
                      </Loading>
                    </b-container>
                  </b-modal>
                  <div v-if="folders.length > 0" class="bg-white p-2 shadow-sm">
                    <div v-for="f in folders" :key="f.name" class="folder">
                      <b-row>
                        <b-col>
                          <b-link
                            :href="'https://drive.google.com/drive/u/0/folders/' +
                              f.folder_reference
                              "
                            target="_blank"
                            ><b-icon-folder-fill variant="secondary" />
                            {{ f.name }}</b-link
                          >
                          <b-link
                            v-if="f.name != seeingDeal.title"
                            class="ml-3 text-danger"
                            @click="syncFolderName(f)"
                            ><b-icon-link45deg scale="1.5"
                          /></b-link>
                        </b-col>
                        <b-col cols="auto">
                          <b-dropdown
                            id="dropdown-folder"
                            size="sm"
                            variant="link"
                            menu-class="text-center border-0 shadow-4 small"
                            right
                            no-caret
                          >
                            <template #button-content>
                              <b-icon-three-dots-vertical
                                scale="1"
                                variant="secondary"
                              />
                            </template>
                            <b-dropdown-item
                              variant="primary"
                              @click="createAutoTemplate(f)"
                              ><b-icon-plus-circle /> Auto-template
                            </b-dropdown-item>
                            <b-dropdown-item
                              variant="danger"
                              @click="unlinkFolder(f)"
                            >
                              Deslinkar
                            </b-dropdown-item>
                          </b-dropdown>
                        </b-col>
                      </b-row>
                      <div
                        v-for="(child, i) in f.childrens"
                        :key="child.name + i"
                        class="children"
                      >
                        <b-row
                          v-if="child.mimeType ==
                            'application/vnd.google-apps.spreadsheet'
                            "
                        >
                          <b-col>
                            <b-link :href="child.webViewLink" target="_blank"
                              ><b-icon-file-spreadsheet-fill
                                variant="success"
                              />
                              {{ child.name }}</b-link
                            >
                          </b-col>
                          <b-col v-if="child.name.match(/^\d{5}/g)" cols="auto">
                            <b-button
                              variant="link"
                              size="sm"
                              @click="duplicateNewLetter(child)"
                              ><b-icon-plus-circle /> Duplicar</b-button
                            >
                            <b-button
                              variant="link"
                              size="sm"
                              @click="createNewOrder(child)"
                              ><b-icon-plus-circle /> Gerar pedido</b-button
                            >
                          </b-col>
                        </b-row>
                        <b-link
                          v-else-if="child.mimeType.indexOf('image') !== -1"
                          :href="child.webViewLink"
                          target="_blank"
                          ><b-icon-image variant="danger" />
                          {{ child.name }}</b-link
                        >
                        <b-link
                          v-else-if="child.mimeType.indexOf('pdf') !== -1"
                          :href="child.webViewLink"
                          target="_blank"
                          ><b-icon-file-ppt-fill variant="warning" />
                          {{ child.name }}</b-link
                        >
                        <b-link
                          v-else-if="child.mimeType.indexOf('folder') !== -1"
                          :href="child.webViewLink"
                          target="_blank"
                          ><b-icon-folder variant="secondary" />
                          {{ child.name }}</b-link
                        >
                        <b-link v-else
                          ><b-icon-file-x /> {{ child.name }}</b-link
                        >
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <b-badge variant="danger">Pasta inexistente</b-badge>
                  </div>
                  <!-- <b-row align-v="center">
                    <b-button
                      size="sm"
                      variant="link"
                      @click="returnNextNumber()"
                      >Retornar próximos números (google) : </b-button
                    ><Loading
                      style="width: 25px; align-self: center; color: dodgerblue"
                      :small="true"
                      :loading="nextNumberLoading"
                    />
                    <b-link
                      class="font-weight-bold small mx-3"
                      @click="form.title = nextNumber[0] + ' - ' + form.title"
                      >{{ nextNumber[0] }}</b-link
                    >
                    <b-link
                      class="font-weight-bold small mx-3"
                      @click="form.title = nextNumber[1] + ' - ' + form.title"
                      >{{ nextNumber[1] }}</b-link
                    >
                  </b-row> -->
                  <b-row align-v="center">
                    <b-button
                      size="sm"
                      variant="link"
                      @click="returnNextNumberServer()"
                      >Retornar próximos números (servidor) : </b-button
                    ><Loading
                      style="width: 25px; align-self: center; color: dodgerblue"
                      :small="true"
                      :loading="nextNumberServerLoading"
                    />
                    <b-link
                      class="font-weight-bold small mx-3"
                      @click="
                        form.title = nextNumberServer[0] + ' - ' + form.title
                        "
                      >{{ nextNumberServer[0] }}</b-link
                    >
                    <b-link
                      class="font-weight-bold small mx-3"
                      @click="
                        form.title = nextNumberServer[1] + ' - ' + form.title
                        "
                      >{{ nextNumberServer[1] }}</b-link
                    >
                  </b-row>
                </b-tab>
              </b-tabs>
            </div>
          </b-row>
          <b-row>
            <b-col>
              <b-col v-if="timelineOrdered">
                <b-row v-for="(note, index) in timelineOrdered" :key="index">
                  <div v-if="note.spec == 'notes'" class="w-100 my-2">
                    <div
                      class="border p-2"
                      style="background-color: lightyellow; border-radius: 10px"
                    >
                      <div class="d-flex">
                        <div
                          class="small w-100 text-left text-black-50"
                          :title="note.id"
                        >
                          {{ niceMoment(note.created_at) }} por
                          {{
                            note.creator ? note.creator.name : note.creator_id
                          }}
                        </div>
                        <b-button
                          title="Fixar no topo"
                          variant="link"
                          class="p-0 m-0"
                          @click="stickNote(note)"
                          ><b-icon-sticky-fill
                            v-if="note.sticky == true"
                            variant="secondary" /><b-icon-sticky
                            v-else
                            variant="secondary"
                        /></b-button>
                        <b-button
                          variant="link"
                          class="p-0 m-0"
                          @click="deleteNote(note)"
                          ><b-icon-x variant="danger"
                        /></b-button>
                      </div>
                      <tip-tap :value="note.note" :editable="false" />
                      <div
                        class="small text-right text-black-50"
                        style="line-height: 1.1em"
                      >
                        <div
                          v-if="moment(note.updated_at).diff(note.created_at)"
                        >
                          última atualização:
                          {{ niceMoment(note.updated_at) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="note.spec == 'history'" class="w-100 my-2">
                    <div
                      class="text-black-50 text-center"
                      style="
                        background-color: transparent;
                        border-radius: 10px;
                        border: dashed 1px lightgray;
                      "
                    >
                      <span class="small text-left d-block font-italic">
                        {{ niceMoment(note.created_at) }} por {{ note.actor }}
                      </span>
                      Atualizou
                      <span class="font-italic font-weight-bold">{{
                        note.attribute
                      }}</span>
                      de ({{ note.name[0] }}) para ({{ note.name[1] }})
                    </div>
                  </div>
                  <div v-else-if="note.spec == 'activities'" class="w-100 my-2">
                    <div
                      :class="[
                        'px-2 shadow-1 text-black-50',
                        note.status == 'Concluído'
                          ? 'bg-transparent shadow-sm border'
                          : ''
                      ]"
                      style="
                        background-color: white;
                        border-radius: 10px;
                        border: 5px solid #f8f9fa;
                      "
                    >
                      <div v-if="note.status == 'Em aberto'">
                        <b-badge
                          v-if="moment(note.due_date).isBefore()"
                          class="float-right"
                          pill
                          variant="danger"
                          >Atrasado</b-badge
                        >
                        <b-badge
                          v-else
                          pill
                          class="float-right"
                          variant="success"
                          >Planejado</b-badge
                        >
                      </div>
                      <b-form-checkbox
                        :key="note.id + index"
                        :checked="note.status"
                        value="Concluído"
                        size="lg"
                        unchecked-value="Em aberto"
                        class="font-weight-bold input-pointer"
                        @change="checkActivity(note)"
                      >
                        <b-button
                          variant="link"
                          class="p-0 m-0 font-weight-bold text-black-50 border-0 align-text-bottom"
                          :style="{
                            marginBottom: '2px !important',
                            textDecoration:
                              note.status == 'Concluído'
                                ? 'line-through'
                                : 'none'
                          }"
                          @click.stop="openActivity(note)"
                          >{{ note.activity_type.name }}</b-button
                        >
                      </b-form-checkbox>
                      <div class="pl-4" :title="note.id">
                        <div
                          class="border p-2"
                          :style="{
                            backgroundColor: note.notes && note.notes.length ? 'lightyellow' : 'transparent',
                            borderRadius: '10px',
                          }"
                        >
                          <tip-tap v-if="note.notes && note.notes.length" :value="note.notes" :editable="false" />
                          <span v-else>(vazio)</span>
                        </div>
                        {{
                          note.closed_date
                          ? niceMoment(note.closed_date)
                          : niceMoment(note.due_date)
                        }}
                        -
                        <span class="small">
                          para: {{ note.enrollments.toString() }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    {{ JSON.stringify(note) }}
                  </div>
                </b-row>
              </b-col>
              <b-col v-else>
                <hr />
                <div class="w-100 text-center text-black-50">
                  linha do tempo vazia
                </div>
                <hr />
              </b-col>
            </b-col>
          </b-row>
        </b-col>
        <!-- END TIMELINE -->
      </b-row>
      <b-row class="small">
        Criado em: {{ createdAt }} <br />
        Criado por: {{ creatorId }} <br /><br />
        Atualizado em: {{ updatedAt }} <br />
        Atualizado por: {{ lastUpdateById }}<br />
      </b-row>
      <b-row class="mt-4">
        <b-button
          v-if="form.active"
          variant="danger"
          @click="
            $v.form.$touch()
          localChangeEmitter()
          form.active = false
            "
          >Deletar</b-button
        >
      </b-row>
    </form-wrapper>
    <go-top
      :size="35"
      :right="15"
      :bottom="25"
      fg-color="#000000"
      bg-color="#eaeaea"
      weight="bold"
    ></go-top>
  </b-container>
</template>

<script>
import { CurrencyInput } from 'vue-currency-input' // setValue
import Loading from './Loading.vue'

import { required } from 'vuelidate/lib/validators'
import find from 'lodash/find'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import remove from 'lodash/remove'

import FormToOrganizations from './FormToOrganizations.vue'
import FormToPeople from './FormToPeople.vue'
import ActivityForm from './ActivityForm.vue'

import { filter } from 'vue-input-facade'

import TipTap from './TipTap.vue'
import GoTop from '@inotom/vue-go-top'

import Tabulator from './Tabulator.vue'

export default {
  filters: { facade: filter },
  components: {
    CurrencyInput,
    Loading,
    FormToOrganizations,
    FormToPeople,
    GoTop,
    TipTap,
    ActivityForm,
    Tabulator
  },
  data() {
    return {
      saved: true,
      unwatchers: [],
      orgOptions: [],
      peopleOptions: [],
      statuses: null,
      stages: null,
      createdAt: null,
      updatedAt: null,
      creatorId: null,
      lastUpdateById: null,
      form: {
        title: null,
        owner: null,
        value: null,
        status: null,
        stage: null,
        organization: null,
        customer: null,
        contact: null,
        active: null,
        pipe: null,
        archived: null
      },
      position: 0,
      seeingDeal: null,
      localChange: null,
      seeMorePeople: false,
      timeline: {
        fetched: false,
        newNote: '',
        notes: null
      },
      activeTab: 0,
      tableData: [],
      tableOptions: {
        height: 400,
        layout: 'fitColumns',
        layoutColumnsOnNewData: true,
        columns: [
          { title: 'Nome', field: 'name', sorter: 'alphanum', minWidth: 200 },
          {
            title: 'Data modificada',
            field: 'modifiedTime',
            formatter: 'datetime',
            sorter: 'datetime',
            width: 200,
            sorterParams: {
              format: 'YYYY-MM-DD HH:mm:ss',
              alignEmptyValues: 'top'
            }
          },
          {
            title: 'Link',
            field: 'webViewLink',
            formatter: 'link',
            formatterParams: {
              label: 'Link',
              urlField: 'webViewLink',
              target: '_blank'
            },
            width: 50,
            headerSort: false
          }
        ]
      },
      queryFile: '',
      searchFiles: false,
      searchFilesEmpty: false,
      loading: true,
      folders: [],
      okToOpenFolderModal: false,
      nextNumberLoading: false,
      nextNumber: ['', ''],
      nextNumberServerLoading: false,
      nextNumberServer: ['', ''],
      titleReady: false
    }
  },

  validations: {
    form: {
      title: {
        required,
        existent: function () {
          return !this.titleReady
        }
      },
      owner: {},
      value: {},
      status: { required },
      stage: { required },
      organization: {},
      customer: {},
      contact: {},
      active: {},
      archived: {},
      pipe: { required }
    }
  },

  metaInfo() {
    return {
      title: this.seeingDeal.title
    }
  },

  computed: {
    // GET DEAL FROM PARAMS NOT FROM PROP
    // alguma lógica do start não está boa, entre dealid, e watchers
    // entre o fetchdeal e o fillfields e cascade nos fetchs
    dealID: function () {
      return parseInt(this.$route.params.id)
    },
    list_index: function () {
      return this.$store.state.lists.findIndex((list) => {
        return list.deals.find((card) => {
          return card.id === this.dealID
        })
      })
    },
    deal_index: function () {
      if (this.list_index < 0) return
      return this.$store.state.lists[this.list_index].deals.findIndex(
        (item) => item.id == this.dealID
      )
    },
    compute_deal: function () {
      return this.$store.state.lists[this.list_index].deals[this.deal_index]
    },

    entity: function () {
      let f = {}
      f.deal = {
        title: this.form.title,
        value: this.form.value,
        deal_status_id: this.form.status.id,
        deal_stage_id: this.form.stage.id,
        owner_id: this.form.owner.email,
        organization_id: this.form.organization
          ? this.form.organization.id
          : '',
        customer_person_id: this.form.customer ? this.form.customer.id : '',
        contact_person_id: this.form.contact ? this.form.contact.id : '',
        active: this.form.active,
        pipe: this.form.pipe.deal_stages[0].id,
        archived: this.form.archived
      }
      return f
    },
    seePeople: function () {
      if (!this.form.organization.people) return
      if (this.seeMorePeople) {
        return this.form.organization.people
      }
      return this.form.organization.people.slice(0, 10)
    },
    owners: function () {
      return this.$store.state.usersEmailsList
    },
    pipes: function () {
      return this.$store.state.pipes
    },
    timelineOrdered: function () {
      if (!this.timeline.notes || this.timeline.notes.length <= 0) return
      return this.timeline.notes.slice(0).sort((a, b) => {
        // sort first open activities then by due_date or created_at

        let c = a.closed_date || a.due_date || a.created_at
        let d = b.closed_date || b.due_date || b.created_at

        return (
          (b.sticky === true) - (a.sticky === true) ||
          (b.status === 'Em aberto') - (a.status === 'Em aberto') ||
          this.moment(d) - this.moment(c)
        )
      })
    }
  },

  watch: {
    // IF DEAL is NOT status = 1
    '$store.state.seeingDeal': {
      immediate: true,
      deep: true,
      handler(value) {
        this.seeingDeal = value

        this.localChange = false

        if (!isEmpty(this.seeingDeal)) this.fillFields(this.seeingDeal)
      }
    },
    '$store.state.needToUpdate': {
      immediate: true,
      deep: true,
      handler(value) {
        if (isEmpty(value)) return
        if (value[0] == 'organization') {
          this.fetchOrganization(this.seeingDeal)
        }
        if (value[0] == 'person') {
          if (value.person.id == this.entity.deal.customer_person_id) {
            this.fetchCustomer(this.seeingDeal)
          }
          if (value.person.id == this.entity.deal.contact_person_id) {
            this.fetchContact(this.seeingDeal)
          }
        }
        if (value[0] == 'timeline') {
          console.log('\n\n------ begin: needToUpdate ------')
          console.log('------ end: needToUpdate ------\n\n')
          this.fetchTimeline(this.seeingDeal.id)
        }
        this.$store.state.needToUpdate = []
      }
    },
    dealID() {
      // must have same logic when created, except watchers
      if (this.list_index < 0) {
        let vm = this
        this.fetchDeal(this.dealID).then(function (data) {
          vm.$store.state.seeingDeal = data
          vm.fetchTimeline(vm.$store.state.seeingDeal.id)
        })
      } else {
        this.$store.state.seeingDeal = this.compute_deal
        this.fetchTimeline(this.$store.state.seeingDeal.id)
      }
    }
  },

  created() {
    this.loading = true
    this.installWatchers()
    // FETCH DATA
    this.statuses = this.$store.getters.statuses

    if (this.list_index < 0) {
      let vm = this
      this.fetchDeal(this.dealID).then(function (data) {
        vm.$store.state.seeingDeal = data
        vm.fetchTimeline(vm.$store.state.seeingDeal.id)
      })
    } else {
      this.$store.state.seeingDeal = this.compute_deal
      this.fetchTimeline(this.$store.state.seeingDeal.id)
    }
  },

  destroyed() {
    this.$store.state.seeingDeal = {}
  },

  methods: {
    dropPos(dropdownList, component, { width, top, left }) {
      if (component.options.length > 0) {
        dropdownList.style.top = top
        dropdownList.style.left = 0
        dropdownList.style.width = document.body.clientWidth
      } else {
        dropdownList.style.top = top
        dropdownList.style.left = left
        dropdownList.style.width = width
      }
    },
    localChangeEmitter() {
      this.localChange = true
    },

    checkTitleReady(value) {
      if (!value) this.titleReady = true
      if (value.length < 4) this.titleReady = true
      if (!this.$v.form.title.$anyDirty) this.titleReady = true

      Rails.ajax({
        url: `/api/title/search?deal[title]=${encodeURI(value)}&deal[id]=${this.seeingDeal.id
          }`,
        type: 'GET',
        dataType: 'json',
        success: (data) => {
          console.log(data)
          this.titleReady = data
          this.form.title = value
        },
        error: (data) => {
          console.log(data)
        }
      })
    },

    handleChange(newVal, oldVal, key) {
      console.log('changed before! === ', key)

      if (this.localChange == false) return

      // check form any changes on form
      if (!this.$v.form.$anyDirty) return

      // check specific changed field if valid
      if (this.$v.form[key].$invalid) return

      console.log('changed AFTER TESTS ', key)

      this.saved = false

      var fd = new FormData()
      var isMove = ''

      switch (key) {
        case 'status':
          fd.append('deal[deal_status_id]', this.entity.deal.deal_status_id)
          break

        case 'stage':
          fd.append('deal[deal_stage_id]', this.entity.deal.deal_stage_id)
          isMove = '/move'
          break

        case 'owner':
          fd.append('deal[owner_id]', this.entity.deal.owner_id)
          break

        case 'organization':
          fd.append('deal[organization_id]', this.entity.deal.organization_id)
          break

        case 'customer':
          fd.append(
            'deal[customer_person_id]',
            this.entity.deal.customer_person_id
          )
          break

        case 'contact':
          fd.append(
            'deal[contact_person_id]',
            this.entity.deal.contact_person_id
          )
          break

        case 'active':
          fd.append('deal[active]', this.entity.deal.active)
          break

        case 'archived':
          fd.append('deal[archived]', this.entity.deal.archived)
          break

        case 'pipe':
          fd.append('deal[deal_stage_id]', this.entity.deal.pipe)
          break

        default:
          fd.append('deal[' + key + ']', newVal)
          break
      }

      // console.log(fd)

      Rails.ajax({
        //beforeSend: () => true,
        url: `/api/deals/${this.seeingDeal.id}${isMove}`,
        type: 'PATCH',
        data: fd,
        dataType: 'json',
        success: (data) => {
          console.log(data)
        },
        error: (data) => {
          this.$root.toast('Erro - ' + data.status, data.status, 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    installWatchers() {
      this.unwatchers = []
      for (let key in this.form) {
        let unwatch = this.$watch(
          'form.' + key,
          (newVal, oldVal) => this.handleChange(newVal, oldVal, key),
          {
            deep: true
          }
        )
        this.unwatchers.push(unwatch)
      }
    },

    fetchDeal(dealID) {
      let vm = this
      const response = new Promise(function (resolve, reject) {
        Rails.ajax({
          url: `/api/deals/${dealID}`,
          type: 'GET',
          dataType: 'json',
          success: (data) => {
            console.log(data)
            resolve(data)
          },
          error: (data, status, xhr) => {
            console.error(xhr.status, xhr.statusText)
            vm.$bvModal
              .msgBoxOk(xhr.status + ' - ' + xhr.statusText, {
                title: 'ERRO'
              })
              .then(() => {
                vm.$router.push({ name: 'Home' })
              })
              .catch((data) => {
                this.$root.toast('Erro - ' + data.status, data.status, 'danger')
              })
            reject(data)
          },
          complete: (data) => resolve(data)
        })
      })
      return response
    },

    async fetchContact(deal) {
      const response = new Promise(function (resolve, reject) {
        Rails.ajax({
          url: `/api/people/${deal.contact_person_id}`,
          type: 'GET',
          dataType: 'json',
          success: (data) => {
            console.log(data)
            resolve(data)
          },
          error: (data) => {
            this.$root.toast('Erro - ' + data.status, data.status, 'danger')
            reject(data)
          },
          complete: (data) => resolve(data)
        })
      })
      this.form.contact = await response
      return response
    },

    async fetchCustomer(deal) {
      const response = new Promise(function (resolve, reject) {
        if (deal.customer_person_id) {
          Rails.ajax({
            url: `/api/people/${deal.customer_person_id}`,
            type: 'GET',
            dataType: 'json',
            success: (data) => {
              console.log(data)
              resolve(data)
            },
            error: (data) => {
              this.$root.toast('Erro - ' + data.status, data.status, 'danger')
              reject(data)
            },
            complete: (data) => resolve(data)
          })
        }
      })
      this.form.customer = await response
      return response
    },

    async fetchOrganization(deal) {
      const response = new Promise(function (resolve, reject) {
        if (deal.organization_id) {
          Rails.ajax({
            url: `/api/organizations/${deal.organization_id}?include=people`,
            type: 'GET',
            dataType: 'json',
            success: (data) => {
              console.log(data)
              resolve(data)
            },
            error: (data) => {
              this.$root.toast('Erro - ' + data.status, data.status, 'danger')
              reject(data)
            },
            complete: (data) => resolve(data)
          })
        }
      })
      this.form.organization = await response
      return response
    },

    async fillFields(deal) {
      this.stages = this.$store.getters
        .stages_per_deal_stage(deal.deal_stage_id)
        .sort((a, b) => a.position - b.position)
      this.form.title = deal.title
      this.form.value = parseFloat(deal.value)
      this.form.status = find(this.statuses, { id: deal.deal_status_id })
      this.form.owner = { email: deal.owner_id }
      this.form.stage = find(this.stages, { id: deal.deal_stage_id })
      this.form.active = deal.active
      this.form.archived = deal.archived
      this.createdAt = this.niceMoment(deal.created_at)
      this.updatedAt = this.niceMoment(deal.updated_at)
      this.creatorId = deal.creator_id
      this.lastUpdateById = deal.last_update_by_id
      this.form.pipe = find(this.pipes, {
        deal_stages: [{ id: deal.deal_stage_id }]
      })

      // CHAIN START

      if (deal.contact_person_id) {
        if (this.form.contact) {
          if (deal.contact_person_id != this.form.contact.id) {
            await this.fetchContact(deal)
          }
        } else {
          await this.fetchContact(deal)
        }
      } else {
        this.form.contact = null
      }

      if (deal.customer_person_id) {
        if (this.form.customer) {
          if (deal.customer_person_id != this.form.customer.id) {
            await this.fetchCustomer(deal)
          }
        } else {
          await this.fetchCustomer(deal)
        }
      } else {
        this.form.customer = null
      }

      if (deal.organization_id) {
        if (this.form.organization) {
          if (deal.organization_id != this.form.organization.id) {
            await this.fetchOrganization(deal)
          }
        } else {
          await this.fetchOrganization(deal)
        }
      } else {
        this.form.organization = null
      }
    },

    getOrganizations(search, loading) {
      loading(true)
      if (search.length > 2) {
        this.searchOrgs(loading, search, this)
      } else {
        loading(false)
      }
    },

    searchOrgs: debounce((loading, search, vm) => {
      Rails.ajax({
        url: `/api/organizations.json?name=${encodeURI(search)}`,
        type: 'GET',
        //data: fd,
        dataType: 'json',
        success: (data) => {
          console.log(data)
          vm.orgOptions = data
        },
        error: (data) => {
          this.$root.toast('Erro - ' + data.status, data.status, 'danger')
        },
        complete: () => {
          loading(false)
        }
      })
    }, 350),

    getPeople(search, loading) {
      loading(true)
      if (search.length > 2) {
        this.searchPeople(loading, search, this)
      } else {
        loading(false)
      }
    },

    searchPeople: debounce((loading, search, vm) => {
      Rails.ajax({
        url: `/api/people.json?name=${encodeURI(search)}`,
        type: 'GET',
        //data: fd,
        dataType: 'json',
        success: (data) => {
          console.log(data)
          vm.peopleOptions = data
        },
        error: (data) => {
          this.$root.toast('Erro - ' + data.status, data.status, 'danger')
        },
        complete: () => {
          loading(false)
        }
      })
    }, 350),

    setOrganization(event) {
      this.localChangeEmitter()
      if (!event) {
        this.form.organization = null
      } else {
        this.fetchOrganization({ organization_id: event.id })
      }
    },

    setCustomer(obj) {
      console.log('set cust: ', obj)
      this.form.customer = obj
    },

    setContact(obj) {
      console.log('set cont : ', obj)
      this.form.contact = obj
    },

    selectPerson(person) {
      console.log(person)
      this.$v.form.$touch()
      this.localChangeEmitter()
      this.form.contact = person
    },

    avoidValidClass() {
      if (this.$refs.title === undefined) return false
      if (this.$refs.title.$el.classList.contains('is-valid')) {
        return true
      } else {
        return false
      }
    },

    niceMoment(date) {
      return this.moment(date).calendar(null, {
        sameDay: null,
        nextDay: null,
        nextWeek: function () {
          return this.day() === 0 || this.day() === 6
            ? '[Próximo] dddd [às] LT' // Saturday + Sunday
            : '[Próxima] dddd [às] LT' // Monday - Friday
        },
        lastDay: null,
        lastWeek: null,
        sameElse: 'ddd LLL'
      })
    },

    // START TIMELINE STUFF

    fetchTimeline(id) {
      if (!id) return
      Rails.ajax({
        url: `/api/timeline/${id}`,
        type: 'GET',
        dataType: 'json',
        success: (data) => {
          if (data) {
            if (data.length > 0) {
              this.folders = remove(data, { spec: 'folders' })
              this.timeline.notes = data
              this.setFolderFiles()
            }
          }
        },
        error: (data) => {
          this.$root.toast('Erro - ' + data.status, data.status, 'danger')
        },
        complete: () => {
          this.saved = true
          this.loading = false
        }
      })
    },

    addNote() {
      if (!this.timeline.newNote) return
      if (this.timeline.newNote.length <= 0) return

      var fd = new FormData()
      fd.append('note[note]', this.timeline.newNote)
      fd.append('note[deal_id]', this.seeingDeal.id)

      Rails.ajax({
        url: `/api/notes`,
        type: 'POST',
        data: fd,
        dataType: 'json',
        success: () => {
          this.timeline.newNote = ''
          this.activeTab = 0
          this.$root.toast('Sucesso', 'Nota adicionada com sucesso!', 'success')
        },
        error: (data) => {
          this.$root.toast('Erro - ' + data.status, data.status, 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    deleteNote(note) {
      this.saved = false
      Rails.ajax({
        url: `/api/notes/${note.id}`,
        type: 'DELETE',
        dataType: 'json',
        success: () => {
          this.$root.toast('Sucesso', 'Nota deletada com sucesso!', 'success')
        },
        error: (data) => {
          this.$root.toast('Erro - ' + data.status, data.status, 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    stickNote(note) {
      this.saved = false
      Rails.ajax({
        url: `/api/notes/stick/${note.id}`,
        type: 'PATCH',
        dataType: 'json',
        success: () => {
          this.$root.toast('Sucesso', 'Nota sticked com sucesso!', 'success')
        },
        error: (data) => {
          this.$root.toast('Erro - ' + data.status, data.status, 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    checkActivity(activity) {
      Rails.ajax({
        url: `/api/activities/check/${activity.id}`,
        type: 'PATCH',
        dataType: 'json',
        success: () => {
          this.$root.toast(
            'Sucesso',
            'Atividade atualizada com sucesso!',
            'success'
          )
          this.activeTab = 2
          window.scroll({
            top: 0,
            behavior: 'smooth'
          })
        },
        error: (data) => {
          this.$root.toast('Erro - ' + data.status, data.status, 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    openActivity(activity) {
      let h = this.$createElement
      const vnode = h('ActivityForm', { props: { value: activity } })
      this.$bvModal.msgBoxOk([vnode], {
        id: 'activity_modal',
        footerClass: 'd-none',
        title: 'Atividades',
        centered: true,
        size: 'lg'
      })
    },

    setFolderFiles() {
      this.folders.forEach((folder, i) => {
        this.saved = false
        let fd = new FormData()
        fd.append('folder_id', folder.folder_reference)
        Rails.ajax({
          url: `/api/list_folder_files`,
          type: 'POST',
          data: fd,
          dataType: 'json',
          success: (data) => {
            console.log(data)
            this.$set(this.folders[i], 'childrens', [...data.files])
          },
          error: (data) => {
            console.log(data)
          },
          complete: () => {
            this.saved = true
          }
        })
      })
    },

    listFiles() {
      this.searchFiles = true
      var fd = new FormData()
      fd.append('name', this.queryFile)

      Rails.ajax({
        url: `/api/list_files/`,
        type: 'POST',
        dataType: 'json',
        data: fd,
        success: (data) => {
          this.setFilesData(data)
        },
        error: (data) => {
          this.$root.toast('Erro - ' + data.status, data.status, 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    setFilesData(data) {
      this.searchFilesEmpty = false
      if (data.files?.length > 0) {
        this.tableData = data.files
      } else if (typeof data == 'object') {
        this.tableData = [data]
      } else {
        this.tableData = []
        this.searchFilesEmpty = true
      }
      this.searchFiles = false
    },

    openFolderModal() {
      if (this.$store.state.user.admin) {
        this.$bvModal.show('modal-add-folder')
      } else {
        this.$root.toast('Erro', 'sem autorização - unauthorized', 'danger')
      }
    },

    returnNextNumber() {
      this.nextNumberLoading = true
      Rails.ajax({
        url: `/api/title/next_number`,
        type: 'GET',
        dataType: 'json',
        success: (data) => {
          this.nextNumber = data
        },
        error: (data) => {
          console.log(data)
        },
        complete: () => {
          this.nextNumberLoading = false
        }
      })
    },

    returnNextNumberServer() {
      this.nextNumberSererLoading = true
      Rails.ajax({
        url: `/api/title/next_number_server`,
        type: 'GET',
        dataType: 'json',
        success: (data) => {
          this.nextNumberServer = data
        },
        error: (data) => {
          console.log(data)
        },
        complete: () => {
          this.nextNumberServerLoading = false
        }
      })
    },

    createNewFolder() {
      this.saved = false
      var fd = new FormData()
      fd.append('name', this.form.title)
      fd.append('deal_id', this.seeingDeal.id)
      Rails.ajax({
        url: `/api/create_new_folder`,
        type: 'POST',
        dataType: 'json',
        data: fd,
        success: () => {
          this.$bvModal.hide('modal-add-folder')
        },
        error: (data) => {
          console.log(data)
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    linkNewFolder(e, row) {
      this.saved = false
      let folderData = row.getData()
      let fd = new FormData()
      fd.append('folder[name]', folderData.name)
      fd.append('folder[deal_id]', this.seeingDeal.id)
      fd.append('folder[folder_reference]', folderData.id)
      Rails.ajax({
        url: `/api/folders`,
        type: 'POST',
        dataType: 'json',
        data: fd,
        success: () => {
          this.$bvModal.hide('modal-add-folder')
        },
        error: (data) => {
          console.log(data)
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    unlinkFolder(folder) {
      this.saved = false
      Rails.ajax({
        url: `/api/folders/${folder.id}`,
        type: 'DELETE',
        dataType: 'json',
        success: () => {
          this.$root.toast('ok', 'Folder un-linked', 'success')
        },
        error: (data) => {
          console.log(data)
          this.$root.toast('Erro', 'sem autorização - unauthorized', 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    createAutoTemplate(folder) {
      this.saved = false
      Rails.ajax({
        url: `/api/create_auto_template?id=${this.seeingDeal.id}&folder_id=${folder.folder_reference}`,
        type: 'POST',
        dataType: 'json',
        success: () => {
          this.$root.toast('Sucesso', 'Template criado', 'success')
        },
        error: (data) => {
          console.log(data)
          this.$root.toast('Erro', 'impossível criar template', 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    syncFolderName(f) {
      this.saved = false
      let fd = new FormData()
      fd.append('id', f.id)
      fd.append('name', this.seeingDeal.title)

      Rails.ajax({
        url: `/api/rename_folder/`,
        type: 'POST',
        data: fd,
        dataType: 'json',
        success: () => {
          this.$root.toast('Sincronizado', '', 'success')
        },
        error: (data) => {
          console.log(data)
          this.$root.toast('Erro', 'impossível sincronizar', 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    duplicateNewLetter(child) {
      this.saved = false
      let fd = new FormData()
      fd.append('id', child.id)
      fd.append('name', child.name)
      fd.append('deal_id', this.seeingDeal.id)

      Rails.ajax({
        url: `/api/duplicate_letter/`,
        type: 'POST',
        data: fd,
        dataType: 'json',
        success: () => {
          this.$root.toast('Duplicado', 'Duplicado', 'success')
        },
        error: (data) => {
          console.log(data)
          this.$root.toast('Erro', 'impossível duplicar', 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    createNewOrder(child) {
      this.saved = false
      let fd = new FormData()
      fd.append('id', child.id)
      fd.append('name', child.name)
      fd.append('deal_id', this.seeingDeal.id)

      Rails.ajax({
        url: `/api/create_new_order/`,
        type: 'POST',
        data: fd,
        dataType: 'json',
        success: () => {
          this.$root.toast('Pedido gerado', 'Gerado com sucesso', 'success')
        },
        error: (data) => {
          console.log(data)
          this.$root.toast('Erro', 'impossível gerar pedido', 'danger')
        },
        complete: () => {
          this.saved = true
        }
      })
    },

    statusVariant: function (status) {
      switch (status) {
        case 'Em aberto':
          return 'outline-primary'
        case 'Perdido':
          return 'outline-danger'
        case 'Ganho':
          return 'outline-success'
        case 'Finalizado':
          return 'outline-dark'
        default:
          return 'outline-secondary'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.no-valid-vuelidate {
  border-color: #6c757d !important;
  background-image: none !important;
}

.edit-title {
  height: auto;
  color: #6c757d;
  font-weight: bold;
  width: 100%s;
  font-weight: bold;
  border: none;
  border-bottom: solid 2px transparent !important;
  border-radius: 0;
  font-size: xx-large;
  padding: 0;
  margin: 0 0 16px 0;

  &:focus {
    color: black;
    border: 0;
    border-bottom: solid 2px black !important;
    box-shadow: none;
  }
}

.custom-inline::v-deep {
  & legend {
    margin-right: 5px;
    float: left !important;
    order: 0;
    padding: 0;
    flex-basis: 0;
  }

  &>div {
    width: 100%;
  }
}

.stage-progress {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid transparent;
  background-color: transparent;
}

.arrow-right::before {
  content: '';
  position: relative;
  display: block;
  width: 30px;
  height: 30px;
  border-right: 3px solid white;
  border-top: 3px solid white;
  transform: rotate(45deg) translate(-36px, 14px);
}

.progress {
  border-radius: 0rem;
  min-width: 1000px;
}

.progress-bar:last-child .arrow-right {
  border-top: 20px solid white;
  border-bottom: 20px solid white;
}

.active-stage .arrow-right {
  border-top: 20px solid #6c757d;
  border-bottom: 20px solid #6c757d;
}

.cursor-pointer {
  cursor: pointer;
}

.big-radius {
  border-radius: 12px;
}

.nice-scroll::-webkit-scrollbar {
  height: 20px;
}

.nice-scroll::-webkit-scrollbar-track {
  //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
  background-color: transparent;
}

.nice-scroll::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
  background-color: #d6dee1;
}

.nice-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

input {
  background-color: white;
}

.v-select {
  background-color: white;
}

.tiptap-main::v-deep {
  & .ProseMirror {
    background-color: white;
    min-height: 100px;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    padding: 0.5rem;
  }
}

.tiptap-main-yellow::v-deep {
  & .ProseMirror {
    background-color: lightyellow;
    min-height: 100px;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    padding: 0.5rem;

    p.is-editor-empty:first-child::before {
      color: #6c757d;
    }
  }
}

.input-pointer::v-deep input {
  cursor: pointer;
  z-index: 1;
}

.folder .children {
  text-indent: 15px;
}

::v-deep {
  .border-radius-on-top {
    border-radius: 10px 10px 0px 0px !important;
  }

  /* Basic editor styles */
  .ProseMirror {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    p {
      margin-top: 0rem !important;
      margin-bottom: 0rem !important;
    }

    p.is-editor-empty:first-child::before {
      content: attr(data-placeholder);
      float: left;
      color: #ced4da;
      pointer-events: none;
      height: 0;
    }

    // > * + * {
    //   margin-top: 0.75em;
    // }

    ul,
    ol {
      padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }

    code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
    }

    pre {
      background: #0d0d0d;
      color: #fff;
      font-family: 'JetBrainsMono', monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }

    mark {
      background-color: #faf594;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    hr {
      margin: 1rem 0;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
      border: none;
      border-top: 2px solid rgba(#0d0d0d, 0.1);
      margin: 2rem 0;
    }

    ul[data-type='taskList'] {
      list-style: none;
      padding: 0;

      li {
        display: flex;
        align-items: center;

        >label {
          flex: 0 0 auto;
          margin-right: 0.5rem;
        }
      }
    }
  }
}
</style>
