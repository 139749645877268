var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"sticky-to-top",staticStyle:{"z-index":"10"},attrs:{"align-h":"center","align-v":"center","no-gutters":""}},[_c('b-col',{staticClass:"mobile-absolute-1",attrs:{"cols":"auto"}},[_c('b-link',{staticClass:"d-flex flex-column align-items-center m-md-2",attrs:{"to":{ name: 'Home' }}},[_c('b-icon-filter',{attrs:{"font-scale":"1.5"}}),_vm._v(" "),_c('span',{staticClass:"small"},[_vm._v("Funil")])],1)],1),_vm._v(" "),_c('b-col',{staticClass:"mobile-absolute-3",attrs:{"cols":"10","md":"5"}},[_c('v-select',{staticClass:"h-auto",attrs:{"get-option-key":function (option) { return option.id +
          (option.name || '').substr(4) +
          (option.telephone || '').substr(-4); },"label":"name","filterable":false,"options":_vm.searchOpts,"placeholder":'Procure aqui...',"taggable":false,"append-to-body":true,"calculate-position":_vm.dropPos},on:{"search":_vm.getSearch,"input":_vm.setSearchGoto},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('div',[('organization_id' in option)?_c('b-icon-person',{staticClass:"mr-2",attrs:{"font-scale":"1.5"}}):('stage' in option)?_c('b-icon-files',{staticClass:"mr-2",attrs:{"font-scale":"1.5"}}):_c('b-icon-building',{staticClass:"mr-2",attrs:{"font-scale":"1.5"}})],1),_vm._v(" "),_c('div',{staticClass:"flex-column"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(option.name))]),_vm._v(" "),('stage' in option)?_c('div',[_vm._v("\n              "+_vm._s(option.status ? option.status.name : '')+" -\n              "+_vm._s(option.stage ? option.stage.name : '')+"\n            ")]):_c('div',[_vm._v("\n              "+_vm._s(option.email)+"\n              "+_vm._s(_vm._f("facade")(option.mobile,[
                    '####-####',
                    '#####-####',
                    '(##) ####-####',
                    '(##) #####-####'
                  ]))+"\n              "+_vm._s(_vm._f("facade")(option.telephone,[
                    '####-####',
                    '#####-####',
                    '(##) ####-####',
                    '(##) #####-####'
                  ]))+"\n            ")])])])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v("\n          "+_vm._s(option.name)+"\n        ")])]}}]),model:{value:(_vm.form.search),callback:function ($$v) {_vm.$set(_vm.form, "search", $$v)},expression:"form.search"}},[_c('template',{slot:"no-options"},[_vm._v(" Digite para procurar... ")])],2)],1),_vm._v(" "),_c('b-col',{staticClass:"position-static",attrs:{"cols":"auto"}},[_c('b-dropdown',{staticClass:"m-md-2 mobile-absolute-2",attrs:{"id":"dropdown-1","no-caret":"","boundary":"window"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-plus',{attrs:{"scale":"1.5"}})]},proxy:true}])},[_vm._v(" "),_c('b-dropdown-item',{attrs:{"to":{ name: 'Deal New' }}},[_c('b-icon-files'),_vm._v(" Adicionar Negócio")],1),_vm._v(" "),_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-org",modifiers:{"modal-org":true}}]},[_c('b-icon-building'),_vm._v(" Adicionar Organização")],1),_vm._v(" "),_c('b-modal',{attrs:{"id":"modal-org","centered":"","title":"Nova organização","hide-footer":"","size":"lg"}},[_c('FormToOrganizations',{attrs:{"organization":{},"new-form":true}})],1),_vm._v(" "),_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-person",modifiers:{"modal-person":true}}]},[_c('b-icon-person'),_vm._v(" Adicionar Pessoa")],1),_vm._v(" "),_c('b-modal',{attrs:{"id":"modal-person","centered":"","title":"Nova pessoa","hide-footer":"","size":"lg"}},[_c('FormToPeople',{attrs:{"person":{},"new-form":true}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }