<template>
  <div v-if="editor" v-click-outside="clamp">
    <menu-bar
      v-if="editable"
      :editor="editor"
      class="d-flex justify-content-between"
    />
    <editor-content
      ref="editor"
      :editor="editor"
      :class="{ 'line-clamp': clampLines }"
    />
    <b-button
      v-if="clampLines && lines > 4"
      variant="link"
      size="sm"
      class="p-0 m-0"
      @click="clampLines = false"
      >Ver mais...</b-button
    >
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import { defaultExtensions } from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Placeholder from '@tiptap/extension-placeholder'

import MenuBar from './tiptap/MenuBar.vue'

export default {
  components: {
    EditorContent,
    MenuBar
  },

  props: {
    value: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      editor: null,
      clampLines: false,
      elem: null
    }
  },

  computed: {
    lines: function () {
      if (!this.elem) return
      let divHeight = this.elem.offsetHeight
      let lineHeight = parseInt(
        window.getComputedStyle(this.elem, null).getPropertyValue('line-height')
      )
      return divHeight / lineHeight
    }
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return
      }

      this.editor.commands.setContent(this.value, false)
    }
  },

  mounted() {
    let vm = this
    this.editor = new Editor({
      extensions: [
        ...defaultExtensions(),
        Highlight,
        Placeholder.configure({
          placeholder: 'Escreva aqui...'
        })
      ],
      editable: this.editable,
      content: this.value,
      onCreate() {
        vm.elem = vm.$refs.editor.$el
        vm.clamp()
      },
      onUpdate: () => {
        let content = this.editor.getHTML()
        let json = this.editor.getJSON().content

        if (
          Array.isArray(json) &&
          json.length === 1 &&
          !Object.prototype.hasOwnProperty.call(json[0], 'content')
        ) {
          content = null // or any other default value
        }

        // HTML
        this.$emit('input', content)

        // JSON
        // this.$emit('input', this.editor.getJSON())
      }
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },

  methods: {
    clamp() {
      if (this.lines > 4 && !this.editable) {
        this.clampLines = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.line-clamp {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
</style>