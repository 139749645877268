var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('CronogramaTitle',{on:{"update-current-month-year":_vm.updateCurrentMonthYear}}),_vm._v(" "),_c('div',{ref:"firstScrollContainer",staticClass:"scroll-container",style:({ 'max-height': _vm.maxHeight })},[_c('table',[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"140px"}},[_vm._v("Projeto "),_c('b-button',{staticClass:"p-0 m-0 ml-1",attrs:{"variant":"link"},on:{"click":function($event){return _vm.adjustFirstTableToScreenHeight()}}},[_c('b-icon',{attrs:{"icon":"arrows-fullscreen","scale":"0.8"}})],1)],1),_vm._v(" "),_c('th',{staticStyle:{"width":"20px"}}),_vm._v(" "),_c('th',{staticStyle:{"width":"80px"}},[_vm._v("Início")]),_vm._v(" "),_c('th',{staticStyle:{"width":"80px"}},[_vm._v("Fim")]),_vm._v(" "),_vm._l((_vm.getDaysInMonth(_vm.currentMonth, _vm.currentYear)),function(day){return _c('th',{key:day,class:{ weekend: _vm.isWeekend(day), today: _vm.isToday(day) }},[_c('small',[_vm._v(_vm._s(day))])])})],2)]),_vm._v(" "),_c('tbody',_vm._l((_vm.tasks),function(task,index){return _c('tr',{key:index},[_c('td',{staticClass:"p-0",style:({
              textWrap: 'nowrap',
              overflow: 'hidden'
            }),attrs:{"draggable":"true"},on:{"dragstart":function($event){return _vm.drag($event, task)},"dragend":function($event){_vm.isDragging = false}}},[_c('b-link',{attrs:{"title":task.name,"to":{ name: 'Deal View', params: { id: task.deal_id } }}},[_vm._v(_vm._s(task.name))])],1),_vm._v(" "),_c('td',{staticClass:"p-0"},[_c('b-button',{staticClass:"p-0 m-0 border-0 w-100",staticStyle:{"font-size":"large","line-height":"0"},attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.openActivity(task)}}},[_c('b-icon',{staticStyle:{"vertical-align":"middle"},attrs:{"icon":"list","font-scale":"0.75"}})],1),_vm._v(" "),_c('div',{staticClass:"p-0",attrs:{"id":'popover-target-' + task.activity_id + index}},[_c('div',{style:({
                  backgroundColor: task.hex_color || '#007bff',
                  width: '100%',
                  height: '10px'
                })})]),_vm._v(" "),_c('b-popover',{key:'popover-target-' + task.activity_id + index,attrs:{"target":'popover-target-' + task.activity_id + index,"triggers":"hover","placement":"auto","boundary":"window"}},[_c('form-group',{staticClass:"form-group",attrs:{"name":"hex_color","label":"Color"}},[_c('color-picker',{attrs:{"value":task.hex_color || '#007bff'},on:{"input":function($event){return _vm.updateActivityHexColorDebounced($event, task)}}}),_vm._v(" "),_c('span',[_c('b-link',{on:{"click":function($event){task.hex_color = _vm.getRandomColor()}}},[_vm._v("Random")])],1)],1)],1)],1),_vm._v(" "),_c('td',{staticClass:"p-0"},[_vm._v("\n            "+_vm._s(_vm.moment(task.start_date).format('DD-MM-YY'))+"\n          ")]),_vm._v(" "),_c('td',{staticClass:"p-0"},[_vm._v(_vm._s(_vm.moment(task.end_date).format('DD-MM-YY')))]),_vm._v(" "),_vm._l((_vm.getDaysInMonth(_vm.currentMonth, _vm.currentYear)),function(n){return _c('td',{key:n,class:{
              'p-0': true,
              weekend: _vm.isWeekend(n),
              today: _vm.isToday(n)
            },style:({
              backgroundColor: _vm.isTaskActive(task, n)
                ? task.hex_color || '#007bff'
                : null
            })})})],2)}),0)])]),_vm._v(" "),_c('div',{ref:"secondScrollContainer",staticClass:"scroll-container",style:({ 'max-height': _vm.maxHeight })},[_c('table',[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"140px"}},[_c('div',{staticClass:"d-flex"},[_vm._v("\n              Recurso\n              "),_c('b-button',{staticClass:"p-0 m-0 ml-1",attrs:{"variant":"link"},on:{"click":function($event){return _vm.adjustSecondTableToScreenHeight()}}},[_c('b-icon',{attrs:{"icon":"arrows-fullscreen","scale":"0.8"}})],1),_vm._v(" "),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-add-person",modifiers:{"modal-add-person":true}}],staticClass:"ml-auto py-0 px-1",staticStyle:{"border-radius":"100%"},attrs:{"variant":"outline-primary","size":"sm"}},[_c('b-icon-plus',{attrs:{"scale":"1.4"}})],1)],1)]),_vm._v(" "),_c('th',{staticStyle:{"width":"180px"}}),_vm._v(" "),_c('th',{attrs:{"colspan":_vm.getDaysInMonth(_vm.currentMonth, _vm.currentYear)}})])]),_vm._v(" "),_c('tbody',_vm._l((_vm.resources),function(resource,index){return _c('tr',{key:index},[_c('td',[_c('div',{staticClass:"d-flex"},[_vm._v("\n              "+_vm._s(resource.name)+"\n              "),_c('button',{staticClass:"btn btn-outline-danger btn-sm px-1 py-0 ml-auto",staticStyle:{"border-radius":"100%"},on:{"click":function($event){return _vm.removeResource(index)}}},[_c('b-icon-x',{attrs:{"scale":"1.4"}})],1)])]),_vm._v(" "),_c('td'),_vm._v(" "),_vm._l((_vm.getDaysInMonth(_vm.currentMonth, _vm.currentYear)),function(day){return _c('td',{key:day + _vm.currentMonth + _vm.currentYear,staticClass:"p-0 py-2 td-on-hover",class:{
              dragging: _vm.isDragging,
              weekend: _vm.isWeekend(day),
              today: _vm.isToday(day)
            },style:({
              verticalAlign: 'top'
            }),on:{"drop":function($event){return _vm.drop($event, resource, day)},"dragover":function($event){return _vm.allowDrop($event)},"dragenter":_vm.handleDragEnter,"dragleave":_vm.handleDragLeave}},[_c('div',{style:({
                height: '8px',
                backgroundColor: 'transparent',
                pointerEvents: 'none'
              })}),_vm._v(" "),_vm._l((resource.tasks),function(task,taskIndex){return _c('div',{key:'resource-task-' + day + task.id + '-lane-' + taskIndex},[(_vm.dateCheck(day, task.start_date, task.end_date, 'range'))?_c('div',{style:({
                  width: '105%',
                  height: '8px',
                  backgroundColor: task.hex_color || '#007bff',
                  position: 'relative'
                }),attrs:{"variant":"primary"}},[(_vm.dateCheck(day, task.start_date, null, 'sameOrFirst'))?_c('div',{style:({
                    width: _vm.calculateTaskWidth(task) + 'px',
                    height: '8px',
                    position: 'relative',
                    zIndex: 99
                  }),on:{"mouseenter":function($event){return _vm.$set(task, 'showName', true)},"mouseleave":function($event){return _vm.mouseLeaveShowNameTimeout(task)}}},[(((day == new Date(task.start_date).getDate() &&
                      _vm.currentMonth ==
                      new Date(task.start_date).getMonth() &&
                      _vm.currentYear ==
                      new Date(task.start_date).getFullYear()) ||
                      (new Date(_vm.currentYear, _vm.currentMonth, 1).getTime() >=
                        new Date(task.start_date).getTime() &&
                        day == 1)) &&
                      task.showName
                      )?_c('div',{ref:'taskName',refInFor:true,staticClass:"d-flex",style:({
                      position: 'absolute',
                      bottom: '0px',
                      textWrap: 'nowrap',
                      width: 'auto',
                      left: _vm.willOverflow(task),
                      backgroundColor: task.hex_color || '#007bff',
                      color: task.text_color_1,
                      zIndex: 100
                    })},[_c('div',{staticClass:"flex flex-column ml-2"},[_c('span',[_vm._v(_vm._s(task.name))]),_vm._v(" "),_c('button',{staticClass:"btn btn-danger border btn-sm p-0",staticStyle:{"border-radius":"100%"},on:{"click":function($event){return _vm.removeResourceTask(task)}}},[_c('b-icon-x',{attrs:{"scale":"1.4"}})],1)]),_vm._v(" "),(_vm.activeResourceTask)?_c('div',{staticClass:"flex flex-column ml-2"},[_c('div',{staticClass:"row no-gutters flex-nowrap"},[_c('b-form-input',{class:{
                            'h-100 p-0': true,
                            'white-color': task.text_color_1 === 'white'
                          },style:({
                            width: '140px',
                            fontSize: 'smaller',
                            border: 'none',
                            borderNottom: '1px solid black',
                            background: 'transparent',
                            color: task.text_color_1
                          }),attrs:{"value":_vm.formattedStartDateTime,"type":"datetime-local"},on:{"blur":function($event){_vm.formattedStartDateTime = $event.target.value}}})],1),_vm._v(" "),_c('div',{staticClass:"row no-gutters flex-nowrap"},[_c('b-form-input',{class:{
                            'h-100 p-0': true,
                            'white-color': task.text_color_1 === 'white'
                          },style:({
                            width: '140px',
                            fontSize: 'smaller',
                            border: 'none',
                            borderNottom: '1px solid black',
                            background: 'transparent',
                            color: task.text_color_1
                          }),attrs:{"value":_vm.formattedEndDateTime,"type":"datetime-local"},on:{"blur":function($event){_vm.formattedEndDateTime = $event.target.value}}})],1)]):_vm._e()]):_vm._e()]):_vm._e()]):_c('div',{style:({
                  height: '8px',
                  backgroundColor: 'transparent',
                  pointerEvents: 'none'
                })})])})],2)})],2)}),0)])]),_vm._v(" "),_c('b-modal',{attrs:{"id":"modal-add-person","centered":"","hide-footer":"","hide-header":""}},[_c('b-form-group',{staticClass:"form-group font-weight-bold",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"align-items-center d-flex"},[_c('b-icon-person-fill'),_vm._v("\n          Pessoa\n        ")],1)]},proxy:true},{key:"default",fn:function(ref){
                var attrs = ref.attrs;
                var listeners = ref.listeners;
return _c('v-select',_vm._g(_vm._b({staticClass:"w-100 h-auto",staticStyle:{"z-index":"1000"},attrs:{"label":"name","filterable":false,"options":_vm.peopleOptions,"placeholder":'Procure aqui...',"taggable":false,"append-to-body":true,"calculate-position":_vm.dropPos},on:{"search":_vm.getPeople,"search:blur":function($event){return _vm.$refs.addPersonButton.focus()}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('div',[_c('b-icon-person-circle',{staticClass:"mr-2",attrs:{"font-scale":"1.5"}})],1),_vm._v(" "),_c('div',{staticClass:"flex-column"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(option.name))]),_vm._v(" "),_c('div',[_vm._v("\n                "+_vm._s(option.email)+"\n                "+_vm._s(_vm._f("facade")(option.mobile,[
                    '####-####',
                    '#####-####',
                    '(##) ####-####',
                    '(##) #####-####'
                  ]))+"\n                "+_vm._s(_vm._f("facade")(option.telephone,[
                    '####-####',
                    '#####-####',
                    '(##) ####-####',
                    '(##) #####-####'
                  ]))+"\n              ")])])])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v("\n            "+_vm._s(option.name)+"\n          ")])]}}]),model:{value:(_vm.newPerson),callback:function ($$v) {_vm.newPerson=$$v},expression:"newPerson"}},'v-select',attrs,false),listeners),[_c('template',{slot:"no-options"},[_vm._v(" Digite para procurar... ")])],2)}}])}),_vm._v(" "),_c('b-button',{ref:"addPersonButton",attrs:{"block":"","variant":"success"},on:{"click":function($event){return _vm.addResource()}}},[_vm._v("Adicionar à Recursos\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }